<template>
  <v-container>
    <settings-interface
      ref="component"
      :settings="settings"
      :interfaces="interfaces"
      :type="type"
      :disabled="!canAddInterface"
      :status="status"
      :ping-analyzers="pingAnalyzers"
      :interface-trackers="interfaceTrackers"
      :features="features"
      @renew-dhcp="onRenewDhcp"
      @manage-status-analyzers="onManageStatusAnalyzers"
      @delete="onDelete"
      @get-all-interface-status="onGetAllInterfaceStatus"
      @get-status-hardware="onGetStatusHardware"
      @get-status-wan-test="onGetStatusWanTest"
      @get-wifi-channels="onGetWifiChannels"
      @get-wifi-mode-list="onGetWifiModeList"
      @get-status-wwan="onGetStatusWwan"
      @get-wireguard-publickey="onGetWireguardPublicKey"
      @get-wireguard-keypair="onGetWireguardKeypair"
      @get-wireguard-address-check="onWireguardAddressCheck"
      @get-device-status="onGetDeviceStatus"
    >
      <template #no-license>
        <no-license v-if="!canAddInterface" class="mt-2">
          {{ getVpnInterfaceMessage() }}
          <template #actions>
            <u-btn class="ml-4" @click="$router.push({ name: 'appliances-id' })">{{ $t('view_system_license') }}</u-btn>
            <u-btn class="ml-4" @click="$router.push({ name: 'account-subscriptions' })">
              {{ $t('manage_licenses') }}
              <v-icon right> mdi-open-in-new </v-icon>
            </u-btn>
          </template>
        </no-license>
      </template>
      <template #bridged-interface>
        <v-alert v-if="isBridged && canAddInterface && settings.type !== 'WIFI'" text color="primary">
          <div class="d-flex align-left ml-1">
            <strong>
              <slot>
                <v-icon color="primary" class="mr-4">mdi-information</v-icon>
                <span>{{ $t('bridged_interface_info') }} {{ bridgedInterfaceName }}</span>
              </slot>
            </strong>
            <v-spacer />
            <slot name="actions"></slot>
          </div>
        </v-alert>
      </template>
      <template #actions="{ newSettings, isDirty, validate }">
        <u-btn class="mr-2" @click="$router.push({ name: 'appliances-interfaces' })">{{ $t('back_to_list') }}</u-btn>
        <u-btn :min-width="null" :disabled="!isDirty || !canAddInterface" @click="onSave(newSettings, validate)">{{
          $t('save')
        }}</u-btn>
      </template>
    </settings-interface>
  </v-container>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { NoLicense, SettingsInterface } from 'vuntangle'
  import interfaceMixin from './applianceInterfaceMixin'
  import appliance from '@/plugins/ut/ut-appliances'

  export default {
    components: {
      NoLicense,
      SettingsInterface,
    },
    mixins: [interfaceMixin],
    data: () => ({
      status: null,
      canAddInterface: true,
      isBridged: undefined,
      bridgedInterfaceName: undefined,
    }),
    computed: {
      device: ({ $route }) => $route.params.device,
      type: ({ $route }) => $route.params.type,
      settings: ({ interfaces, device }) => interfaces.find(intf => intf.device === device),
      pingAnalyzers: ({ boxSettings }) => boxSettings?.stats?.pingAnalyzers || [],
      interfaceTrackers: ({ boxSettings }) => boxSettings?.network?.track || [],
      features: ({ appliance }) => appliance.features,
      // appliances that have been added via CV will have noLicenseEnforcement as true
      noLicenseEnforced: ({ $store }) => $store.getters['data/NoLicenseEnforcement'],
    },
    async mounted() {
      this.$store.commit('SET_PAGE_LOADER', true)
      await Promise.allSettled([this.setLicenseAndLicenseUri(), this.getStatus()])
      this.$store.commit('SET_PAGE_LOADER', false)
      this.isBridgedInterface()
    },
    methods: {
      getVpnInterfaceMessage() {
        switch (this.type?.toLowerCase()) {
          case 'openvpn':
            return this.$t('not_licensed_interface', [this.$t('open_vpn')])
          case 'wireguard':
            return this.$t('not_licensed_interface', [this.$t('wireguard')])
          case 'ipsec':
            return this.$t('not_licensed_interface', [this.$t('ipsec_tunnel')])
        }
        return ''
      },
      async setLicenseAndLicenseUri() {
        // MFW-2454: VPN Interfaces can only be added on licensed appliance
        if (['openvpn', 'ipsec', 'wireguard'].includes(this.type)) {
          const response = await this.getFromAppliance('status/license')
          // if the account allows noLicenseEnforced, allow interfaces to be added
          this.canAddInterface = response.data?.list?.length > 0 || this.noLicenseEnforced || false
        } else {
          this.canAddInterface = true
        }
      },

      // get the interface status
      async getStatus() {
        // during add device will be undefined
        if (!this.device) {
          return
        }
        const response = await this.getFromAppliance(`status/interfaces/${this.device}`)
        if (response.data && Array.isArray(response.data)) {
          this.status = response.data[0]
        }
      },

      // renews DHCP and refetches status
      async onRenewDhcp(device, cb) {
        const response = await appliance.sendToApplianceApi(this.uid, `renewdhcp/${device}`)
        if (response.success) {
          await this.getStatus()
        } else {
          this.$vuntangle.toast.add(this.$t('an_error_occurred'), 'error')
        }
        cb()
      },

      // redirects to manage analyzers route
      onManageStatusAnalyzers() {
        this.$router.push({ name: 'appliances-status-analyzers' })
      },

      // returns all interface statuses
      async onGetAllInterfaceStatus(cb) {
        const response = await this.getFromAppliance('status/interfaces/all')
        cb(response.data ?? null)
      },

      /** returns box status hardware info */
      async onGetStatusHardware(cb) {
        const response = await this.getFromAppliance('status/hardware')
        cb(response.data ?? null)
      },

      async onGetStatusWanTest(l3device, cb) {
        const response = await this.getFromAppliance(`status/wantest/${l3device}`)
        cb(response.data ?? null)
      },

      /** returns box wwan status */
      async onGetStatusWwan(device, cb) {
        const response = await this.getFromAppliance(`status/wwan/${device}`)
        cb(response.data ?? null)
      },

      /** returns box Wi-Fi channels */
      async onGetWifiChannels(device, cb) {
        const response = await this.getFromAppliance(`status/wifichannels/${device}`)
        cb(response.data ?? null)
      },

      /** returns box Wi-Fi mode list */
      async onGetWifiModeList(device, cb) {
        try {
          const response = await this.getFromAppliance(`status/wifimodelist/${device}`)
          cb(response.data ?? null)
        } catch (ex) {
          cb(null)
        }
      },

      /** returns wireguard public key */
      async onGetWireguardPublicKey(privateKey, cb) {
        const response = await appliance.sendToApplianceApi(this.uid, 'wireguard/publickey', { privateKey })
        if (response.success) {
          cb(response.data)
        } else {
          this.$vuntangle.toast.add(this.$t('an_error_occurred'), 'error')
          cb(null)
        }
      },

      /** returns wireguard keypair */
      async onGetWireguardKeypair(cb) {
        const response = await appliance.sendToApplianceApi(this.uid, 'wireguard/keypair', null, 'GET')
        if (response.success) {
          cb(response.data)
        } else {
          this.$vuntangle.toast.add(this.$t('an_error_occurred'), 'error')
          cb(null)
        }
      },

      /** returns wireguard address check */
      async onWireguardAddressCheck(value, cb) {
        const response = await appliance.addressChecker(this.uid, value + '/24')
        cb(response ?? null)
      },

      /** returns device status */
      async onGetDeviceStatus(device, cb) {
        try {
          const response = await this.getFromAppliance(`status/interfaces/${device}`)
          cb(response.data ?? null)
        } catch (ex) {
          cb(null)
        }
      },

      async onSave(intf, validate) {
        const isValid = await validate()
        if (!isValid) return

        const interfaces = cloneDeep(this.interfaces)
        const updatedInterface = interfaces.find(i => i.interfaceId === intf.interfaceId)

        // apply changes made to the interface
        if (updatedInterface) {
          Object.assign(updatedInterface, { ...intf })
        } else {
          interfaces.push(intf)
        }
        this.$store.commit('SET_PAGE_LOADER', true)

        // save interface settings
        const resultIntf = await appliance.sendToApplianceApi(this.uid, 'settings/network/interfaces', interfaces)
        let proceed = false

        // show confirmation dialogue if BE returns error (dependencies will also be affected)
        if (resultIntf?.data?.error) {
          const matches = /^(.{0,11}CONFIRM):([\s\S]*?)$/gm.exec(resultIntf.data.error)
          if (matches?.[2]) {
            // check if user clicks ok or cancel
            proceed = await this.displayConfirm(this.uid, matches[2], interfaces, !updatedInterface.enabled)
          }
        }

        // if saving forced is successful, proceed with additional check and update
        let resultWan
        let updatedWan
        if (proceed.result) {
          updatedWan = this.validateIpsecPoliciesAndRules(intf)
          if (updatedWan) {
            resultWan = await appliance.sendToApplianceApi(this.uid, 'settings/wan', updatedWan)
          }
        }

        // if the initial call either passed or needed confirmation and that passed, and updateWan was not needed or it was successful
        if ((resultIntf?.success || proceed?.result) && (!updatedWan || resultWan.success)) {
          // upon save success will refetch the appliance settings so UI is in sync
          await this.fetchSettings()
          // return to main interfaces
          this.$router.push({ name: 'appliances-interfaces' })
          this.$vuntangle.toast.add(this.$t('settings_updated'))
        } else {
          // get the potential error message from the initial call (resultIntf) or resultWan
          // the other 2 calls are treated already
          const errorMessage = resultIntf?.data?.error || resultWan?.data?.error

          if (!errorMessage) {
            this.$vuntangle.toast.add(this.$t('sync_now_could_not_be_performed', 'error'))
            return
          }

          try {
            // if error message is a JSON string (not a plain string)
            const parsedError = JSON.parse(errorMessage)
            this.$vuntangle.toast.addError(
              this.$t(parsedError?.error || 'failed_sync_settings'),
              JSON.stringify(parsedError, null, 2),
            )
          } catch {
            // otherwise just show normal error
            this.$vuntangle.toast.add(this.$te(errorMessage) ? this.$t(errorMessage) : errorMessage, 'error')
          }
        }

        this.$store.commit('SET_PAGE_LOADER', false)
      },

      /**
       * Removes the interface
       * - shows a confirm dialog
       */
      onDelete() {
        this.deleteInterfaceHandler(this.settings, () => {
          this.$router.push({ name: 'appliances-interfaces' })
        })
      },

      /**
       * when enabling a disabled IPsec interface also enable policies related to it
       * @returns {Array|Boolean} - the updated policies array or false
       */
      validateIpsecPoliciesAndRules(newSettings) {
        if (!newSettings.enabled || newSettings.type !== 'IPSEC') return false
        const wanCopy = cloneDeep(this.boxSettings?.wan)
        if (!wanCopy) return false

        const policies = wanCopy.policies
        const rules = wanCopy.policy_chains.find(chain => chain.name === 'user-wan-rules').rules
        const affectedPoliciesIds = []

        policies.forEach(policy => {
          policy.interfaces.forEach(intf => {
            // find policies related to current interface
            if (intf.interfaceId === newSettings.interfaceId && !policy.enabled) {
              affectedPoliciesIds.push(policy.policyId)
              policy.enabled = newSettings.enabled
            }
          })
        })
        rules.forEach(rule => {
          // check rule action policy in affected policies
          if (affectedPoliciesIds.includes(rule.action.policy) && !rule.enabled) {
            rule.enabled = true
          }
        })

        if (affectedPoliciesIds.length < 0) return false
        return wanCopy
      },

      isBridgedInterface() {
        const currentDevice = this.device
        let isBridgeInterface = false
        let currentInterfaceId = ''

        for (const interfaceItem of this.interfaces) {
          if (interfaceItem.device === currentDevice) {
            currentInterfaceId = interfaceItem.interfaceId
            break
          }
        }

        for (const interfaceItem of this.interfaces) {
          if (interfaceItem.type === 'BRIDGE') {
            const matchedInterface = interfaceItem.bridgedInterfaces.includes(currentInterfaceId)
            if (matchedInterface) {
              isBridgeInterface = true
              this.isBridged = true
              this.bridgedInterfaceName = interfaceItem.device
              break
            }
          }
        }
        return isBridgeInterface
      },
    },
  }
</script>
