<template>
  <v-container class="pa-0 d-flex flex-column">
    <database-list
      :disabled="securityLicenseRequired()"
      :settings="settings"
      :status="status"
      :fetching="fetching"
      :cloud-config-id="cloudConfigId"
      @update-settings="onSaveEntry"
      @delete-configuration="onDeleteConfiguration"
      @refresh="fetchStatus"
      @refresh-connection="onRefreshConnection"
    >
      <template #actions="{ newSettings, disabled, isDirty }">
        <u-btn :disabled="disabled" class="mr-2" @click="onResetDefaults">
          {{ $vuntangle.$t('reset_to_defaults') }}
        </u-btn>
        <u-btn :disabled="disabled || !isDirty" @click="onSave(newSettings)">
          {{ $vuntangle.$t('save') }}
        </u-btn>
      </template>
    </database-list>
  </v-container>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { DatabaseList } from 'vuntangle'
  import settingsMixin from './settingsMixin'
  import { mfwServices, ServiceName } from '@/util/mfwServices'
  import appliances from '@/plugins/ut/ut-appliances'

  export default {
    components: {
      DatabaseList,
    },

    mixins: [settingsMixin],
    data() {
      return {
        status: [],
        fetching: false,
        settingsPath: 'database',
      }
    },

    computed: {
      serviceConfig: () => mfwServices[ServiceName.Database],
      boxPath: ({ serviceConfig }) => serviceConfig?.settingPathForBox,
      settings: ({ boxSettings }) => boxSettings.databases || [],
      cloudConfigId: ({ boxSettings }) => boxSettings.cloud_managed?.databases,
    },

    mounted() {
      this.fetchStatus()
    },

    methods: {
      /** Resets databases to its default configuration */
      onResetDefaults() {
        this.$vuntangle.confirm.show({
          title: this.$t('confirm'),
          message: this.$t('database_reset_warning'),
          action: async resolve => {
            const defaultsResponse = await appliances.sendToApplianceApi(this.uid, 'defaults/databases')
            if (defaultsResponse.success && defaultsResponse.data) {
              this.saveSettings(this.boxPath, defaultsResponse.data)
            }
            resolve()
          },
        })
      },

      /**
       * dispatches action to save the updated database configs
       * @param {Object} newSettings - new settings to be saved
       */
      onSave(newSettings) {
        this.saveSettings(this.boxPath, newSettings)
      },

      /**
       * removes a configuration by passing a new list with that configuration sliced out
       * @param {String} configurationId - the uuid of the configuration to be removed
       */
      async onDeleteConfiguration(configurationId) {
        const settings = cloneDeep(this.settings)
        const listIndex = settings.findIndex(conf => conf.id === configurationId)
        if (listIndex >= 0) {
          settings.splice(listIndex, 1)
        }
        const response = await this.saveSettings(this.boxPath, settings)

        if (response.success) {
          await appliances.sendToApplianceApi(this.uid, 'reports/database/' + configurationId, null, 'DELETE')
        }
      },

      /**
       * handler to fetch db statuses
       */
      async fetchStatus() {
        this.fetching = true
        const response = await appliances.sendToApplianceApi(this.uid, 'reports/database/refresh', null, 'GET')
        this.fetching = false
        this.status = response.data?.result || []
      },

      /**
       * Refresh Connection for a single added database
       * @param uuid
       */
      async onRefreshConnection({ uuid, cb }) {
        this.fetching = true
        const response = await appliances.sendToApplianceApi(this.uid, `reports/database/refresh/${uuid}`, null, 'GET')
        cb(response && response.data ? response.data.result : false)
        this.fetching = false
      },

      /**
       * Dispatch action to add/update settings
       * @param {Object} newSettings - updated configuration details
       */
      async onSaveEntry(newSettings) {
        const settings = cloneDeep(this.settings)
        const listIndex = settings.findIndex(conf => conf.id === newSettings.id)
        if (listIndex < 0) {
          // Add db connection
          settings.push(newSettings)
        } else {
          // Edit db connection
          settings.splice(listIndex, 1, newSettings)
        }

        const response = await this.saveSettings(this.boxPath, settings)

        if (response.success) {
          this.fetching = true
          if (listIndex < 0) {
            await appliances.sendToApplianceApi(this.uid, 'reports/database/' + newSettings?.id, null, 'POST')
          } else {
            await appliances.sendToApplianceApi(this.uid, 'reports/database/' + newSettings?.id, null, 'PUT')
          }
          this.fetching = false
          this.$vuntangle.toast.add(this.$t('saved_successfully', [this.$t('database')]))
        } else {
          this.$vuntangle.toast.add(this.$t('an_error_occurred'), 'error')
        }
      },
    },
  }
</script>
