import { incomingThreatLevels } from 'vuntangle/constants'
import api from '@/plugins/ut/ut-api'
import store from '@/store'
import { ServiceName, mfwServices } from '@/util/mfwServices'

/** this params are initialized with the component and are needed for the methods used by the components */
let params = {
  settingsPath: null,
  applianceUid: null,
}

/**
 * The map between settings path and the component definition that is used for editing those settings
 * Depending on the settings/component this can vary, see below
 * In ApplianceSettings.vue the component
 * - binds the below defined props (if any) (`v-bind="component.props"`)
 * - listens to below defined events (if any) (`v-on="component.events"`)
 */
const component = {
  [ServiceName.Dhcp]: {
    name: mfwServices[ServiceName.Dhcp].componentName, // the component name that is used
    // extra props required by the component (besides `settings` which is set for all)
    props: {
      leases: [],
      fetching: false, // loader while fetching leases
    },
    // specific events for this component
    events: {
      'fetch-leases': fetchLeases,
    },
    // extra data fetching required so the compoenent functions as expected
    preFetch: async () => {
      await fetchLeases()
    },
  },
  [ServiceName.Dns]: {
    name: mfwServices[ServiceName.Dns].componentName,
  },
  [ServiceName.StaticRoutes]: {
    name: mfwServices[ServiceName.StaticRoutes].componentName,
  },
  [ServiceName.WebFilter]: {
    name: mfwServices[ServiceName.WebFilter].componentName,
    events: {
      lookup: ({ site, resolve }) => webFilterLookup(site, resolve, params.applianceUid),
    },
  },
  [ServiceName.ThreatPrevention]: {
    name: mfwServices[ServiceName.ThreatPrevention].componentName,
    events: {
      lookup: (ipAddress, resolve) => threatPreventionLookup(ipAddress, resolve, params.applianceUid),
    },
  },
  [ServiceName.GeoipFilter]: {
    name: mfwServices[ServiceName.GeoipFilter].componentName,
    events: {
      lookup: geoIpLookup, // method for the `lookup` event defined below
    },
  },
  [ServiceName.NetworkDiscovery]: {
    name: mfwServices[ServiceName.NetworkDiscovery].componentName,
  },
  [ServiceName.ApplicationControl]: {
    name: mfwServices[ServiceName.ApplicationControl].componentName,
  },
  [ServiceName.CaptivePortal]: {
    name: mfwServices[ServiceName.CaptivePortal].componentName,
    props: {
      zoneData: { interfaces: [] },
    },
    preFetch: () => {
      return Promise.allSettled([
        fetchZoneData(params.applianceUid),
        store.dispatch('appliances/fetchCaptivePortalImage', params.applianceUid),
      ])
    },
    preSave: settings =>
      store.dispatch('appliances/saveCaptivePortalImage', {
        uid: params.applianceUid,
        payload: {
          imageData: settings.logo?.imageData || '',
        },
      }),
  },
  [ServiceName.DenialOfService]: {
    name: mfwServices[ServiceName.DenialOfService].componentName,
  },
  [ServiceName.DnsFilter]: {
    name: mfwServices[ServiceName.DnsFilter].componentName,
  },
}

/**
 * Fetches DHCP leases and sets the `leases` prop on component
 * used by DHCP settings
 */
async function fetchLeases() {
  const dhcpComponent = component[ServiceName.Dhcp]
  dhcpComponent.props.fetching = true
  const dhcpLeases = await api.cloud('Untangle_CommandCenter', 'GetFromApplianceApi', {
    uid: params.applianceUid,
    path: 'status/dhcp',
    paramOrder: 'uid path',
  })
  dhcpComponent.props.fetching = false
  dhcpComponent.props.leases = dhcpLeases.success ? dhcpLeases.data : []
}

/**
 * Method called on GeoIP lookup event resolving with the response from the box
 * @param {Object<string, function>} - the ip for the lookup and the callback function
 */
async function geoIpLookup({ ip, resolve }) {
  const response = await api.cloud('Untangle_CommandCenter', 'OnLookup', { ip, paramOrder: 'ip' })
  if (response.data && response.success) {
    resolve(response.data) // passed to GeoIP shared component
  }
}

/**
 * Method called on Web Filter lookup event resolving with the response from the box
 * @param site - site to perform lookup
 * @param resolve - resolve method send from lookup component
 * @param uid - uid of the selected appliance
 */
async function webFilterLookup(site, resolve, uid) {
  const lookupPath = 'webfilter/lookup?site=' + site
  const response = await api.cloud('Untangle_CommandCenter', 'LookupFromAppliance', {
    uid,
    lookupPath,
    paramOrder: 'uid lookupPath',
  })
  if (response.data && response.success) {
    resolve(response.data) // passed to GeoIP shared component
  }
}

/**
 * Method called on Web Filter lookup event resolving with the response from the box
 * @param site - ip address to lookup
 * @param resolve
 * @param uid - uid of the selected appliance
 */
async function threatPreventionLookup(ipAddress, resolve, uid) {
  const lookupPath = 'threatprevention/lookup/' + ipAddress
  const response = await api.cloud('Untangle_CommandCenter', 'LookupFromAppliance', {
    uid,
    lookupPath,
    paramOrder: 'uid lookupPath',
  })
  if (response.data && response.success) {
    let tkey = 'unknown'
    Object.keys(incomingThreatLevels).forEach(level => {
      if (response.data[0].reputation > level) {
        tkey = incomingThreatLevels[level]
      }
    })
    response.data.incoming = tkey
    response.data.outgoing = response.data[0].status === 0 ? 'trustworthy' : 'high_risk'
    resolve(response.data)
  }
}

/**
 * Get the interface zones from the box
 *
 * @param uid
 * @returns {Promise<void>}
 */
async function fetchZoneData(uid) {
  const setting = 'network/interfaces'
  const response = await api.cloud('Untangle_CommandCenter', 'GetApplianceSetting', {
    uid,
    setting,
    paramOrder: 'uid setting',
  })
  component[ServiceName.CaptivePortal].props.zoneData.interfaces =
    response.data && response.success ? response.data : []
}

/**
 * Returns the component to be used for editing settings
 * @param {String} settingsPath - the settings that are edited (equiv to settings key in settings.json)
 * @param {String} applianceUid - the appliance UID
 */
const setComponent = (settingsPath, applianceUid) => {
  // initialize params
  params = {
    settingsPath,
    applianceUid,
  }
  return component[settingsPath]
}

export { setComponent }
