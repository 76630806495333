<template>
  <u-page :title="$t('alert_history')" full-height>
    <u-grid
      id="alert-history"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.appliances.fetching || $store.state.events.accountEventsFetching"
      :row-data="rowData"
      :row-actions="rowActions"
      @row-clicked="viewAlertDetails"
      @refresh="loadAlertsReceived(true)"
    />
    <u-dialog
      :show-dialog="!!filteredAlerts.length"
      :title="$t('additional_information')"
      :width="700"
      :buttons="[
        {
          'name': $t('add_alert_rule'),
          'handler': 'add-alert-rule',
        },
        {
          'name': $t('close'),
          'handler': 'close-dialog',
        },
      ]"
      @add-alert-rule="addAlertRule({ data: filteredAlerts[alertIndex] })"
      @close-dialog="onCloseDialog"
    >
      <alert-details :filtered-alerts="filteredAlerts" :alert-index.sync="alertIndex" @route-to="onRouteTo" />
    </u-dialog>
  </u-page>
</template>
<script>
  import AlertDetails from '@/pages/alerts/alert-details'
  import users from '@/plugins/ut/ut-users'
  import grids from '@/plugins/ut/ut-grids'

  export default {
    components: { AlertDetails },
    data() {
      return {
        // list of records passed to detail dialog to navigate through
        filteredAlerts: [],
        // index of the record being viewed from filteredAlerts
        alertIndex: -1,
      }
    },
    computed: {
      // grid column defs
      columnDefs: () => grids.mergeWithApplianceColumns(grids.getAlertColumnDefs(), ['appliance', 'tag']),

      // make sure alerts received is only returned once appliances has loaded.
      alertsReceived: ({ $store: { state } }) =>
        state.appliances.list && state.events.accountEvents ? state.events.accountEvents : [],

      // returns merged alerts data with appliances data
      rowData: ({ $store, alertsReceived }) =>
        $store.getters['appliances/populateApplianceInfoByUid'](alertsReceived, 'DeviceId'),

      // returns add alert rule action for the rows
      rowActions: ({ $i18n, addAlertRule }) => {
        if (!users.hasFullAccountAccess()) return []
        return [
          {
            icon: 'mdi-file-plus',
            tooltip: $i18n.t('add_alert_rule'),
            handler: addAlertRule,
          },
        ]
      },
    },

    created() {
      this.$store.commit('SET_ALERTS_SELECTION', this.$route.path)
      this.loadAlertsReceived()
    },

    methods: {
      // dispatches the fetch action for appliances/alerts
      loadAlertsReceived(force = false) {
        this.$store.dispatch('appliances/fetchAppliances')
        this.$store.dispatch('events/fetchAccountEvents', { force })
      },

      // routing to create a new alert rule from the current message
      addAlertRule({ data }) {
        this.onCloseDialog()
        this.$router.push({
          name: 'alerts-rules-id',
          params: {
            id: 'new', // 'new' is used as id when creating a rule
            eventMessage: data.Message,
          },
        })
      },

      // closes dialog and redirects to provided route
      onRouteTo(route) {
        this.onCloseDialog()
        if (!route) return
        this.$router.push(route)
      },

      // closes the alert details dialog
      onCloseDialog() {
        this.filteredAlerts = []
        this.alertIndex = -1
      },

      // builds the props needed for alert details dialog and displays the dialog
      viewAlertDetails({ api, rowIndex }) {
        api.forEachNodeAfterFilterAndSort(node => {
          this.filteredAlerts.push(node.data)
        })
        this.alertIndex = rowIndex
      },
    },
  }
</script>
