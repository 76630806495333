import Appliances from '@/pages/appliances/index.vue'
import ApplianceDetails from '@/pages/appliances/_id.vue'
import AppliancesAdd from '@/pages/appliances/add.vue'
import AppliancesUpdate from '@/pages/appliances/update.vue'
import Backups from '@/pages/appliances/backups/index.vue'
import Restore from '@/pages/appliances/backups/restore.vue'
import Parent from '@/components/Parent'
import { breadCrumbBuilder } from '@/util/navigation'
import Networks from '@/pages/networks'
import AddNetwork from '@/pages/networks/add'
import NetworkId from '@/pages/networks/networksId'
import RulesId from '@/pages/networks/rulesId'
import NgfwMain from '@/components/policies/NgfwMain'
import store from '@/store'
import PoliciesAlerts from '@/pages/policies/ngfw/alerts'
import NgfwTemplateConfigurationAdd from '@/pages/policies/ngfw/template-configuration/add'
import NgfwTemplateConfiguration from '@/pages/policies/ngfw/template-configuration'
import NgfwTemplateConfigurationEdit from '@/pages/policies/ngfw/template-configuration/_id'
import Sync from '@/pages/policies/ngfw/template-configuration/sync-now/_id'
import PolicyCategoryType from '@/pages/policies/ngfw/_category/_type'
import MfwMain from '@/components/policies/MfwMain'
import mfwTemplatesRoutes from '@/router/mfw-templates'

import {
  ApplianceDashboard,
  ApplianceActiveSessions,
  ApplianceReports,
  ApplianceReportDetails,
  ApplianceSettings,
  ApplianceRules,
  ApplianceWanPolicies,
  ApplianceWanPolicyEdit,
  ApplianceAnalyzers,
  ApplianceInterfaces,
  ApplianceInterfaceEdit,
  ApplianceDynamicLists,
  ApplianceLogging,
  ApplianceSystem,
  ApplianceClients,
  AppliancePolicies,
  Database,
} from '@/components/appliances/settings'

export default [
  {
    path: '/appliances',
    component: Parent,
    meta: breadCrumbBuilder([['appliances', { name: 'appliances' }]]),
    children: [
      // backup paths (backup, network, and policies paths moved to top to avoid confusion with appliances/:id )
      {
        path: 'backups',
        component: Parent,
        meta: breadCrumbBuilder([['backups', { name: 'appliances-backups' }]]),
        children: [
          {
            path: '',
            name: 'appliances-backups',
            component: Backups,
          },
          {
            path: 'restore',
            name: 'appliances-backups-restore',
            component: Restore,
            meta: breadCrumbBuilder([['restore_backup', { name: 'appliances-backups-restore' }]]),
          },
        ],
      },
      // network paths
      {
        path: 'networks',
        component: Parent,
        meta: breadCrumbBuilder([['networks', { name: 'networks' }]]),
        children: [
          {
            path: '',
            name: 'networks',
            component: Networks,
          },
          {
            path: 'add',
            name: 'networks-add',
            component: AddNetwork,
            meta: breadCrumbBuilder([['create_network', { name: 'networks-add' }]]),
          },
          {
            path: ':id',
            component: Parent,
            meta: breadCrumbBuilder([['network_details', { name: 'networks-network-id' }]]),
            children: [
              {
                path: '',
                name: 'networks-network-id',
                component: NetworkId,
              },
              {
                path: 'rules/:ruleId',
                name: 'networks-rules-id',
                component: RulesId,
                meta: {
                  breadCrumb(route) {
                    return breadCrumbBuilder([
                      [route.params.ruleId === 'add' ? 'add_wan_rule' : 'edit_wan_rule', { name: 'networks-rules-id' }],
                    ]).breadCrumb
                  },
                },
              },
            ],
          },
        ],
      },
      // redirect for old 'sdwan' path
      {
        path: '/sdwan',
        redirect: { name: 'networks' },
      },
      // redirect for old 'networks' page
      {
        path: '/cmd/networks',
        redirect: { name: 'networks' },
      },
      {
        path: '/networks',
        redirect: { name: 'networks' },
      },
      // redirect for appliances to new my-appliances
      {
        path: '/appliances',
        redirect: { name: 'appliances' },
      },
      // policies ngfw
      {
        path: 'templates/ngfw',
        component: NgfwMain,
        meta: {
          breadCrumb(route) {
            // do not show a link if you are already on this page (there is no landing page)
            let to = { name: 'ngfw-policies-template-configuration' }
            if (route.name === 'template_configuration') {
              to = {}
            }

            return breadCrumbBuilder([['ngfw_templates', to]]).breadCrumb
          },
        },
        children: [
          {
            path: '',
            name: 'ngfw-templates',
            // the base route is a redirect to the route they left off on in this section
            redirect: () => store.state.ngfwTemplatesSelection,
          },
          {
            path: 'alerts',
            name: 'ngfw-policies-alerts',
            component: PoliciesAlerts,
            meta: breadCrumbBuilder([[['page_title', { 'app': 'alerts' }], { name: 'ngfw-policies-alerts' }]]),
          },
          {
            path: 'template-configuration',
            component: NgfwMain,
            meta: breadCrumbBuilder([['template_configuration', { name: 'ngfw-policies-template-configuration' }]]),
            children: [
              {
                path: '',
                name: 'ngfw-policies-template-configuration',
                component: NgfwTemplateConfiguration,
              },
              {
                path: 'add',
                name: 'ngfw-policies-template-configuration-add',
                component: NgfwTemplateConfigurationAdd,
                meta: breadCrumbBuilder([['add_template', { name: 'ngfw-policies-template-configuration-add' }]]),
              },
              {
                path: 'edit/:id',
                name: 'ngfw-policies-template-configuration-id',
                component: NgfwTemplateConfigurationEdit,
                meta: breadCrumbBuilder([['edit_template', { name: 'ngfw-policies-template-configuration-id' }]]),
              },
              {
                path: 'sync-now/:id',
                name: 'ngfw-policies-template-configuration-sync-now-id',
                component: Sync,
                meta: breadCrumbBuilder([['sync_now', { name: 'ngfw-policies-template-configuration-sync-now-id' }]]),
              },
            ],
          },
          {
            path: ':category/:type',
            name: 'ngfw-policies-category-type',
            component: PolicyCategoryType,
            meta: {
              breadCrumb(route) {
                return breadCrumbBuilder([
                  [
                    [
                      'policies_category_type',
                      {
                        'category': route.params.category,
                        'type': route.params.type.replace('-', '_'),
                      },
                    ],
                    { name: 'ngfw-policies-category-type' },
                  ],
                ]).breadCrumb
              },
            },
          },
        ],
      },
      // templates mfw
      {
        path: 'templates/micro-edge',
        component: MfwMain,
        meta: {
          breadCrumb(route) {
            // do not show a link if you are already on this page (there is no landing page)
            let to = { name: 'mfw-templates' }
            if (route.name === 'mfw-policies-types') {
              to = {}
            }

            return breadCrumbBuilder([['micro_edge_templates', to]]).breadCrumb
          },
        },
        children: mfwTemplatesRoutes,
      },
      // appliances paths
      {
        path: 'my-appliances',
        component: Parent,
        children: [
          {
            path: '',
            name: 'appliances',
            component: Appliances,
          },
          {
            path: 'add/:id?',
            name: 'appliances-add',
            component: AppliancesAdd,
            meta: breadCrumbBuilder([['add_appliance', { name: 'appliances-add' }]]),
          },
          {
            path: 'update',
            name: 'appliances-update',
            component: AppliancesUpdate,
            meta: breadCrumbBuilder([['update_software', { name: 'appliances-update' }]]),
          },
          {
            path: ':id',
            props: true,
            meta: {
              keyBuilder: route => `appliance-${route.params.id}`,
              breadCrumb: breadCrumbBuilder([['appliance_details', { name: 'appliances-id' }]]).breadCrumb,
            },
            component: ApplianceDetails,

            children: [
              {
                path: '',
                name: 'appliances-id',
                component: ApplianceDashboard,
              },
              {
                path: 'policies',
                name: 'appliances-policies',
                component: AppliancePolicies,
              },
              {
                path: 'clients',
                name: 'appliances-clients',
                component: ApplianceClients,
              },
              {
                path: 'active-sessions',
                name: 'appliances-active-sessions',
                component: ApplianceActiveSessions,
              },
              {
                path: 'appliance-reports',
                name: 'appliances-reports',
                component: ApplianceReports,
              },
              {
                path: 'appliance-reports/:viewId',
                name: 'appliances-report-details',
                component: ApplianceReportDetails,
              },
              {
                path: 'wan-policies',
                name: 'appliances-wan-policies',
                component: ApplianceWanPolicies,
              },
              {
                path: 'wan-policies/:policyId',
                name: 'appliances-wan-policies-edit',
                component: ApplianceWanPolicyEdit,
              },
              {
                path: 'dynamic-blocklist',
                name: 'appliances-dynamic-lists',
                component: ApplianceDynamicLists,
              },
              {
                path: 'status-analyzers',
                name: 'appliances-status-analyzers',
                component: ApplianceAnalyzers,
              },
              {
                path: 'interfaces',
                name: 'appliances-interfaces',
                component: ApplianceInterfaces,
              },
              {
                path: 'interfaces/add/:type',
                name: 'appliances-interfaces-add',
                component: ApplianceInterfaceEdit,
              },
              {
                path: 'interfaces/:device',
                name: 'appliances-interfaces-edit',
                component: ApplianceInterfaceEdit,
              },
              {
                path: 'appliance-logging',
                name: 'appliances-logging',
                component: ApplianceLogging,
              },
              {
                path: 'database',
                name: 'database',
                component: Database,
              },
              {
                path: 'appliance-system',
                name: 'appliances-system',
                component: ApplianceSystem,
              },
              {
                path: ':settings(filter|access|port-forward|shaping|nat|wan-rules|bypass)',
                name: 'appliances-rules',
                component: ApplianceRules,
              },
              {
                path: ':settings',
                name: 'appliances-settings',
                component: ApplianceSettings,
              },
            ],
          },
        ],
      },
      // this is for legacy routing from ngfw setup wizard
      {
        path: 'add/:id?',
        redirect: { name: 'appliances-add' },
      },
    ],
  },
]
