<template>
  <rules-list
    :title="$t(ruleType.replace(/-/g, '_'))"
    :description="ruleType === 'shaping' ? $t('shaping_rules_description') : undefined"
    :rules="rules"
    :has-cloud-policies="hasCloudPolicies"
    :cloud-config-id="cloudConfigId"
    :type="ruleType"
    @refresh="onRefresh"
  >
    <template #actions="{ updatedRules, isDirty }">
      <u-btn :min-width="null" :disabled="readOnly" class="mr-2" @click="onReset">{{ $t('reset_to_defaults') }}</u-btn>
      <u-btn :min-width="null" :disabled="!isDirty" @click="onSave(updatedRules)">
        {{ $t('save') }}
      </u-btn>
    </template>
  </rules-list>
</template>

<script>
  import get from 'lodash/get'
  import cloneDeep from 'lodash/cloneDeep'
  import { RulesList, ruleDefs } from 'vuntangle'
  import settingsMixin from './settingsMixin'

  export default {
    components: { RulesList },
    mixins: [settingsMixin],

    provide() {
      return {
        $remoteData: () => ({
          interfaces: this.interfaces,
          wanPolicies: this.wanPolicies,
          serviceStatus: this.serviceStatus,
          etmNetworksUri: '/appliances/networks',
          classifyApplications: this.applications,
        }),
        $features: this.appliance.features,
        $readOnly: this.readOnly,
      }
    },

    data() {
      return {
        rulesMap: {
          'port-forward': ['port-forward-rules'],
          'shaping': ['prioritization-rules', 'limiting-rules'],
          'nat': ['nat-rules'],
          'wan-rules': ['command-center-rules', 'user-wan-rules'],
          'filter': ['filter-rules'],
          'access': ['access-rules'],
          'bypass': ['bypass-rules'],
        },
        defaultsMap: {
          'port-forward': 'firewall/tables/port-forward',
          'shaping': 'firewall/tables/shaping',
          'nat': 'firewall/tables/nat',
          'wan-rules': 'wan-rules',
          'filter': 'firewall/tables/filter',
          'access': 'firewall/tables/access',
          'bypass': 'bypass',
        },
      }
    },

    computed: {
      /**
       * one of the 5 available rule types
       * `port-forward`, `shaping`, `nat`, `wan-rules`, `filter`, `access`
       */
      ruleType: ({ $route }) => $route.params.settings,
      // rule configuration names associated with a given rule type
      ruleConfigs: ({ rulesMap, ruleType }) => rulesMap[ruleType],

      rules: ({ boxSettings, ruleConfigs, appliance }) => {
        const rules = {}

        ruleConfigs.forEach(confName => {
          const conf = ruleDefs[confName]
          // for rules found in table chains have to lookup each chain
          if (conf.chains) {
            const chain = get(boxSettings, conf.chains)?.find(c => c.name === confName)
            // if there are no command center rules defined do not show them
            if (confName === 'command-center-rules' && !chain?.rules?.length) return
            // do not show limiting rules if not `hasLimitingRules` feature
            if (confName === 'limiting-rules' && !appliance?.features.hasLimitingRules) return
            rules[confName] = chain.rules
          }
          // for rules not being in chains, e.g bypass rules
          if (conf.path) {
            rules[confName] = get(boxSettings, conf.path)?.rules
          }
        })

        return rules
      },

      // interfaces used for _INTERFACE_ZONE conditions types
      interfaces: ({ $store }) => $store.getters['appliances/zoneInterfaces'],

      // Wan Policies used for the WAN Rules
      wanPolicies: ({ boxSettings }) =>
        boxSettings?.wan.policies.map(policy => ({ text: policy.description, value: policy.policyId })),

      // Status of services used on validating APPLICATION or GEOIP based conditions
      serviceStatus: ({ boxSettings }) => ({
        application_control: boxSettings?.application_control.enabled,
        geoip: boxSettings?.geoip.enabled,
      }),

      // classify applications used for APPLICATION based conditions
      applications: ({ $store }) => $store.getters['appliances/getApplianceClassifyApplications'],

      cloudConfigId: ({ boxSettings, ruleType }) => (ruleType === 'bypass' ? boxSettings.cloud_managed?.bypass : null),

      hasCloudPolicies: ({ $store }) => $store.getters['appliances/hasCloudPolicies'],
    },

    methods: {
      /**
       * calls the mixin `saveSettings` method
       * @param {Array} updatedRules - the Rule(s) as updated in shared component
       */
      onSave(updatedRules) {
        switch (this.ruleType) {
          case 'bypass': {
            const settings = cloneDeep(this.boxSettings.bypass)
            settings.rules = updatedRules['bypass-rules']
            this.saveSettings('bypass', settings)
            break
          }
          case 'wan-rules': {
            const settings = cloneDeep(this.boxSettings.wan)
            const localChain = settings.policy_chains.find(chain => chain.name === 'user-wan-rules')
            localChain.rules = updatedRules['user-wan-rules']
            this.saveSettings('wan', settings)
            break
          }
          default: {
            const settings = cloneDeep(this.boxSettings?.firewall.tables[this.ruleType])
            const chains = settings.chains
            Object.entries(updatedRules).forEach(([key, rules]) => {
              const chain = chains.find(chain => chain.name === key)
              chain.rules = rules
            })
            this.saveSettings(`firewall/tables/${this.ruleType}`, settings)
          }
        }
      },

      /**
       * calls the mixin `onResetToDefaults`
       */
      onReset() {
        this.onResetToDefaults(this.defaultsMap[this.ruleType])
      },

      /**
       * refreshes settings on grid Refresh action
       */
      async onRefresh() {
        await this.fetchSettings()
      },
    },
  }
</script>
