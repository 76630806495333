<template>
  <div class="d-flex flex-column flex-grow-1 ma-4">
    <div class="d-flex justify-space-between align-center mb-4">
      <div class="d-flex flex-row justify-center">
        <v-breadcrumbs class="pa-0 ma-0 text-uppercase">
          <span v-for="(label, index) in breadcrumbs" :key="label">
            <v-breadcrumbs-item
              v-if="index < step + 1"
              tag="a"
              :disabled="index >= step || (index === 2 && step === 3 && !selectedPolicies.length)"
              class="text-caption font-weight-bold"
              @click="goTo(index + 1)"
            >
              {{ $t(label) }}
            </v-breadcrumbs-item>
            <v-icon v-if="index < step && !(index === 2 && step === 3 && !selectedPolicies.length)">
              mdi-chevron-right
            </v-icon>
          </span>
        </v-breadcrumbs>
      </div>
      <div>
        <v-btn text class="text-capitalize mr-2" @click="onCancel">{{ $t('back') }}</v-btn>
        <v-btn depressed color="primary" class="text-capitalize" @click="goNext()">{{ $t(continueLabel) }}</v-btn>
      </div>
    </div>
    <u-grid
      v-if="step === 1"
      id="policies-grid"
      key="policies-grid"
      selection-type="multiAction"
      row-node-id="id"
      :no-data-message="noDataMessage"
      :column-defs="commonColumnDefs"
      :fetching="fetching"
      :row-data="policies"
      :selection.sync="selectedPolicies"
      :framework-components="frameworkComponents"
      @refresh="fetchPolicies(true)"
    />
    <u-grid
      v-if="step === 2"
      id="order-grid"
      key="order-grid"
      row-node-id="id"
      :enable-refresh="false"
      :no-data-message="noDataMessage"
      :column-defs="commonColumnDefs"
      :row-data="orderedPolicies"
      :framework-components="frameworkComponents"
      custom-ordering
      :custom-grid-options="{
        onRowDragEnd: updateOrder,
      }"
    />
    <div v-if="step === 3">
      <div>
        <h1 class="headline mb-2" style="font-weight: bold">{{ $t('configuration_templates') }}</h1>
        <ValidationObserver ref="obs">
          <div v-for="(configs, type) in globalConfigs" :key="type">
            <v-row class="align-center mx-0">
              <v-col cols="8" md="2" class="flex-grow-1">
                <label>{{ $t(templatesConfig[type].text) }}: </label>
              </v-col>
              <v-col cols="6" md="4" class="flex-grow-1">
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <u-autocomplete
                    v-model="selectedGlobalConfig[type]"
                    :items="configs"
                    clearable
                    item-text="Name"
                    item-value="Id"
                    :error-messages="errors"
                  >
                    <template #selection="{ item }">
                      <span class="selection-text" style="overflow: hidden; text-overflow: ellipsis">
                        {{ item.Name }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span class="item-text" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                        {{ item.Name }}
                      </span>
                    </template>
                    <template v-if="errors.length" #append>
                      <u-errors-tooltip :errors="errors" />
                    </template>
                  </u-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import { isEqual } from 'lodash'
  import {
    columnDefs,
    Type,
    ConditionsRenderer,
    ConditionGroupsRenderer,
    OrderRenderer,
    NameRenderer,
    templatesConfig,
  } from 'vuntangle/pm'
  import { hydratePoliciesData } from '../hydration'

  export default {
    data() {
      return {
        step: 1,
        drag: false,
        selectedPolicies: [], // policies in selection grid
        orderedPolicies: [], // policies in ordering grid
        orderedPoliciesIds: [], // source of truth for ordered selected policies ids that gets updateded upon selection/reorder
        frameworkComponents: {
          ConditionsRenderer,
          ConditionGroupsRenderer,
          OrderRenderer,
          NameRenderer,
        },
        objectType: Type.Policy,
        globalConfigTypes: Object.keys(templatesConfig).filter(key => templatesConfig[key].category === 'global'),
        selectedGlobalConfig: {}, // Object to hold selected options for each type
        templatesConfig,
      }
    },
    computed: {
      assignments: ({ $store }) => $store.getters['policyManager/getApplianceAssignment'],
      fetching: ({ $store, objectType }) => $store.getters['policyManager/fetching'](objectType),
      selectedAppliances: ({ $route }) => $route.params.selectedAppliances,
      policies: ({ $store }) => {
        const policies = $store.getters['policyManager/getObjectsByType'](Type.Policy)
        return hydratePoliciesData(policies)
      },
      breadcrumbs: ({ selectedPolicies }) =>
        !selectedPolicies.length
          ? ['appliances', 'policies', 'global_configuration']
          : ['appliances', 'policies', 'policy_order', 'global_configuration'],
      continueLabel: ({ step, selectedPolicies }) => {
        switch (step) {
          case 1:
            return selectedPolicies.length ? 'edit_policy_order' : 'next_step'
          case 2:
            return 'choose_global_configuration'
          case 3:
            return 'sync'
        }
      },
      commonColumnDefs: () => columnDefs.getCommonColumnDefs(),
      noDataMessage() {
        return this.policies.length > 0 ? this.$t('no_filtered_data_policies') : this.$t('no_data_defined_policies')
      },
      /**
       * Used in step 4 of assignment; returns a list of objects, where key is global config type and value is a list of
       * objects of that specific type, containing name and id fields
       *
       * {
       *   "mfw-config-global-dns": [
       *    { "Id": "current", "Name": "Not Assigned" },
       *    { "Id": "75323a27-95fd-4b58-b125-50b28c104e33", "Name": "test"}
       *   ],
       *   "mfw-config-global-statusanalyzers": [
       *    { "Id": "current", "Name": "Not Assigned" },
       *    { "Id": "3660e2e0-e186-4a2e-b1dd-c44b7d69a927", "Name": "some name"}
       *   ]
       * }
       */
      globalConfigs: ({ globalConfigTypes, $store, currentObject, defaultObject, selectedAppliances }) => {
        const globalConfigs = globalConfigTypes.reduce((acc, type) => {
          const storeObjects = $store.getters['policyManager/getObjectsByType'](type).map(el => ({
            Name: el.Name,
            Id: el.Id,
          }))
          acc[type] = [currentObject, defaultObject, ...storeObjects]
          return acc
        }, {})

        // remove Database and Static Routes from global templates list
        // if at least one appliance is not EOS, or has software version below 6.0
        selectedAppliances.forEach(appliance => {
          if (!appliance.features.hasDatabase) {
            delete globalConfigs[Type.ConfigGlobalDatabase]
          }
          if (!appliance.features.hasStaticRouteGlobalConfig) {
            delete globalConfigs[Type.ConfigGlobalStaticRoutes]
          }
          if (!appliance.features.hasDenialOfService) {
            delete globalConfigs[Type.ConfigGlobalDenialOfService]
          }
          if (!appliance.features.hasBypass) {
            delete globalConfigs[Type.ConfigGlobalBypass]
          }
        })

        return globalConfigs
      },
      currentObject: ({ $i18n }) => ({ Id: 'current', Name: $i18n.t('keep_current_settings') }),
      defaultObject: ({ $i18n }) => ({ Id: 'default', Name: $i18n.t('use_default_settings') }),

      /**
       * computes if there are appliances going to be synced for the first time
       * @param {Object} vm - vue instance
       * @param {Array} vm.assignments - all the assignments
       * @param {Array} vm.selectedAppliances - appliances to be synced
       * @returns {Boolean}
       */
      firstTimeSync: ({ assignments, selectedAppliances }) => {
        const selectedAppliancesIds = selectedAppliances?.map(appl => appl.Uid)
        const assignmetsAppliancesIds = assignments?.map(asgn => asgn.appliance_id)

        return selectedAppliancesIds.some(id => !assignmetsAppliancesIds.includes(id))
      },
    },
    watch: {
      step: {
        handler(step) {
          if (step === 2) {
            this.setPoliciesOrder()
          }
        },
        immediate: true,
      },
    },

    mounted() {
      this.fetchPolicies()
      this.fetchGlobalConfigurations()

      // redirect to assignment if no selected appliances
      if (!this.selectedAppliances?.length) {
        this.redirectToAssignments()
      } else {
        this.preselectPolicies()
        this.preselectGlobalConfigs()
      }
    },
    methods: {
      /**
       * Initializes the ordered ids and the selection based on appliances(s) existing provisioned policies
       * @returns {void}
       */
      preselectPolicies() {
        let policiesIds = []
        // flag to keep track if all selected appliances have the same policies applied in same order
        let allPoliciesEqual = true

        for (const appliance of this.selectedAppliances) {
          // extract ordered policies ids for appliance as set in assignments
          const appliancePolicyIds = this.assignments
            .filter(assignment => assignment.appliance_id === appliance.Uid && assignment.object_type === Type.Policy) // filter by appliance
            .sort((a1, a2) => (a1.ordering < a2.ordering ? -1 : 1)) // sort by `ordering`
            .map(assignment => assignment.object_id) // return ids

          if (!policiesIds.length) policiesIds = appliancePolicyIds

          // if multiple appliances selected, check if ids are the same
          if (!isEqual(policiesIds, appliancePolicyIds)) {
            allPoliciesEqual = false
            break
          }
        }

        if (allPoliciesEqual) this.orderedPoliciesIds = policiesIds

        // set initial selection on the grid
        this.selectedPolicies = [...this.policies.filter(policy => this.orderedPoliciesIds.includes(policy.id))]
      },

      /**
       * computes selected value against each global template type
       */
      preselectGlobalConfigs() {
        this.selectedGlobalConfig = this.globalConfigTypes.reduce((selected, type) => {
          // find assigned template id against each appliance
          const applianceAssignments = this.selectedAppliances.map(appliance => {
            // find the assignment against the type and appliance
            const assignment = this.assignments.find(
              assignment => assignment.object_type === type && assignment.appliance_id === appliance.Uid,
            )
            // if no assignment found, use current settings
            return assignment ? assignment.object_id : this.currentObject.Id
          })
          // if all appliances are assigned same template id, select that template otherwise use current
          selected[type] = new Set(applianceAssignments).size === 1 ? applianceAssignments[0] : this.currentObject.Id
          return selected
        }, {})
      },

      /**
       * Sets the order of the selected policies based on selection and ordered ids
       */
      setPoliciesOrder() {
        const selectedPoliciesIds = this.selectedPolicies.map(p => p.id)

        // filter out those policies ids that are no longer selected
        this.orderedPoliciesIds = this.orderedPoliciesIds.filter(id => selectedPoliciesIds.includes(id))

        // push new (ordered last) ids that were not yet in ordered list
        selectedPoliciesIds.forEach(id =>
          !this.orderedPoliciesIds.includes(id) ? this.orderedPoliciesIds.push(id) : undefined,
        )

        // create ordered list row data based on updated ordered ids
        const orderedList = []
        this.orderedPoliciesIds.forEach(id => {
          const policy = this.selectedPolicies.find(policy => policy.id === id)
          orderedList.push(policy)
        })
        this.orderedPolicies = orderedList
      },

      onCancel() {
        if (this.step === 3 && !this.selectedPolicies.length) {
          this.step = 1
          return
        }
        if (this.step > 1) this.step -= 1
        else this.$router.push({ name: 'pm-assignment' })
      },

      /**
       * Increases the step counter
       */
      goNext() {
        if (this.step === 1 && !this.selectedPolicies.length) {
          this.step = 3
          return
        }
        if (this.step < 3) this.step += 1
        else {
          this.preSync()
        }
      },

      /**
       * Moves the step to a new value
       *
       * @param newStep
       */
      goTo(newStep) {
        if (newStep === 1) {
          this.redirectToAssignments()
        } else {
          this.step = newStep - 1
        }
      },

      // redirects to assignment page
      redirectToAssignments() {
        this.$router.push({ name: 'pm-assignment' })
      },

      /**
       * Updates the policies ids order than populates the ordered grid row data
       *
       * @param gridEvent
       */
      updateOrder(gridEvent) {
        const orderedIds = []

        gridEvent.api.forEachNode(node => {
          orderedIds.push(node.data.id)
        })

        this.orderedPoliciesIds = orderedIds
        this.setPoliciesOrder()
      },

      /**
       * Fetches the policies
       *
       * @param {boolean} force flag to force fetch
       */
      fetchPolicies(force = false) {
        this.$store.dispatch('policyManager/fetchObjectsByType', { type: this.objectType, force })
      },

      /**
       * Fetches the global configuration templates
       *
       * @param {boolean} force flag to force fetch
       */
      fetchGlobalConfigurations(force = false) {
        this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-config-global', force })
      },

      /**
       * Validates the fields and shows a confirmation dialog in case
       * one of the appliances is going to be synced for the first time
       */
      async preSync() {
        const valid = await this.$refs.obs.validate()

        if (!valid) return

        if (!this.firstTimeSync) {
          this.sync()
          return
        }

        const vm = this

        // show confirmation
        this.$vuntangle.confirm.show({
          title: this.$t('important'),
          icon: 'mdi-alert',
          iconColor: 'warning',
          progress: false,
          message: this.$t('first_time_sync_confirmation'),
          buttons: [
            {
              name: this.$vuntangle.$t('cancel'),
              props: {
                minWidth: null,
                small: false,
                text: true,
                depressed: true,
                class: 'text-capitalize',
              },
              handler() {
                this.onClose()
              },
            },
            {
              name: this.$t('sync'),
              props: {
                minWidth: null,
                small: false,
                depressed: true,
                class: 'text-capitalize px-4',
              },
              handler() {
                vm.sync()
                this.onClose()
              },
            },
          ],
        })
      },

      /**
       * Dispatches action to update appliance assignments (sync action)
       *
       * @returns {Promise<void>}
       */
      async sync() {
        this.$store.commit('SET_PAGE_LOADER', true)
        const applianceIds = this.selectedAppliances.map(appliance => appliance.Uid)
        const globalConfigs = Object.entries(this.selectedGlobalConfig).map(([k, v]) => ({ type: k, value: [v] }))
        const policyIds = this.orderedPolicies.map(policy => policy.id)

        const response = await this.$store.dispatch('policyManager/updateApplianceAssignments', {
          applianceIds,
          assignments: [{ type: Type.Policy, value: policyIds }, ...globalConfigs],
        })

        this.$store.commit('SET_PAGE_LOADER', false)

        if (response) {
          this.$vuntangle.toast.add(this.$t('policy_syncing_initiated'))
        }

        this.redirectToAssignments()
      },
    },
  }
</script>
