/**
 * This plugin is a collection of globally available methods used throughout the project
 * to define columns for various grids.  By centralizing them here, multiple users of the same
 * grid can access the columns in a standardized fashion.
 */
import Vue from 'vue'
import { column } from 'vuntangle/pm'
import vuntangle from '@/plugins/vuntangle'
import i18n from '@/plugins/vue-i18n'
import subscriptions from '@/plugins/ut/ut-subscriptions'
import util from '@/plugins/ut/ut-util'
import store from '@/store'

const grids = {
  /**
   * Sends back a set of column defs to be used for all appliances grids
   * @param {Object} customCols - the columns passed in display order and extra props like 'hide'
   * @param {Array} removeCols - the columns we want to remove from the grid
   * @param {string} fieldPrefix - passed when appliance info is not directly present in params.data and instead in params.data[fieldPrefix]
   * @returns array of Objects
   */
  getApplianceGridColumns(customCols, removeCols, fieldPrefix = '') {
    const statusCol = this.getApplianceStatusColumn(fieldPrefix)
    const licenseCol = this.getApplianceLicenseColumn(fieldPrefix)
    const allColumns = {
      status: statusCol,
      license: licenseCol,
      appliance: {
        headerName: i18n.t('appliance'),
        field: 'Hostname',
        flex: 0,
        width: 300,
        cellClass: params => (!params.value ? 'cell-no-value' : ''),
        valueFormatter: params => params.value || i18n.t('unknown_hostname'),
        valueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.Hostname
        },
      },
      tag: {
        headerName: i18n.tc('label', 1),
        field: 'ApplianceTag',
        cellClass: params => (!params.value ? 'cell-no-value' : ''),
        valueFormatter: params => params.value || i18n.t('label_not_assigned'),
        valueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.ApplianceTag
        },
      },
      // add 'management address' column for sapphire UI only, as only ruby devices support this
      ...(Vue.prototype.$isSapphire
        ? {
            management_address: {
              headerName: i18n.t('management_address'),
              field: 'ManagementAddress',
              cellClass: params => (!params.value ? 'cell-no-value' : ''),
              valueFormatter: params => params.value || i18n.t('unknown_ip'),
              valueGetter: params => {
                const data = fieldPrefix ? params.data[fieldPrefix] : params.data
                return data?.ManagementAddress
              },
              comparator: (a, b) => vuntangle.util.compareIpAny(a, b),
            },
          }
        : {}),
      ip_address: {
        headerName: i18n.t('ip_address'),
        field: 'IpAddress',
        cellClass: params => (!params.value ? 'cell-no-value' : ''),
        valueFormatter: params => params.value || i18n.t('unknown_ip'),
        valueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.IpAddress
        },
        comparator: (a, b) => vuntangle.util.compareIpAny(a, b),
      },
      software_version: {
        headerName: i18n.t('version'),
        field: 'SoftwareVersion',
        cellClass: params => (!params.value ? 'cell-no-value' : ''),
        cellStyle: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.updatesAvailable ? { color: '#FF9731', fontWeight: 'bold' } : ''
        },
        valueFormatter: params => params.value || i18n.t('unknown_version'),
        valueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.SoftwareVersion
        },
        // tooltip
        tooltipValueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          if (data?.updatesAvailable) {
            return data.SoftwareVersion + ' ' + i18n.t('appliance_upgrade_available')
          }
          return params.value || i18n.t('unknown_version')
        },
        comparator: (a, b) => util.compareVersions(a, b),
      },
      uid: {
        headerName: i18n.t('uid'),
        field: 'Uid',
        flex: 0,
        width: 350,
        hide: true,
        valueFormatter: params => (params.value ? util.obfuscateUid(params.value) : i18n.t('not_assigned')),
        valueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.Uid
        },
      },
      serial_number: {
        headerName: i18n.t('serial_number'),
        field: 'SerialNumber',
        cellRenderer: params => params.value || `<span class="cell-no-value">${i18n.t('not_found')}</span>`,
        valueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.ApplianceSerialNumber || data?.SerialNumber
        },
      },
      network: {
        headerName: i18n.t('network'),
        field: 'NetworkInfo',
        cellClass: params => (!params.value ? 'cell-no-value' : ''),
        valueFormatter: params => params.value?.split(':')[0] || i18n.t('not_assigned'),
        valueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.NetworkInfo
        },
      },
      host_count: {
        headerName: i18n.t('host_count'),
        field: 'HostCount',
        valueFormatter: params => (params.value >= 0 ? params.value : 0),
        valueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.HostCount
        },
      },
      product_line: {
        headerName: i18n.t('product_line'),
        field: 'ProductLine',
        hide: true,
        valueFormatter: params => (params.value === 'MFW' ? 'Micro Edge' : params.value),
        valueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.ProductLine
        },
      },
      location: {
        headerName: i18n.t('location'),
        field: 'ApplianceLocation',
        cellClass: params => (!params.value ? 'cell-no-value' : ''),
        valueFormatter: params => params.value || i18n.t('unknown'),
        valueGetter: params => {
          const data = fieldPrefix ? params.data[fieldPrefix] : params.data
          return data?.ApplianceLocation
        },
      },
      last_seen: {
        headerName: i18n.t('last_seen'),
        field: 'LastSeen',
        valueGetter: ({ data }) => (fieldPrefix ? data[fieldPrefix] : data),
        valueFormatter: ({ value }) =>
          value?.IsConnectedToCmd
            ? i18n.t('now')
            : vuntangle.dates.formatDateFromApi(value?.LastSeen) || i18n.t('never'),
        comparator: (a, b) => {
          a = a?.IsConnectedToCmd ? 'now' : a?.LastSeen
          b = b?.IsConnectedToCmd ? 'now' : b?.LastSeen
          return vuntangle.dates.compareDates(a, b)
        },
      },
    }

    /**
     * Override or extend columns with customCols definitions if provided
     * otherwise use the default definitions
     */
    const columnDefs = []
    for (const [key, column] of Object.entries(allColumns)) {
      if (removeCols && removeCols.includes(key)) {
        // we don't want to have this column, skip to the next key
        continue
      }
      if (customCols && customCols[key]) {
        columnDefs.push(Object.assign(column, customCols[key]))
      } else {
        columnDefs.push(column)
      }
    }

    return columnDefs
  },

  // get appliance columns for mfw and ngfw policies, uses the original appliance columns just hides a lot of
  // columns to look better in a dialog
  getPolicyApplianceColumnDefs(removeCols) {
    return this.getApplianceGridColumns(
      {
        appliance: { flex: 1 },
        tag: { flex: 1 },
        status: { hide: false },
        license: { hide: true },
        management_address: { hide: false },
        ip_address: { hide: true },
        software_version: { hide: true },
        uid: { flex: 1, hide: false },
        serial_number: { hide: true },
        network: { hide: true },
        host_count: { hide: true },
        location: { hide: true },
        last_seen: { hide: true },
      },
      removeCols,
    )
  },

  // get appliance columns for policy manager , uses the original appliance columns just hides a lot of
  getPMAppliancesColumnDefs(removeCols) {
    const cols = this.getApplianceGridColumns(
      {
        appliance: { flex: 1 },
        tag: { flex: 1 },
        status: { hide: false },
        license: { hide: true },
        management_address: { hide: false },
        ip_address: { hide: false },
        software_version: { hide: true },
        uid: { hide: true },
        serial_number: { hide: false },
        network: { hide: true },
        host_count: { hide: true },
        location: { hide: false },
        last_seen: { hide: false },
      },
      removeCols,
    )

    cols.push(
      {
        colId: 'policies',
        headerName: i18n.t('policies'),
        field: 'Policies',
        flex: 1,
        hide: false,
        autoHeight: true,
        wrapText: true,
        cellClass: params => (!params.value ? 'cell-no-value' : ''),
        cellRenderer: 'AssociatedPolicyRenderer',
        valueGetter: ({ data }) => data.Policies.map(policy => ({ id: policy.Id, name: policy.Name })),
        valueFormatter: params => (params.value || []).map(item => item.name).join(', '),
      },
      {
        colId: 'global_templates',
        headerName: i18n.t('global_templates'),
        field: 'GlobalTemplates',
        flex: 1,
        hide: false,
        autoHeight: true,
        wrapText: true,
        cellClass: params => (!params.value ? 'cell-no-value' : ''),
        cellRenderer: 'AssociatedPolicyRenderer',
        valueGetter: ({ data }) => data.GlobalTemplates.map(object => ({ id: object.Id, name: object.Name })),
        valueFormatter: params => (params.value || []).map(item => item.name).join(', '),
      },
      {
        headerName: i18n.t('last_updated'),
        field: 'LastUpdated',
        width: 350,
        hide: false,
        valueGetter: ({ data }) => vuntangle.dates.formatDateFromApi(data.LastUpdated),
        valueFormatter: params => params.value || '',
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      column.syncStatus('SyncStatus'),
      column.lastSynced('LastSynced'),
    )

    // Map of cols used for ordering
    const colsOrder = [
      'StatusFlags',
      'SyncStatus',
      'Hostname',
      'ApplianceTag',
      'SerialNumber',
      'ManagementAddress',
      'IpAddress',
      'Policies',
      'GlobalTemplates',
      'LastSeen',
      'LastUpdated',
      'LastSynced',
      'ApplianceLocation',
    ]
    // sort cols using above map
    return cols.sort((a, b) => {
      a = colsOrder.indexOf(a.field)
      b = colsOrder.indexOf(b.field)
      // put any col not in above map at the end
      if (a === -1 || b === -1) {
        return b - a
      }
      return a - b
    })
  },

  /**
   * Returns column definition objects for the alerts history widget
   */
  getAlertColumnDefs() {
    return [
      {
        headerName: i18n.t('date_time'),
        flex: 0,
        width: 200,
        valueGetter: ({ data }) => data.DateCreated?.$date,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      {
        headerName: i18n.t('appliance'),
        field: 'Payload.Hostname',
        valueGetter: params => params.data.appliance?.Hostname || i18n.t('unknown_hostname'),
      },
      {
        headerName: i18n.t('details'),
        valueGetter: params => vuntangle.util.translateMessage(params.data.Message),
      },
      {
        headerName: i18n.tc('label', 1),
        valueGetter: params => params.data.appliance?.ApplianceTag || i18n.t('label_not_set'),
      },
    ]
  },
  /**
   * Returns column definition objects for the threats history widget
   */
  getThreatColumnDefs() {
    return [
      {
        headerName: i18n.t('date_time'),
        flex: 0,
        width: 200,
        valueGetter: ({ data }) => data.DateCreated?.$date,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      {
        headerName: i18n.t('appliance'),
        field: 'Payload.Hostname',
      },
      {
        headerName: i18n.t('uid'),
        field: 'DeviceId',
        hide: true,
        cellRenderer: params => {
          return params.data.DeviceId ? util.obfuscateUid(params.data.DeviceId) : i18n.t('not_assigned')
        },
      },
      {
        headerName: i18n.t('threat_info'),
        field: 'Message',
      },
      {
        headerName: i18n.tc('label', 1),
        cellRenderer: params => {
          return util.uidToApplianceTag(params.data.DeviceId)
        },
      },
    ]
  },
  /**
   * returns column definitions for the audit history widget
   * @returns {[Array of column defs]}
   */
  getAuditColumnDefs() {
    return [
      {
        headerName: i18n.t('date_time'),
        flex: 0,
        width: 200,
        valueGetter: ({ data }) => data.DateCreated?.$date,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value) || i18n.t('unknown'),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      {
        headerName: i18n.t('uid'),
        field: 'DeviceId',
        hide: true,
        valueGetter: params =>
          params.data.DeviceId ? util.obfuscateUid(params.data.DeviceId) : i18n.t('not_assigned'),
      },
      {
        headerName: i18n.t('details'),
        field: 'Message',
        valueGetter: params => util.obfuscateUidsInString(params.data.Message),
      },
    ]
  },
  /**
   * Returns column defintions for the network performance and link information widgets
   * @param {Object|null} columns - the columns passed in display order and extra props like 'hide'   */
  getNetworkPerformanceColumnDefs(customCols = null) {
    const allColumns = {
      appliance: {
        headerName: i18n.t('appliance'),
        field: 'ApplianceHostname',
        hide: true,
      },
      label: {
        headerName: i18n.tc('label', 1),
        field: 'ApplianceTag',
        hide: true,
      },
      interfaceId: {
        headerName: i18n.t('interface_id'),
        field: 'InterfaceName',
        valueFormatter: params => {
          return params.data.InterfaceName ? params.data.InterfaceName : i18n.t('unknown')
        },
        hide: false,
      },
      UID: {
        headerName: i18n.t('uid'),
        field: 'Uid',
        hide: true,
        valueFormatter: params => {
          return params.data.Uid ? util.obfuscateUid(params.data.Uid) : i18n.t('not_assigned')
        },
      },
      download: {
        headerName: i18n.t('download'),
        field: 'AverageDownloadRate',
        valueFormatter: params => {
          return params.data.AverageDownloadRate
            ? util.bytesSecRenderer(params.data.AverageDownloadRate)
            : i18n.t('unknown')
        },
      },
      totalDownload: {
        headerName: i18n.t('total_download'),
        field: 'TotalDownload',
        valueFormatter: params => {
          return params.data.TotalDownload ? util.bytesRenderer(params.data.TotalDownload) : i18n.t('unknown')
        },
      },
      uploadRate: {
        headerName: i18n.t('upload_rate'),
        field: 'AverageUploadRate',
        valueFormatter: params => {
          return params.data.AverageUploadRate
            ? util.bytesSecRenderer(params.data.AverageUploadRate)
            : i18n.t('unknown')
        },
      },
      uploadTotal: {
        headerName: i18n.t('upload_total'),
        field: 'TotalUpload',
        valueFormatter: params => {
          return params.data.TotalUpload ? util.bytesRenderer(params.data.TotalUpload) : i18n.t('unknown')
        },
      },
      jitter: {
        headerName: i18n.t('jitter'),
        field: 'AverageJitter',
        valueFormatter: params => {
          return params.data.AverageJitter ? util.milliSecRenderer(params.data.AverageJitter) : i18n.t('unknown')
        },
      },
      latency: {
        headerName: i18n.t('latency'),
        field: 'AverageLatency',
        valueFormatter: params => {
          return params.data.AverageLatency ? util.milliSecRenderer(params.data.AverageLatency) : i18n.t('unknown')
        },
      },
      packetLoss: {
        headerName: i18n.t('packet_loss'),
        field: 'PacketLoss',
        valueFormatter: params => {
          return params.data.PacketLoss ? util.packetLossPercentageRenderer(params.data.PacketLoss) : i18n.t('unknown')
        },
      },
      startDate: {
        headerName: i18n.t('start_date'),
        field: 'StartDate',
        hide: true,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      endDate: {
        headerName: i18n.t('end_date'),
        field: 'EndDate',
        hide: true,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
    }
    /**
     * Override or extend columns with customCols definitions if provided
     * otherwise use the default definitions
     */
    const columnDefs = []
    for (const [key, column] of Object.entries(allColumns)) {
      if (customCols && customCols[key]) {
        columnDefs.push(Object.assign(column, customCols[key]))
      } else {
        columnDefs.push(column)
      }
    }
    return columnDefs
  },
  /**
   * returns column definitions for the detected intrusions grid
   */
  getDetectedIntrusionsColumnDefs() {
    return [
      {
        headerName: i18n.t('date_time'),
        valueGetter: ({ data }) => data.time_stamp?.time,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      {
        headerName: i18n.t('web_filter_blocked'),
        field: 'blocked',
      },
      {
        headerName: i18n.t('appliance'),
        valueGetter: params => util.uidToHostname(params.data.uid),
      },
      {
        headerName: i18n.t('uid'),
        field: 'uid',
        hide: true,
        valueGetter: params => (params.data.uid ? util.obfuscateUid(params.data.uid) : i18n.t('not_assigned')),
      },
      {
        headerName: i18n.tc('label', 1),
        valueGetter: params => util.uidToApplianceTag(params.data.uid),
        hide: true,
      },
      {
        headerName: i18n.t('intrusion_info'),
        field: 'msg',
      },
      {
        headerName: i18n.t('web_filter_category'),
        field: 'category',
        hide: true,
      },
      {
        headerName: i18n.t('source'),
        field: 'source_addr',
      },
      {
        headerName: i18n.t('source_port'),
        field: 'source_port',
      },
      {
        headerName: i18n.t('destination'),
        field: 'dest_addr',
      },
      {
        headerName: i18n.t('destination_port'),
        field: 'dest_port',
      },
      {
        headerName: i18n.t('protocol'),
        field: 'protocol',
        hide: true,
      },
      {
        headerName: i18n.t('class_type'),
        field: 'classtype',
        hide: true,
      },
    ]
  },
  /**
   * Returns definition of columns for the cloud backups widget
   */
  getCloudBackupsColumnDefs() {
    return [
      {
        headerName: i18n.t('date_time'),
        valueGetter: ({ data }) => (data.Timestamp ? data.Timestamp * 1000 : 0),
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      {
        headerName: i18n.t('name'),
        field: 'Name',
        cellRenderer: params => {
          return '<a target="_blank" href="' + util.mapBackupUrl(params.data.Link) + '">' + params.data.Name + '</a>'
        },
      },
      {
        headerName: i18n.t('md5'),
        field: 'FileMd5',
        hide: true,
      },
    ]
  },
  /**
   * Return definition of columns for account backups page
   */
  getAccountBackupsColumnDefs() {
    return [
      {
        headerName: i18n.t('date_time'),
        flex: 0,
        width: 450,
        valueGetter: ({ data }) => (data.Timestamp ? data.Timestamp * 1000 : 0),
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      {
        headerName: i18n.t('uid'),
        field: 'Uid',
        flex: 0,
        width: 350,
        hide: false,
        cellRenderer: params => (params.data.Uid ? util.obfuscateUid(params.data.Uid) : i18n.t('not_assigned')),
      },
      {
        headerName: i18n.tc('label', 1),
        field: 'ApplianceTag',
        valueGetter: params => util.uidToApplianceTag(params.data.Uid),
        cellRenderer: params => params.value || i18n.t('label_not_assigned'),
      },
      {
        headerName: i18n.t('name'),
        field: 'Name',
        cellRenderer: params => (params.data.Name ? params.data.Name : i18n.t('not_assigned')),
      },
      {
        headerName: i18n.t('download'),
        field: 'number',
        initialPinned: 'right',
        cellRenderer: 'ActionButton',
        cellRendererParams: {
          label: i18n.t('download'),
          color: 'primary',
          xSmall: true,
          click: params => {
            return (window.location.href = util.mapBackupUrl(params.data.Link))
          },
        },
        sortable: false,
      },
    ]
  },

  getSubscriptionsColumnDefs() {
    return [
      {
        headerName: i18n.t('number'),
        field: 'subscriptionName',
        cellRenderer: params => {
          if (params.data.status === 'Expired' || params.data.status === 'PAYFAIL') {
            return '<span style="color: red; font-weight: bold;">' + params.value + '</span>'
          }
          return params.value
        },
      },
      {
        headerName: i18n.t('description'),
        field: 'description',
        valueGetter: params => params.data.description || '',
      },
      {
        headerName: i18n.t('product_name'),
        field: 'productName',
      },
      {
        headerName: i18n.t('band_tier'),
        valueGetter: params => subscriptions.getSubscriptionMaxDevices(params.data),
        cellRenderer: params => {
          // TODO - fix sorting
          if (subscriptions.isHomeSubscription(params.data)) {
            return i18n.t('subscription_home')
          }
          if (params.value === 1000000) {
            // for Unlimited for XX subscriptions, show that in the seats
            return params.data.hardwareRestriction != null && params.data.hardwareRestriction !== 'U'
              ? i18n.t('subscription_unlimited') + ' / ' + params.data.hardwareRestriction
              : i18n.t('subscription_unlimited')
          }
          return params.value
        },
      },
      {
        headerName: i18n.t('status'),
        field: 'status',
        valueGetter: params => subscriptions.formatSubscriptionStatus(params.data.status),
        cellRenderer: params => {
          if (params.value === 'Expired' || params.value === 'PAYFAIL') {
            return '<span style="color: red; font-weight: bold;">' + params.value + '</span>'
          }
          return params.value
        },
      },
      {
        headerName: i18n.t('assigned_to'),
        field: 'assignedTo',
        valueGetter: params => subscriptions.calculateSubscriptionAssignedToField(params.data),
        cellStyle: params => {
          // red color if pay fail
          if (subscriptions.isPayfailSubscription(params.data)) {
            return { color: 'red' }
          }
          // orange color if shared
          if (params.data.isShared && params.data.customerIsRedeemer) {
            return { color: '#FF9731' }
          }
        },
        tooltipValueGetter: params => {
          const record = params.data
          if (subscriptions.isPayfailSubscription(record)) {
            return i18n.t('manage_subscription_payfail', {
              subscriptionName: record.subscriptionName,
            })
          }
          if (record.isShared) {
            if (record.customerIsRedeemer) {
              return i18n.t('sub_owner', [record.subscriptionCustomerEmail])
            } else {
              return i18n.t('sub_redeemer', [record.subscriptionCustomerEmail])
            }
          }
          return params.value
        },
      },
      {
        headerName: i18n.t('term'),
        field: 'term',
        valueGetter: params => params.data.term.term,
      },
      {
        headerName: i18n.t('renewal_date'),
        field: 'renewalDateUnformatted',
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value, false),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      {
        headerName: i18n.t('renewal_price'),
        field: 'renewalPrice',
        cellRenderer: params => {
          const price = params.value
          if (isNaN(price)) {
            return 'N/A'
          }
          // add currency symbol in renderer
          return util.moneyNumberForReport(price, params.data.currencySymbol)
        },
      },
      {
        headerName: i18n.t('auto_renew'),
        field: 'autoRenewal',
      },
      {
        headerName: i18n.t('purchase_order_number'),
        field: 'subPo',
      },
      {
        headerName: i18n.t('sku'),
        field: 'sku',
        hide: true,
      },
    ]
  },

  /**
   * Returns definition of columns for the mobile devices
   */
  getMobileDevicesColumnDefs() {
    return [
      {
        headerName: i18n.t('mobile_device_type'),
        field: 'DeviceType',
      },
      {
        headerName: i18n.t('last_login'),
        field: 'LastLogin',
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      {
        headerName: i18n.t('mobile_date_paired'),
        field: 'DatePaired',
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
    ]
  },
  /**
   * Returns definition of columns for the vpn appliances
   */
  getVpnAppliancesColumnDefs(removeCols) {
    const statusCol = this.getApplianceStatusColumn()
    const licenseCol = this.getApplianceLicenseColumn()
    const allColumns = {
      status: statusCol,
      license: licenseCol,
      appliance: {
        headerName: i18n.t('appliance'),
        field: 'Hostname',
        flex: 0,
        width: 265,
        cellClass: params => (!params.data.VpnStatus ? 'cell-no-value' : ''),
        cellRenderer: params => params.value || i18n.t('unknown_hostname'),
      },
      location: {
        headerName: i18n.t('location'),
        field: 'Location',
        hide: true,
        cellClass: params => (!params.data.VpnStatus ? 'cell-no-value' : ''),
        cellRenderer: params => params.value || i18n.t('unknown'),
      },
      ip: {
        headerName: i18n.t('ip_address'),
        field: 'IP',
        flex: 0,
        width: 150,
        cellClass: params => (!params.data.VpnStatus ? 'cell-no-value' : ''),
        cellRenderer: params => params.value || i18n.t('unknown_ip'),
      },
      subnets: {
        headerName: i18n.t('shared_subnets'),
        field: 'SharedSubnets',
        cellClass: params => (!params.data.VpnStatus ? 'cell-no-value' : ''),
        cellRenderer: params => (params.value.length > 0 ? params.value.join() : i18n.t('not_applicable')),
      },
      vpnStatus: {
        headerName: i18n.t('status'),
        field: 'VpnStatus',
        flex: 0,
        width: 100,
        cellClass: params => (!params.data.VpnStatus ? 'cell-no-value' : ''),
        cellRenderer: params => (params.value === true ? i18n.t('enabled') : i18n.t('disabled')),
      },
      uid: {
        headerName: i18n.t('uid'),
        field: 'Uid',
        flex: 0,
        width: 350,
        hide: true,
        cellClass: params => (!params.data.VpnStatus ? 'cell-no-value' : ''),
        cellRenderer: params => (params.data.Uid ? util.obfuscateUid(params.data.Uid) : i18n.t('not_assigned')),
      },
      notes: {
        headerName: i18n.t('notes'),
        field: 'Notes',
      },
    }

    const columnDefs = []
    for (const [key, column] of Object.entries(allColumns)) {
      if (removeCols && removeCols.includes(key)) {
        // we don't want to have this column, skip to the next key
        continue
      }

      columnDefs.push(column)
    }

    return columnDefs
  },
  /**
   * Creates array of column definitions for hosts grid.
   *
   * @param {Object|null} columns - the columns passed in display order and extra props like 'hide'
   *
   * @returns {array} info to define columns
   */
  getHostsGridColumns(customCols = null) {
    const allColumns = {
      vendorImage: {
        cellStyle: { 'padding-left': '14px', 'padding-right': '14px', 'padding-top': '12px' },
        sortable: false,
        filter: false,
        flex: 0,
        width: 20,
        cellRenderer: params =>
          params.data.VendorDetails.length > 0
            ? '<img src="' +
              require(`@/static/icons/vendors/${params.data.VendorDetails[0].VendorName.toLowerCase()}.svg`) +
              '" style="width: 20px; height: 20px;" />'
            : '',
      },
      hostname: {
        headerName: i18n.t('hostname'),
        field: 'HostName',
        valueFormatter: ({ value }) => util.formatHostname(value),
      },
      ipAddress: {
        headerName: i18n.t('ip_address'),
        field: 'IP',
      },
      active: {
        headerName: i18n.t('active'),
        valueGetter: ({ data }) => (data.CmdHost?.active !== undefined ? data.CmdHost.active : i18n.t('unknown')),
      },
      macAddress: {
        headerName: i18n.t('mac_address'),
        field: 'MacAddress',
      },
      label: {
        headerName: i18n.tc('label', 1),
        field: 'tagString',
        cellRenderer: params => {
          return util.uidToApplianceTag(params.data.Uid)
        },
      },
      macAddressVendor: {
        headerName: i18n.t('mac_address_vendor'),
        field: 'CmdHost.macVendor',
      },
      appliance: {
        headerName: i18n.t('appliance'),
        valueGetter: params => (params.data.UID ? util.uidToHostname(params.data.UID) : i18n.t('not_applicable')),
      },
      uid: {
        headerName: i18n.t('uid'),
        valueGetter: params => (params.data.UID ? util.obfuscateUid(params.data.UID) : i18n.t('not_assigned')),
      },
      operatingSystem: {
        headerName: i18n.t('operating_system'),
        field: 'OS',
      },
      quotaUsage: {
        headerName: i18n.t('quota_usage'),
        valueGetter: params =>
          !params.data.CmdHost || params.data.CmdHost.quotaSize === 0
            ? i18n.t('none')
            : util.formatBytes(params.data.CmdHost.quotaSize, 2, true) +
              '/' +
              (params.data.CmdHost.quotaSize - params.data.CmdHost.quotaRemaining) / params.data.CmdHost.quotaSize +
              '%',
      },
      licenseEntitled: {
        headerName: i18n.t('license_entitled'),
        valueGetter: params => (params.data.CmdHost?.entitled ? params.data.CmdHost.entitled.toString() : ' - '),
      },
      dateCreated: {
        headerName: i18n.t('date_created'),
        valueGetter: ({ data }) => data.CmdHost?.creationTime,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      dateUpdated: {
        headerName: i18n.t('date_updated'),
        valueGetter: ({ data }) => data.CmdHost?.lastCompletedTcpSessionTime,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
    }

    /**
     * Override or extend columns with customCols definitions if provided
     * otherwise use the default definitions
     */
    return customCols
      ? Object.entries(customCols).map(([key, column]) => Object.assign({}, allColumns[key], column))
      : Object.values(allColumns)
  },
  /**
   * Creates an array of column defs for templates grid.  Used both in policies page and in
   * add appliance function when user requests sync from an existing template
   * @param customCols - if populated, specifies which columns should be returned
   * @returns {[]} - array of column defs
   */
  getTemplateColumnDefs(customCols = null) {
    const allColumns = {
      'MasterApplianceUID': {
        headerName: i18n.t('master_appliance'),
        field: 'MasterApplianceUID',
        flex: 0,
        width: 300,
        valueGetter: params => util.getFormattedApplianceList([params.data.MasterApplianceUID], ','),
      },
      'TargetAppliances': {
        headerName: i18n.t('target_appliance'),
        field: 'TargetAppliances',
        flex: 0,
        width: 300,
        valueGetter: params => util.getFormattedApplianceList(params.data.TargetAppliances, ','),
      },
      'LastSyncHash': {
        headerName: i18n.t('last_sync_md5'),
        field: 'LastSyncHash',
        flex: 0,
        width: 300,
        hide: true,
        valueFormatter: params => params.value || 'N/A',
      },
      'LastSynced': {
        headerName: i18n.t('last_synced'),
        field: 'LastSynced',
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      'KeepInSyncEnabled': {
        headerName: i18n.t('keep_in_sync'),
        field: 'KeepInSyncEnabled',
        valueGetter: params => util.formatBoolean(params.data.KeepInSyncEnabled),
      },
      'DateCreated': {
        headerName: i18n.t('date_created'),
        field: 'DateCreated',
        hide: true,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      'DateUpdated': {
        headerName: i18n.t('date_updated'),
        field: 'DateUpdated',
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
    }

    /**
     * Override or extend columns with customCols definitions if provided
     * otherwise use the default definitions
     */
    const columnDefs = []
    for (const [key, column] of Object.entries(allColumns)) {
      if (!customCols || customCols.includes(key)) {
        columnDefs.push(column)
      }
    }

    return columnDefs
  },
  /**
   * code for displaying the appliance status icons - used multiple places
   * @param {string} fieldPrefix - passed when appliance info is not directly present in params.data and instead in params.data[fieldPrefix]
   * @returns column def for appliance status in any grid
   */
  getApplianceStatusColumn(fieldPrefix = '') {
    return {
      colId: 'OnlineStatus', // to avoid setting the field as default and diff between online and license status
      field: 'StatusFlags',
      headerName: i18n.t('status'),
      flex: 0,
      width: 100,
      cellStyle: { padding: 0, display: 'flex', justifyContent: 'center' },
      valueGetter: params => {
        const data = fieldPrefix ? params.data[fieldPrefix] : params.data
        return data?.StatusFlags && data.StatusFlags[0] ? data.StatusFlags[0].Messages[0] : ''
      },
      cellRenderer: params => {
        const color = params.value === 'appliance_status_online' ? '#68bd49' : 'red'
        return `<i class="mdi mdi-circle" style="color: ${color};"></i>`
      },
    }
  },
  /**
   * Returns the column def for the license status column anywhere appliance grid is displayed
   * @param {string} fieldPrefix - passed when appliance info is not directly present in params.data and instead in params.data[fieldPrefix]
   * @returns column definition
   */
  getApplianceLicenseColumn(fieldPrefix = '') {
    return {
      headerName: i18n.t('license'),
      field: 'StatusFlags',
      flex: 0,
      width: 103,
      cellStyle: { padding: 0, display: 'flex', justifyContent: 'center' },
      // If there are additional status flags, then the license is not valid
      valueGetter: params => {
        const data = fieldPrefix ? params.data[fieldPrefix] : params.data
        return data?.StatusFlags && data.StatusFlags[1] ? data.StatusFlags[1].Messages : ['valid_license']
      },
      cellRenderer: params => {
        const data = fieldPrefix ? params.data[fieldPrefix] : params.data
        // Only one statusFlag in data means the license is valid
        if (data?.StatusFlags && data.StatusFlags.length === 1) {
          return `<i class="mdi mdi-license" style="color: rgba(76, 175, 80, 1);"></i>`
        } else if (params.value && params.value[0] !== 'valid_license') {
          // The statusFlag has the color the tooltip should be, so we color accordingly
          const colorName = data.StatusFlags[1].State
          let color = 'rgba(221, 44, 0)'
          if (colorName === 'status_warning') {
            color = 'rgba(255, 193, 7)'
          }
          return `<i class="mdi mdi-alert" style="color: ${color};"></i>`
        }
      },
    }
  },

  /**
   * Returns column definitions for Tasks grid
   * @return column definition
   */
  getTasksColumnDefs() {
    return [
      {
        headerName: i18n.t('task_id'),
        field: 'TaskId',
        hide: true,
      },
      {
        headerName: i18n.t('date_updated'),
        field: 'Timestamp',
        width: 150,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      {
        headerName: i18n.t('task'),
        field: 'Task',
        cellRenderer: params => i18n.t(params.value),
      },
      {
        headerName: i18n.t('status'),
        field: 'Status',
        cellRenderer: params => i18n.t(params.value),
        width: 100,
      },
      {
        headerName: i18n.t('error_message'),
        field: 'ErrorMessage',
        valueFormatter: params => i18n.t(params.value),
        width: 500,
      },
      {
        headerName: i18n.t('number_of_attempts'),
        field: 'RetryCount',
        width: 130,
      },
      {
        headerName: i18n.t('expiration_date'),
        field: 'ExpirationDate',
        width: 150,
        valueFormatter: ({ value }) => vuntangle.dates.formatDateFromApi(value),
        comparator: (a, b) => vuntangle.dates.compareDates(a, b),
      },
      {
        headerName: i18n.t('user_email_address'),
        field: 'UserEmail',
      },
    ]
  },

  /**
   * Merges the passed columns with the appliance columns and returns the result
   *
   * @param {Array} originalColumns original list of columns to merge with appliance columns
   * @param {Array} removeCols list of columns to remove from appliance columns
   * @returns Array result of merge
   */
  mergeWithApplianceColumns(originalColumns, removeCols = [], fieldPrefix = 'appliance') {
    // hide appliance license column if NoLicenseEnforcement is enabled
    if (store.getters['data/NoLicenseEnforcement']) {
      removeCols = [...removeCols, 'license']
    }
    const applianceCols = this.getApplianceGridColumns(null, removeCols, fieldPrefix)
    // by default hide all additional appliance cols
    applianceCols.forEach(col => (col.hide = true))
    return originalColumns.concat(applianceCols)
  },
}

export default grids
