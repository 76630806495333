<template>
  <div class="fill-height">
    <v-app style="background: transparent" class="fill-height">
      <div class="v-main__wrap">
        <selection-bar v-if="showAppliancesSelector" config-type="appliances" class="flex-grow-0" />
        <router-view />
        <!-- used for displaying vuntangle framework components -->
        <common-footer />
      </div>
    </v-app>
  </div>
</template>
<script>
  import SelectionBar from '@/layouts/default/SelectionBar.vue'
  import CommonFooter from '@/layouts/common-footer'

  export default {
    components: {
      SelectionBar,
      CommonFooter,
    },
    computed: {
      routeKey() {
        const keyBuilder = this.$route.matched[0]?.meta?.keyBuilder
        return keyBuilder ? keyBuilder(this.$route) : this.$route.fullPath
      },
      isLoggedIn: ({ $store }) => $store.state.auth.isAuth,

      showAppliancesSelector: ({ $route }) => $route.name?.startsWith('appliances'),
    },
  }
</script>
