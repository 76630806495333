<template>
  <div class="d-flex flex-column overflow-auto">
    <v-navigation-drawer permanent floating color="transparent" width="240">
      <v-list class="list-dense" nav dense>
        <v-list-item
          v-for="(item, idx) in alertsItems"
          :key="idx"
          :class="{ 'v-list-item--active': $route.fullPath.startsWith(item.fullPath) }"
          @click="$router.push({ name: item.to })"
        >
          <v-list-item-title>{{ $t(item.tkey) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
  import users from '@/plugins/ut/ut-users'

  export default {
    computed: {
      /**
       * Returns the Alerts side navigation items
       * Applies the same logic as in `layouts/default/UtNewDrawer.vue:126`
       * @returns {Array<Object>}
       */
      alertsItems: ({ $router }) => {
        const items = [
          ...(users.hasFullAccountAccess()
            ? [
                {
                  tkey: 'alert_rules',
                  to: 'alerts-rules',
                },
              ]
            : []),
          { tkey: 'notification_profiles', to: 'alerts-notification-profiles' },
          ...(users.hasFullAccountAccess()
            ? [
                {
                  tkey: 'audit_history',
                  to: 'alerts-audit-history',
                },
              ]
            : []),
          { tkey: 'alert_history', to: 'alerts-alert-history' },
          ...(users.hasFullAccountAccess()
            ? [
                {
                  tkey: 'notification_log',
                  to: 'alerts-notification-log',
                },
              ]
            : []),
        ]
        return items.map(item => {
          item.fullPath = $router.resolve({ name: item.to }).resolved.fullPath
          return item
        })
      },
    },
  }
</script>
