/**
 * This plugin is a collection of globally available methods used throughout the project
 * It is globally accessible using 'this.function_name'
 */
import Vue from 'vue'
import store from '@/store'
import api from '@/plugins/ut/ut-api'
import vuntangle from '@/plugins/vuntangle'
import i18n from '@/plugins/vue-i18n'

const licenseUrlLinks = {
  'Ad Blocker': 'shop/Ad-Blocker/',
  'Application Control': 'shop/Application-Control/',
  'Application Control Lite': 'shop/Application-Control/',
  'Bandwidth Control': 'shop/Bandwidth-Control/',
  'Branding Manager': 'shop/Branding-Manager/',
  'Captive Portal': 'shop/Captive-Portal/',
  'Configuration Backup': 'configuration-backup/',
  'Directory Connector': 'shop/Directory-Connector/',
  'Firewall': 'shop/firewall/',
  'Home Protect Basic': 'solutions/untangle-at-home/',
  'Home Protect Plus': 'solutions/untangle-at-home/',
  'HTTPS Inspector': 'shop/ssl-inspector/',
  'Intrusion Prevention': 'shop/intrusion-prevention/',
  'Intrusion Protection': 'shop/intrusion-prevention/',
  'IPsec VPN': 'shop/IPsec-VPN/',
  'Live Support': 'shop/Live-Support/',
  'Micro Edge Not For Resale': 'nfr/',
  'NG Firewall Complete': 'shop/NG-Firewall-Complete/',
  'NG Firewall Not For Resale': 'nfr/',
  'Nonprofit Complete': 'shop/nonprofit-complete/',
  'Open VPN': 'shop/OpenVPN/',
  'Wireguard VPN': 'shop/Wireguard-VPN/',
  'Phish Blocker': 'shop/phish-blocker/',
  'Policy Manager': 'shop/Policy-Manager/',
  'Public Sector Complete': 'shop/public-sector-complete/',
  'Reports': 'shop/reports/',
  'Spam Blocker': 'shop/Spam-Blocker/',
  'Spam Blocker (CT)': 'shop/Spam-Blocker/',
  'Spam Blocker Lite': 'shop/Spam-Blocker/',
  'SSL Inspector': 'shop/ssl-inspector/',
  'Support': 'shop/Live-Support/',
  'Threat Prevention': 'shop/threat-prevention/',
  'Tunnel VPN': 'shop/Tunnel-VPN/',
  'WAN Balancer': 'shop/WAN-Balancer/',
  'WAN Failover': 'shop/WAN-Failover/',
  'Web Filter': 'shop/web-filter/',
  'Web Cache': 'shop/Web-Cache/',
  'Web Monitor': 'shop/Web-Monitor/',
  'Virus Blocker': 'shop/virus-blocker/',
  'Virus Blocker (CT)': 'shop/virus-blocker/',
  'Virus Blocker Cloud': 'shop/virus-blocker/',
  'Virus Blocker Lite': 'shop/virus-blocker/',
}

const util = {
  isDev: process.env.NODE_ENV === 'development', // replaces ctx.isDev,

  /**
   * Sets the favicon based on host
   */
  setFavIcons() {
    // set favicon based on host
    const host = document.location.host
    const favicoSizes = ['16x16', '32x32', '96x96'] // as found in public /favicon folder
    let favicoPrefix = ''

    // use `local` red icon if local or dev server
    if (host.startsWith('local') || host.startsWith('cmd-ui')) {
      favicoPrefix = 'local.'
    }
    // use `develop` orange icon for develop server
    if (host.startsWith('develop')) {
      favicoPrefix = 'develop.'
    }
    // otherwise will use `prod` green icon

    favicoSizes.forEach(size => {
      const link = document.createElement('link')
      link.rel = 'icon'
      link.type = 'image/png'
      link.sizes = size
      link.href = require('@/static/favicon/' + favicoPrefix + 'favicon-' + size + '.png')
      document.getElementsByTagName('head')[0].appendChild(link)
    })
  },

  /**
   * Returns a translated status value
   * @param status - string value of the status [active, disabled]
   */
  formatLocaleStatus(status) {
    switch (status) {
      case 'active':
        return i18n.t('active')
      case 'disabled':
        return i18n.t('disabled')
      default:
        return ''
    }
  },

  /**
   * Returns true/false as a localized YES/NO string
   * @param value - true/false
   */
  formatBoolean(value) {
    if (value === true) {
      return i18n.t('yes')
    }
    if (value === false) {
      return i18n.t('no')
    }
    return ''
  },

  /**
   * Returns bytes as a formatted string (e.g. 1024 bytes becomes 1 kB)
   * @param bytes - bytes to format
   * @param decimals - number of decimal places in the formatted byte string
   * @param isKilobytes - if true, it indicates that the 'bytes' parameter is coming in as a kilobyte value rather than bytes
   * @returns string - bytes formatted as a string
   */
  formatBytes(bytes, decimals, isKilobytes) {
    if (isNaN(bytes) || bytes === 0) {
      return '0 Bytes'
    }

    if (isKilobytes === true) {
      // convert the kilobyte value to bytes
      bytes = bytes * 1000
    }
    const k = 1000 // or 1024 for binary
    const dm = decimals || 3
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
  },

  /**
   * Helper function to check if an object is undefined, null, or an empty string.
   *
   * @param obj - instance to check
   *
   * @returns {boolean} - true if obj is undefined, null, or an empty string. false otherwise.
   */
  isUndefinedOrNullOrEmpty(obj) {
    return undefined === obj || obj === null || obj === ''
  },

  /**
   * Helper function to check if an object is undefined or null
   *
   * @param obj - instance to check
   *
   * @returns {boolean} - true if obj is undefined or null. false otherwise.
   */
  isUndefinedOrNull(obj) {
    return undefined === obj || obj === null
  },

  /**
   * Converts the supplied GeoLocation object to a string that can be displayed
   * @param geoLocation - geolocation object
   * @returns string - formatted string based on available lcoation info
   */
  formatGeoLocation(geoLocation) {
    if (geoLocation && geoLocation.Country !== null) {
      if (geoLocation.City !== null) {
        return geoLocation.City + ', ' + geoLocation.CountryCode
      }
      return geoLocation.Country
    }
    return i18n.t('unknown')
  },

  /**
   * Compares two supplied version strings. Returns -1 if the first version is lower than the second, 0 if they are equal, and 1 if the second is lower.
   * @param version1 - first version to compare
   * @param version2 - second version to compare
   * @returns {number} - -1 if the first version is lower than the second, 0 if they are equal, and 1 if the second is lower.
   */
  compareVersions(version1, version2) {
    // prevent having nulls versions to compare
    const v1 = version1 || ''
    const v2 = version2 || ''
    if (v1 === v2) return 0
    const version1Components = v1.split('.')
    const version2Components = v2.split('.')
    for (let i = 0; i < Math.max(version1Components.length, version2Components.length); i++) {
      const component1 = i < version1Components.length ? parseInt(version1Components[i]) : 0 // normalize the component to 0 if we are past version's length
      const component2 = i < version2Components.length ? parseInt(version2Components[i]) : 0
      if (component1 > component2) {
        return 1
      } else if (component1 < component2) {
        return -1
      }
      // otherwise they are the same, continue to next iteration
    }
    return 0 // if we got here, they are the same
  },

  /**
   * Obfuscates the UID for display purposes. The first four characters and the last four characters will not be
   * obfuscated.
   *
   * @param uid - UID to obfuscate
   *
   * @returns {string} - obfuscated UID
   */
  obfuscateUid(uid) {
    const ccViewModel = store.state.data.ccViewModel
    if (!ccViewModel?.Account.AccountSettings.MaskUIDs) return uid
    // return uid
    if (!this.isUndefinedOrNullOrEmpty(uid)) {
      const uidLength = uid.length
      const numberCharsToObfuscate = uidLength / 2
      const numberFirstAndLastCharsToObfuscate = Math.round(numberCharsToObfuscate / 2)
      const firstVisibleChars = uid.substring(0, numberFirstAndLastCharsToObfuscate)
      let obfuscatedUid = 'x'
      if (uidLength >= 3) {
        obfuscatedUid =
          firstVisibleChars +
          'x'.repeat(numberCharsToObfuscate) +
          uid.substring(uidLength - numberFirstAndLastCharsToObfuscate)
      } else if (uidLength === 2) {
        obfuscatedUid = firstVisibleChars + 'x'
      }
      return obfuscatedUid
    }
    return 'xxxx-xxxx-xxxx-xxxx'
  },

  /**
   * Finds and replaces all UIDs in the given string with their obfuscated value.
   *
   * @param str - string to modify
   *
   * @returns string - string with obfuscated UIDs
   */
  obfuscateUidsInString(str) {
    // find all UIDs in the string using regex
    const regex =
      /\b([A-Za-z0-9]{4})-([A-Za-z0-9]{4})-([A-Za-z0-9]{4})-([A-Za-z0-9]{4})\b|\b([A-Za-z0-9]{8})-([A-Za-z0-9]{4})-([A-Za-z0-9]{4})-([A-Za-z0-9]{4})-([A-Za-z0-9]{12})\b/g
    const uids = str.match(regex)
    if (uids !== null) {
      // replace all the UIDs with the obfuscated value
      for (let i = 0; i < uids.length; i++) {
        const obfuscatedUid = this.obfuscateUid(uids[i])
        str = str.replace(uids[i], obfuscatedUid)
      }
    }
    return str
  },

  /**
   * adds appliance tag and hostname into any incidence of a valid UID in a string.
   * also obfuscates UID if user has chosen that.
   * @param str
   * @returns {*}
   */
  addApplianceTagsInString(str) {
    // find all UIDs in the string using regex
    const regex =
      /\b([A-Za-z0-9]{4})-([A-Za-z0-9]{4})-([A-Za-z0-9]{4})-([A-Za-z0-9]{4})\b|\b([A-Za-z0-9]{8})-([A-Za-z0-9]{4})-([A-Za-z0-9]{4})-([A-Za-z0-9]{4})-([A-Za-z0-9]{12})\b/g
    const uids = str.match(regex)
    if (uids !== null) {
      // replace all the UIDs with the obfuscated value
      const alreadyDone = []
      for (let i = 0; i < uids.length; i++) {
        if (alreadyDone.includes(uids[i])) continue
        const tag = this.uidToApplianceTag(uids[i])
        const hostname = this.uidToHostname(uids[i])
        const newVal = hostname + ' (' + this.obfuscateUid(uids[i]) + ' / ' + tag + ')'
        str = str.replace(new RegExp(uids[i], 'g'), newVal)
        alreadyDone.push(uids[i])
      }
    }
    return str
  },

  /**
   * Returns the formatted string of the given money value.
   *
   * @param {number} moneyNumber - money value to format (e.g. 19.89, 12345.67, -135.79)
   *
   * @param {string} currency - the currency to use to display the formatted string
   *
   * @return {string} formatted string of the money value (e.g. "$19.89", "$12,345.67", "($135.79)")
   */
  moneyNumberToString(moneyNumber, currency) {
    let negative = false
    let value = Math.round(moneyNumber * 100) / 100
    if (isNaN(value)) {
      return (0).toLocaleString('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    }
    // if the value is negative, save a flag to format it at the end
    if (value < 0) {
      negative = true
      value = value * -1
    }
    // return the value as local monetary string ( with comma and point )
    value = parseFloat(value).toLocaleString('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return negative === true ? '(' + value + ')' : value
  },

  /**
   * format a monetary string without having local info needed in method above
   * @param moneyNumber
   * @param currencySymbol
   * @returns {string}
   */
  moneyNumberForReport(moneyNumber, currencySymbol) {
    let negative = false
    let value = Math.round(moneyNumber * 100) / 100
    if (isNaN(value)) {
      return (
        currencySymbol +
        (0).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      )
    }
    // if the value is negative, save a flag to format it at the end
    if (value < 0) {
      negative = true
      value = value * -1
    }
    // return the value as local monetary string ( with comma and point )
    value = parseFloat(value).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return negative === true ? '-' + currencySymbol + value : currencySymbol + value
  },

  /**
   * Removes the / from the beginning of IP address returned from CMD.
   * @param ipAddress - IP address returned from CMD
   * @returns string - formatted IP address
   */
  formatIpAddressFromCMD(ipAddress) {
    if (!ipAddress) {
      return 'Unknown'
    }
    if (ipAddress.indexOf('/', 0) === 0) {
      // remove the / in front of ip address, which is added by CMD
      ipAddress = ipAddress.substring(1)
    }
    return ipAddress
  },

  /**
   * Checks if a credit card month and year are in the past or not
   *
   * @param {string} month - expiration month
   * @param {string} year - expiration year
   *
   * @returns {Array} - dictionary array
   */
  isCreditCardExpired(month, year) {
    const currentDate = new Date()
    // add 1 since getMonth is zero based
    const currentMonth = currentDate.getMonth() + 1
    const currentYear = currentDate.getFullYear()
    return (month < currentMonth && year <= currentYear) || year < currentYear
  },

  /**
   * Returns the formatted hostname
   * @param hostname - the hostname provided
   * @return {*|null} - the formatted hostname
   */
  formatHostname(hostname) {
    return !this.isUndefinedOrNullOrEmpty(hostname) ? hostname : i18n.t('unknown')
  },

  /**
   * Returns the formatted tag
   * @param tag - the tag provided
   * @return {*|string} - the formatted tag
   */
  formatTag(tag) {
    return !this.isUndefinedOrNullOrEmpty(tag) ? tag : i18n.t('label_not_set')
  },

  /**
   * Returns a formatted string of the specified appliances.
   *
   * @param {string[]} uids - array of appliance UIDs
   * @param {string} separator - string to separate appliances with (e.g. a comma)
   * @returns {string} - formatted string of the specified appliances
   */
  getFormattedApplianceList(uids, separator) {
    let formattedList = ''
    if (uids) {
      uids.forEach(_uid => {
        const uid = this.obfuscateUid(_uid)
        const applianceTag = this.uidToApplianceTag(_uid)
        formattedList += this.uidToHostname(_uid) + ' (' + uid
        formattedList += ' / ' + applianceTag + ')' + separator
      })
    }
    if (formattedList.length > 0) {
      // remove the trailing separator
      formattedList = formattedList.substring(0, formattedList.length - separator.length)
      return formattedList
    }
    return i18n.t('no_appliances')
  },

  /**
   * Returns the hostname for the given appliance UID.
   * @param uid - appliance UID
   * @returns string|null - appliance hostname or "Unknown Hostname" if not found
   */
  uidToHostname(uid) {
    const appliance = store.state.appliances.list?.find(appl => {
      return appl.Uid === uid
    })
    if (appliance && appliance.Hostname) {
      return this.formatHostname(appliance.Hostname)
    }
    return i18n.t('unknown_hostname')
  },

  /**
   * Retrieve Appliance tag for the specified UID
   *
   * @param uid string - the appliance uid
   * @returns string - the appliance Label or Label Not Set if not found
   */
  uidToApplianceTag(uid) {
    const appliance = store.state.appliances.list?.find(appl => {
      return appl.Uid === uid
    })
    if (appliance && appliance.ApplianceTag) {
      return appliance.ApplianceTag
    }
    return i18n.t('label_not_set')
  },

  numericOnly(event) {
    const keyCode = event.keyCode ? event.keyCode : event.which
    if (keyCode < 48 || keyCode > 57) event.preventDefault()
  },

  /**
   * Returns the formatted memory
   * @param availableMemory - the available memory
   * @param totalMemory - the total memory
   * @return {*|string} - the formatted memory
   */
  formatMemory(availableMemory, totalMemory) {
    return this.formatBytes(availableMemory) + '/' + this.formatBytes(totalMemory)
  },

  /**
   * Returns the formatted disk info
   * @param diskData - the disk information
   * @return {*|string} - the formatted data for the given disk info
   */
  formatDiskInfo(diskData) {
    let value = ''
    const me = this
    diskData.forEach(function (disk) {
      const label = disk.Label !== '' ? disk.Label : i18n.t('no_label')
      value +=
        disk.Name +
        ' [' +
        label +
        '] &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
        i18n.t('free_space') +
        ': ' +
        me.formatBytes(disk.FreeSpace) +
        ' / ' +
        me.formatBytes(disk.Size) +
        '<br/>'
    })
    return value
  },

  /**
   *  bytesSecRenderer determines the byte per second unit display, based on the amount of bytes per second passed into the parameters
   * @param bytes - the number of bytes per second
   * @returns {string} - the string representation of the bytes per second
   */
  bytesSecRenderer(bytes) {
    if (!bytes) {
      return ''
    }

    const units = ['B/s', 'kB/s', 'MB/s', 'GB/s']
    let unitsItr = 0
    while ((bytes >= 1000 || bytes <= -1000) && unitsItr < 3) {
      bytes = bytes / 1000
      unitsItr++
    }
    if (unitsItr !== 0) {
      bytes = (Math.round(bytes * 100) / 100).toFixed(1)
    }
    return bytes + ' ' + units[unitsItr]
  },

  /**
   *  bytesRenderer determines the byte unit display, based on the amount of bytes passed into the parameters
   *
   * @param bytes - the number of bytes to parse
   * @returns {string}  - the string representation of the bytes
   */
  bytesRenderer(bytes) {
    if (!bytes) {
      return ''
    }

    const units = ['B', 'KB', 'MB', 'GB', 'TB']
    let unitsItr = 0
    while ((bytes >= 1000 || bytes <= -1000) && unitsItr < 3) {
      bytes = bytes / 1000
      unitsItr++
    }
    if (unitsItr !== 0) {
      bytes = (Math.round(bytes * 100) / 100).toFixed(1)
    }
    return bytes + ' ' + units[unitsItr]
  },

  /**
   *   milliSecRenderer determines how milliseconds should be displayed
   * @param msValue - the value, in ms
   * @returns {string} - the string representation with units appended
   */
  milliSecRenderer(msValue) {
    if (!msValue) {
      return ''
    }
    return msValue.toFixed(2) + ' ms'
  },

  /**
   *
   *  packetLossPercentageRenderer converts the packet loss rate into a packet loss percentage for display purposes
   *
   * @param packetLossRate - the packetLossRate (dropped packets / total packets)
   * @returns {string} - the rate as a percentage for display
   */
  packetLossPercentageRenderer(packetLossRate) {
    if (!packetLossRate && packetLossRate !== 0) {
      return ''
    }

    return Math.round(packetLossRate * 100).toFixed(2) + ' %'
  },

  /**
   * get policy name from store to display it with the policy id
   *
   * @param policyId
   * @returns {string}
   */
  policyRenderer(policyId) {
    const name = store.getters['policyManager/getObjectById'](policyId)?.Name
    return `${policyId} (${name || i18n.t('not_found')})`
  },

  /**
   * we get the policies as a comma separated string, so we split them and then get the name from store and display it with the corresponding rule id
   *
   * @param rules
   * @returns {*[]}
   */
  ruleRenderer(rules) {
    const ids = rules.split(',')
    let ruleName
    let ruleMessage = []
    ids.forEach(function (id) {
      ruleName = store.getters['policyManager/getObjectById'](id)?.Name
      ruleMessage += `${id} ${ruleName ? '(' + ruleName + ')' : ''}` + '\n'
    })
    return ruleMessage
  },

  /**
   * This method is called when we are displaying arbitrary JSON on the screen.
   * For certain values, we want to format the value to be more human-friendly
   * @param valueName - name of the value
   * @param value - value
   * @returns {null|*|string|*} - null if the value should not be displayed, formatted value otherwise
   */
  formatValueForDisplay(valueName, value) {
    switch (valueName) {
      case 'uid':
      case 'Uid':
        return this.obfuscateUid(value)
      case 'AveragePacketLossReceiving':
      case 'AveragePacketLossSending':
      case 'PacketLoss':
        return this.packetLossPercentageRenderer(value)
      case 'TotalDownload':
      case 'TotalUpload':
        return this.bytesRenderer(value)
      case 'AverageLatency':
      case 'AverageJitter':
        return this.milliSecRenderer(value)
      case 'AverageDownloadRate':
      case 'AverageUploadRate':
        return this.bytesSecRenderer(value)
      case 'time':
      case 'lastAccessTime':
      case 'creationTime':
      case 'lastCompletedTcpSessionTime':
      case 'StartDate':
      case 'EndDate':
      case 'lastSessionTime':
      case 'DateCreated':
      case 'datetime':
        return vuntangle.dates.formatDateFromApi(value)
      case 'javaClass':
        return null
      case 'id':
        return value && value.startsWith('ext') ? null : value
      case 'ruleId':
        return this.ruleRenderer(value)
      case 'policyId':
        return this.policyRenderer(value)
      default:
        return value
    }
  },

  /**
   * Retrieves the product URL corresponding to the given license name
   * @param licenseDisplayName - name of the license
   * @param productLine - the appliance productLine
   * @returns URL of the license product URL
   */
  getLicenseUrl(licenseDisplayName, productLine) {
    const baseLicenseUrl = store.getters['data/ccViewModel'].StoreUrl
    const selectedUrl = productLine === 'MFW' ? 'micro-edge/' : licenseUrlLinks[licenseDisplayName]
    const defaultUrl = 'ng-firewall/applications/'

    return `${baseLicenseUrl}${selectedUrl !== undefined ? selectedUrl : defaultUrl}`
  },

  /**
   * Set address data markers with google geocode.
   *
   * @param {string} address
   *
   * @returns {void}
   */
  async getAddressMarker(address) {
    const response = await api.cloud('Untangle_CommandCenter', 'LookupLatLong', {
      geocodeString: address,
      paramOrder: 'geocodeString',
    })

    // return empty results if data was not returned correctly
    if (!response.data?.results?.[0]?.geometry) {
      return []
    }

    return [
      {
        position: {
          lat: response.data.results[0].geometry.location.lat,
          lng: response.data.results[0].geometry.location.lng,
        },
        tooltip: response.data.results[0].formatted_address,
      },
    ]
  },

  /**
   * Get the current browser size width.
   *
   * @returns {number}
   */
  getBrowserWidth() {
    if (self.innerWidth) {
      return self.innerWidth
    }
    if (document.documentElement && document.documentElement.clientWidth) {
      return document.documentElement.clientWidth
    }
    if (document.body) {
      return document.body.clientWidth
    }
  },

  /**
   * Get a cookie by name.
   *
   * @param name
   *
   * @returns {String|null}
   */
  getCookie(name) {
    return (
      document.cookie
        .split('; ')
        .find(row => row.startsWith(name + '='))
        ?.split('=')[1] ?? null
    )
  },

  /**
   * Builds a route for entries that have a table, a rule Id or a policyId
   *
   * @param propertyName - the property name
   * @param value - the property value
   * @param setting - the setting to use
   * @param uid - the appliance uid
   * @returns {{name: string, params: {settings, id: *, ruleId}}|null|{name: string, params: {ruleId}}|{name: string, params: {policyId}}}
   */
  formatRouteForDisplay(propertyName, value, setting, uid) {
    // if a setting was passed, we need to go to an appliance route
    if (setting) {
      // get the unique identifier from uid
      const applianceId = store.state.appliances.list?.find(appl => {
        return appl.Uid === uid
      })?.UniqueIdentifier

      // if a rule Id was passed and an applianceId was found, go to appliances rules
      return propertyName === 'ruleId' && applianceId
        ? { name: `appliances-rules`, params: { id: applianceId, settings: setting } }
        : null
    }
    // no setting was passed, build a route if we have a ruleId or policyId
    return propertyName === 'ruleId'
      ? { name: 'pm-rules-rule', params: { ruleId: value } }
      : propertyName === 'policyId'
      ? { name: 'pm-policy-conditions', params: { policyId: value } }
      : null
  },

  /**
   * appends "/sapphirecc" prefix to backup URLs for CV
   *
   * @param {string} downloadLink original backup url
   * @returns string
   */
  mapBackupUrl(downloadLink) {
    return Vue.prototype.$isSapphire ? `/sapphirecc${downloadLink}` : downloadLink
  },
}

export default util
