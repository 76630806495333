import { ServiceName } from '@/util/mfwServices'

const settings = {
  // generic settings
  Dhcp: {
    name: ServiceName.Dhcp,
    to: { name: 'appliances-settings', params: { settings: ServiceName.Dhcp } },
  },
  Dns: {
    name: ServiceName.Dns,
    to: { name: 'appliances-settings', params: { settings: ServiceName.Dns } },
    settingsPath: 'dns',
  },
  WebFilter: {
    name: ServiceName.WebFilter,
    to: { name: 'appliances-settings', params: { settings: ServiceName.WebFilter } },
  },
  ThreatPrevention: {
    name: ServiceName.ThreatPrevention,
    to: { name: 'appliances-settings', params: { settings: ServiceName.ThreatPrevention } },
  },
  GeoipFilter: {
    name: ServiceName.GeoipFilter,
    to: { name: 'appliances-settings', params: { settings: ServiceName.GeoipFilter } },
  },
  NetworkDiscovery: {
    name: ServiceName.NetworkDiscovery,
    to: { name: 'appliances-settings', params: { settings: ServiceName.NetworkDiscovery } },
    settingsPath: 'discovery',
  },
  AppControl: {
    name: ServiceName.ApplicationControl,
    to: { name: 'appliances-settings', params: { settings: ServiceName.ApplicationControl } },
  },
  CaptivePortal: {
    name: ServiceName.CaptivePortal,
    to: { name: 'appliances-settings', params: { settings: ServiceName.CaptivePortal } },
  },
  DenialOfService: {
    name: ServiceName.DenialOfService,
    to: { name: 'appliances-settings', params: { settings: ServiceName.DenialOfService } },
  },
  DnsFilter: {
    name: ServiceName.DnsFilter,
    to: { name: 'appliances-settings', params: { settings: ServiceName.DnsFilter } },
  },

  // firewall
  FilterRules: {
    name: ServiceName.Filter,
    to: { name: 'appliances-rules', params: { settings: ServiceName.Filter } },
  },
  AccessRules: {
    name: ServiceName.Access,
    to: { name: 'appliances-rules', params: { settings: ServiceName.Access } },
  },

  // network
  PortForwardRules: {
    name: ServiceName.PortForward,
    to: { name: 'appliances-rules', params: { settings: ServiceName.PortForward } },
  },
  ShapingRules: {
    name: ServiceName.Shaping,
    to: { name: 'appliances-rules', params: { settings: ServiceName.Shaping } },
  },
  NatRules: {
    name: ServiceName.Nat,
    to: { name: 'appliances-rules', params: { settings: ServiceName.Nat } },
  },
  StatusAnalyzers: {
    name: ServiceName.StatusAnalyzers,
    to: { name: 'appliances-status-analyzers' },
    settingsPath: 'stats.pingAnalyzers',
  },

  Interfaces: {
    name: ServiceName.Interfaces,
    to: { name: 'appliances-interfaces' },
  },

  Bypass: {
    name: ServiceName.Bypass,
    to: { name: 'appliances-rules', params: { settings: ServiceName.Bypass } },
    settingsPath: 'bypass',
  },

  // routing
  WanRules: {
    name: ServiceName.WanRule,
    to: { name: 'appliances-rules', params: { settings: ServiceName.WanRule } },
  },

  WanPolicies: {
    name: ServiceName.WanPolicy,
    to: { name: 'appliances-wan-policies' },
  },

  StaticRoutes: {
    name: ServiceName.StaticRoutes,
    to: { name: 'appliances-settings', params: { settings: ServiceName.StaticRoutes } },
    settingsPath: 'routes',
  },

  DynamicBlocklist: {
    name: ServiceName.DynamicBlocklist,
    to: { name: 'appliances-dynamic-lists', params: { settings: ServiceName.DynamicBlocklist } },
    settingsPath: 'dynamic_lists',
  },
  ApplianceLogging: {
    name: ServiceName.ApplianceLogging,
    to: { name: 'appliances-logging', params: { settings: ServiceName.ApplianceLogging } },
  },
  ApplianceSystem: {
    name: ServiceName.ApplianceSystem,
    to: { name: 'appliances-system', params: { settings: ServiceName.ApplianceSystem } },
  },
  Database: {
    name: ServiceName.Database,
    to: { name: 'database', params: { settings: ServiceName.Database } },
    settingsPath: 'databases',
  },
}

/**
 * Returns the navigation items for appliance settings
 * @param {Object} options - admin/ccViewModel Appliance toggle state of the settings
 * @param {Object} features - features flags to determine weather to show specific settings features
 * e.g. { Dhcp: true, Dns: false }
 */
const getSettingsItems = (options, features) => [
  ...(options.Dhcp ||
  options.Dns ||
  options.PortForwardRules ||
  options.ShapingRules ||
  options.NatRules ||
  options.StatusAnalyzers ||
  options.Interfaces
    ? [
        {
          name: 'network',
          icon: 'mdi-lan',
          items: [
            ...(options.Dhcp ? [settings.Dhcp] : []),
            ...(options.Dns ? [settings.Dns] : []),
            ...(options.PortForwardRules ? [settings.PortForwardRules] : []),
            ...(options.ShapingRules ? [settings.ShapingRules] : []),
            ...(options.NatRules ? [settings.NatRules] : []),
            ...(options.StatusAnalyzers ? [settings.StatusAnalyzers] : []),
            ...(options.Interfaces ? [settings.Interfaces] : []),
            ...(options.Bypass && features.hasBypass ? [settings.Bypass] : []),
          ],
        },
      ]
    : []),
  ...(options.WanRules || options.WanPolicies || options.StaticRoutes
    ? [
        {
          name: 'routing',
          icon: 'mdi-call-split',
          items: [
            ...(options.WanRules ? [settings.WanRules] : []),
            ...(options.WanPolicies ? [settings.WanPolicies] : []),
            ...(options.StaticRoutes ? [settings.StaticRoutes] : []),
          ],
        },
      ]
    : []),
  ...(options.FilterRules || options.AccessRules
    ? [
        {
          name: 'firewall',
          icon: 'mdi-shield-half-full',
          items: [
            ...(options.FilterRules ? [settings.FilterRules] : []),
            ...(options.AccessRules ? [settings.AccessRules] : []),
            ...(options.DenialOfService && features.hasDenialOfService ? [settings.DenialOfService] : []),
          ],
        },
      ]
    : []),
  ...(options.WebFilter ||
  options.ThreatPrevention ||
  options.GeoipFilter ||
  options.NetworkDiscovery ||
  options.AppControl ||
  options.CaptivePortal ||
  options.DynamicBlocklist ||
  options.DenialOfService ||
  options.DnsFilter
    ? [
        {
          name: 'services',
          icon: 'mdi-apps',
          items: [
            ...(options.WebFilter ? [settings.WebFilter] : []),
            ...(options.ThreatPrevention ? [settings.ThreatPrevention] : []),
            ...(options.GeoipFilter ? [settings.GeoipFilter] : []),
            ...(options.NetworkDiscovery ? [settings.NetworkDiscovery] : []),
            ...(options.AppControl ? [settings.AppControl] : []),
            ...(options.CaptivePortal ? [settings.CaptivePortal] : []),
            ...(options.DynamicBlocklist ? [settings.DynamicBlocklist] : []),
            ...(options.DnsFilter && features.hasDnsFilterService ? [settings.DnsFilter] : []),
          ],
        },
      ]
    : []),
  ...(options.ApplianceLogging || options.ApplianceSystem || options.Database
    ? [
        {
          name: 'system',
          icon: 'mdi-cog',
          disableWhenOffline: true,
          items: [
            ...(options.ApplianceLogging ? [settings.ApplianceLogging] : []),
            ...(options.ApplianceSystem ? [settings.ApplianceSystem] : []),
            ...(options.Database && features.hasDatabase ? [settings.Database] : []),
          ],
        },
      ]
    : []),
]

export { getSettingsItems }
