<template>
  <div class="d-flex align-center">
    <u-btn
      icon
      outlined
      :small="false"
      :min-width="null"
      :disabled="!hasPrev"
      @click="updateCurrentIndex(alertIndex - 1)"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </u-btn>
    <div class="my-2 mx-4 flex-grow-1">
      <table class="text-body-2">
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td class="px-2 text-right font-weight-bold" style="min-width: 120px">{{ item.text }}:</td>
            <td>{{ item.value }}</td>
          </tr>
          <template v-if="formattedDetails.length">
            <tr>
              <td></td>
              <td class="font-weight-bold pt-4">{{ $t('details') }}</td>
            </tr>
            <tr v-for="formattedDetail in formattedDetails" :key="formattedDetail.name">
              <td class="px-2 text-right">{{ formattedDetail.name }}:</td>
              <td>
                <!-- if we have a route on this detail, build a link with it. Else, just display the value -->
                <a v-if="formattedDetail.route" @click="$emit('route-to', formattedDetail.route)">
                  {{ formattedDetail.value }}
                </a>
                <span v-else-if="formattedDetail.name === 'Error'">{{ formattedDetail.value.replace(/\\/g, '') }}</span>
                <span v-else class="text-left">{{ formattedDetail.value }}</span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <u-btn
      icon
      outlined
      :small="false"
      :min-width="null"
      :disabled="!hasNext"
      @click="updateCurrentIndex(alertIndex + 1)"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </u-btn>
    <v-overlay :value="detailsFetching" class="text-center" absolute color="rgba(255, 255, 255, 0.5)">
      <v-progress-circular indeterminate size="32" color="aristaMediumBlue"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
  import startCase from 'lodash/startCase'
  import util from '@/plugins/ut/ut-util'
  import api from '@/plugins/ut/ut-api'
  import store from '@/store'

  export default {
    props: {
      // list of records passed to detail dialog to navigate through
      filteredAlerts: { type: Array, required: true },
      // index of the record being viewed from filteredAlerts
      alertIndex: { type: Number, required: true },
    },
    data() {
      return {
        formattedDetails: [],
        extraDetails: undefined,
        detailsFetching: false,
        connectedString: 'Appliance Connected',
        disconnectedString: 'Appliance Disconnected',
      }
    },
    computed: {
      hasPrev: ({ alertIndex }) => alertIndex > 0,
      hasNext: ({ filteredAlerts, alertIndex }) => alertIndex + 1 < filteredAlerts.length,

      // the main alert details used for iteretion inside template
      items: ({ extraDetails, $vuntangle, $i18n }) => {
        if (!extraDetails) return []
        const dateCreated = $vuntangle.dates.formatDateFromApi(extraDetails.DateCreated)
        const message = $vuntangle.util.translateMessage(extraDetails.Message)
        const deviceId = extraDetails.DeviceId ? util.obfuscateUid(extraDetails.DeviceId) : $i18n.t('not_assigned')
        const source =
          extraDetails.Message.includes('Appliance Connected') ||
          extraDetails.Message.includes('Appliance Disconnected')
            ? $i18n.t('etm_cloud')
            : extraDetails.SourceIP
        const appliance = util.uidToHostname(extraDetails.DeviceId)

        return [
          { text: $i18n.t('date_created'), value: dateCreated },
          { text: $i18n.t('message'), value: message },
          { text: $i18n.t('device_id'), value: deviceId },
          { text: $i18n.t('source'), value: source },
          { text: $i18n.t('appliance'), value: appliance },
        ]
      },
    },
    watch: {
      alertIndex: {
        immediate: true,
        handler(index) {
          if (index > -1) {
            this.getDetails()
          }
        },
      },
    },
    methods: {
      updateCurrentIndex(value) {
        this.$emit('update:alertIndex', value)
      },

      /**
       * Calls backend to get the alert payload as well
       */
      async getDetails() {
        this.extraDetails = this.filteredAlerts[this.alertIndex]
        this.detailsFetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'GetAccountEventDetails', {
          eventId: this.extraDetails._id,
          paramOrder: 'eventId',
        })

        if (response.success && response.data) {
          const policyId = response.data.Payload?.Data?.policyId
          const ruleId = response.data.Payload?.Data?.ruleId
          const promises = []
          if (policyId) {
            promises.push(store.dispatch('policyManager/fetchObjectById', policyId))
          }
          if (ruleId) {
            promises.push(store.dispatch('policyManager/fetchObjectById', ruleId))
          }
          if (promises.length) {
            await Promise.allSettled(promises)
          }
          this.extraDetails = response.data
          this.populateAdditionalAlertDetails()
        }
        this.detailsFetching = false
      },

      /**
       * populates formattedDetails using extraDetails.Payload.Data
       */
      populateAdditionalAlertDetails() {
        const details = this.extraDetails
        this.formattedDetails = []
        // loop through the payload data and format / build display value and route value ( if any )
        for (const property in details.Payload.Data) {
          const val = details.Payload.Data[property]

          const displayVal = util.formatValueForDisplay(property, val)
          const routeVal = util.formatRouteForDisplay(property, val, details.Payload.Data.table, details.DeviceId)
          const nxtObj = { name: startCase(property), value: displayVal, route: routeVal }

          // skip displaying data if empty
          if (val) this.formattedDetails.push(nxtObj)
        }
      },
    },
  }
</script>
