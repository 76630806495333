<template>
  <u-widget :title="$t('wan_rules')" v-on="$listeners">
    <div class="d-flex flex-grow-1 flex-column">
      <v-card-actions class="d-flex">
        <u-btn
          class="mr-2"
          :disabled="network == null"
          :to="network ? { name: 'networks-rules-id', params: { id: network.Id, ruleId: 'add' } } : ''"
        >
          {{ $t('add_wan_rule') }}
        </u-btn>
        <u-btn class="mr-2" :disabled="network == null" @click="syncWanRules">
          {{ $t('sync_rules_to_mfw_appliances') }}
        </u-btn>
        <u-btn class="mr-2" :disabled="network == null" @click="resetWanRulesDialog = true">
          {{ $t('reset_to_factory') }}
        </u-btn>
        <div v-if="syncMessage">
          {{ syncMessage }}
          <router-link :to="{ name: 'alerts-audit-history' }"> {{ $t('audit_history') }} </router-link>
        </div>
      </v-card-actions>
      <u-grid
        id="network-rules"
        class="d-flex fill-height"
        row-node-id="name"
        toolbar="hidden"
        :no-data-message="$t('no_data')"
        :custom-ordering="true"
        :custom-grid-options="{
          onRowDragEnd: saveWanRuleOrder,
        }"
        :column-defs="wanRulesColumnDefs"
        :fetching="$store.state.networks.fetching"
        :row-data="wanRulesRowData"
        :row-actions="rowActions"
        :enable-refresh="false"
      />
      <u-dialog
        :show-dialog="resetWanRulesDialog"
        :title="$t('reset_to_factory')"
        :message="$t('confirm_reset_wan_rules')"
        :buttons="[
          { name: $t('cancel') },
          {
            name: $t('yes'),
            handler: 'resetWanRules',
            showProgress: true,
          },
        ]"
        @close-dialog="resetWanRulesDialog = false"
        @resetWanRules="resetWanRules"
      />
    </div>
  </u-widget>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import vuntangle from '@/plugins/vuntangle'
  import { wanCriteriaTypes, wanPolicies } from '@/util/wanRules'

  export default {
    props: {
      network: {
        type: Object,
        default: null,
      },
      appliancesRowData: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        bottomNav: this.$store.state.networkConfigSelectedTab,
        networkNotFound: false,
        syncMessage: '',
        resetWanRulesDialog: false,
        rowActions: [
          {
            icon: 'mdi-pencil',
            handler: ({ data }) => this.editWanRule(data),
          },
          {
            icon: 'mdi-delete',
            handler: ({ data }) => this.deleteWanRule(data),
          },
        ],
      }
    },
    computed: {
      wanRulesColumnDefs() {
        return [
          { headerName: this.$t('name'), field: 'name' },
          {
            headerName: this.$t('summary'),
            field: 'summary',
            flex: 3,
          },
          {
            headerName: this.$t('wan_policy'),
            field: 'wanPolicy',
          },
        ]
      },
      wanRulesRowData() {
        return (
          this.network?.WanRulesJson?.map(wanRule => ({
            name: wanRule.description,
            summary:
              wanRule.conditions.length > 0
                ? wanRule.conditions
                    .map(condition => {
                      const wanCriteriaType = wanCriteriaTypes[condition.type]
                      const values = wanCriteriaType.values
                      return `${this.$t(wanCriteriaType.textKey)} ${this.$t(
                        this.$vuntangle.util.operators[condition.op],
                      )} ${
                        !values || Array.isArray(values) || !values[condition.value]
                          ? condition.value
                          : this.$t(values[condition.value])
                      }`
                    })
                    .join(', ')
                : this.$t('all_traffic_local'),
            wanPolicy: this.$t(wanPolicies[wanRule.action.policy]),
          })) || []
        )
      },
    },
    created() {
      this.$store.dispatch('networks/fetchNetworks')
      this.$store.dispatch('appliances/fetchAppliances')
    },
    methods: {
      /**
       * Edit wan rules from a network.
       *
       * @return {void}
       */
      editWanRule(selectedWanRule) {
        const ruleParam = encodeURIComponent(selectedWanRule.name)
        this.$router.push({ name: 'networks-rules-id', params: { id: this.network.Id, ruleId: ruleParam } })
      },
      /**
       * Sync wan rules to appliances.
       *
       * @return {void}
       */
      async syncWanRules() {
        // dispatch a 'sync wan rules'
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await this.$store.dispatch('networks/syncWanRules', {
          network: this.network,
        })
        this.$store.commit('SET_PAGE_LOADER', false)

        if (response.data) {
          vuntangle.toast.add(this.$t('rules_sync_initiating'))
          const dt = vuntangle.dates.formatLocaleDate(new Date())
          this.syncMessage = this.$t('last_sync_status', { syncTime: dt })
        } else {
          vuntangle.toast.add(this.$t('rules_sync_failed'), 'error')
        }
      },
      /**
       * Reset all command-center created rules on all networks appliances in this network
       *
       * @return {void}
       */
      async resetWanRules() {
        this.$store.commit('SET_PAGE_LOADER', true)
        // dispatch a 'update network rules' update
        await this.$store.dispatch('networks/resetWanRules', {
          network: this.network,
        })
        this.$store.commit('SET_PAGE_LOADER', false)
        this.resetWanRulesDialog = false
        const dt = vuntangle.dates.formatLocaleDate(new Date())
        this.syncMessage = this.$t('reset_status', { syncTime: dt })
        vuntangle.toast.add(this.$t('rules_reset_initiating'))
      },
      /**
       * Save the order of the wan rules for a network.
       *
       * @param  {Object} gridEvent
       *
       * @return {void}
       */
      saveWanRuleOrder(gridEvent) {
        // loop through the nodes to update the ordering
        const wanRules = []
        gridEvent.api.forEachNode(node => {
          // find the wan rule based on the 'name'
          const copyWanRule = this.network.WanRulesJson.find(wanRule => node.data.name === wanRule.description)

          // if found, copy the object to a new array
          if (copyWanRule) {
            wanRules.push(cloneDeep(copyWanRule))
          }
        })

        // dispatch a 'update network rules' update
        this.$store.dispatch('networks/updateNetworkRulesJsonOptimistic', {
          network: this.network,
          wanRules,
        })
      },
      /**
       * Delete wan rule from a network.
       *
       * * @param wanRule rule to be deleted
       *
       * @return {void}
       */
      deleteWanRule(wanRule) {
        this.$vuntangle.confirm.show({
          title: this.$t('delete_wan_rules'),
          message: this.$t('confirm_delete_wan_rules'),
          confirmLabel: this.$t('yes'),
          cancelLabel: this.$t('cancel'),
          action: async resolve => {
            // get existing rules
            const existingWanRules = cloneDeep(this.network.WanRulesJson)

            // remove deleted rules (all rules are sent)
            const wanRules = existingWanRules.filter(({ description }) => description !== wanRule.name)

            // dispatch a 'update network rules' with the changed rules
            await this.$store.dispatch('networks/updateNetworkRulesJson', {
              network: this.network,
              wanRules,
            })

            resolve()
          },
        })
      },
    },
  }
</script>
