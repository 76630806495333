var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-page',{attrs:{"title":_vm.$t('alert_history'),"full-height":""}},[_c('u-grid',{attrs:{"id":"alert-history","no-data-message":_vm.$t('no_data'),"column-defs":_vm.columnDefs,"fetching":_vm.$store.state.appliances.fetching || _vm.$store.state.events.accountEventsFetching,"row-data":_vm.rowData,"row-actions":_vm.rowActions},on:{"row-clicked":_vm.viewAlertDetails,"refresh":function($event){return _vm.loadAlertsReceived(true)}}}),_c('u-dialog',{attrs:{"show-dialog":!!_vm.filteredAlerts.length,"title":_vm.$t('additional_information'),"width":700,"buttons":[
      {
        'name': _vm.$t('add_alert_rule'),
        'handler': 'add-alert-rule',
      },
      {
        'name': _vm.$t('close'),
        'handler': 'close-dialog',
      },
    ]},on:{"add-alert-rule":function($event){return _vm.addAlertRule({ data: _vm.filteredAlerts[_vm.alertIndex] })},"close-dialog":_vm.onCloseDialog}},[_c('alert-details',{attrs:{"filtered-alerts":_vm.filteredAlerts,"alert-index":_vm.alertIndex},on:{"update:alertIndex":function($event){_vm.alertIndex=$event},"update:alert-index":function($event){_vm.alertIndex=$event},"route-to":_vm.onRouteTo}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }