import { deleteMFWObjects } from '../util'

export default {
  computed: {
    policyId: ({ $route }) => $route.params.policyId,
    editPolicy: ({ $store, policyId }) => $store.getters['policyManager/getEditObjectById'](policyId),

    ruleId: ({ $route }) => $route.params.ruleId,
    ruleType: ({ $route }) => $route.params.ruleType,
    editRule: ({ $store, ruleId }) => $store.getters['policyManager/getEditObjectById'](ruleId),

    conditionId: ({ $route }) => $route.params.conditionId,
    editCondition: ({ $store, conditionId }) => $store.getters['policyManager/getEditObjectById'](conditionId),

    objectId: ({ $route }) => $route.params.objectId,
    editObject: ({ $store, objectId }) => $store.getters['policyManager/getEditObjectById'](objectId),

    configurationId: ({ $route }) => $route.params.configurationId,
    editConfiguration: ({ $store, configurationId }) =>
      $store.getters['policyManager/getEditObjectById'](configurationId),

    /**
     * For editing rules, conditions, object configurations specific routes are defined
     * with name endings like: `-rule`, `-condition`, `-object`, `-configuration`
     *
     * Depending on which route the editing is occuring the prefix route name gets computed.
     * So it can be: `pm-policy-rule` or `pm-objects-condition` or `pm-rules-object` etc...
     *
     * See router/mfw-policies.js for routes definitions
     */
    routeNamePrefix: ({ policyId, ruleId, conditionId, objectId, configurationId }) => {
      // if `policyId` means editing a policy, the route starts with `pm-policy`
      if (policyId && ruleId) return 'pm-policy-rules-rule'
      // if `policyId` means editing a policy, the route starts with `pm-policy`
      if (policyId && conditionId) return 'pm-policy-conditions'
      // if `ruleId` means editing a rule, the route starts with `pm-rules` (main Rules view)
      if (ruleId) return 'pm-rules-rule'
      // if `objectId` or `conditionId` (both considered objects) means route starts with `pm-objects` (main Objects view)
      if (objectId || conditionId) return 'pm-objects'
      // if `configurationId`, route starts with `pm-templates` (main Templates view)
      if (configurationId) return 'pm-templates'
    },
  },

  methods: {
    /** returns to previous route as set on current route meta */
    goBack() {
      if (this.$route.meta && typeof this.$route.meta === 'function') {
        const backRoute = this.$route.meta?.(this.$route).backRoute
        if (backRoute) this.$router.push(backRoute)
      }
    },

    /**
     * shows confirmation dialog and on cofirm dispatches the action to delete objects from db
     *
     * @param {Object} object object to be deleted
     */
    onDeleteObject(object) {
      deleteMFWObjects([object], false, response => {
        if (response.data?.deleted) {
          this.goBack()
        }
      })
    },
  },
}
