import Alerts from '@/pages/alerts/index.vue'
import NotificationProfiles from '@/pages/alerts/notification-profiles/index.vue'
import NotificationProfileDetails from '@/pages/alerts/notification-profiles/_id.vue'
import AlertRules from '@/pages/alerts/rules/index.vue'
import AlertRuleDetails from '@/pages/alerts/rules/_id.vue'
import ActivityDetails from '@/pages/alerts/activity-details.vue'
import AlertHistory from '@/pages/alerts/alert-history.vue'
import AuditHistory from '@/pages/alerts/audit-history.vue'
import NotificationDetails from '@/pages/alerts/notification-details.vue'
import NotificationLog from '@/pages/alerts/notification-log.vue'
import Parent from '@/components/Parent'
import { breadCrumbBuilder } from '@/util/navigation'

export default [
  {
    path: '/alerts',
    name: 'alerts',
    component: Alerts,
    redirect: { name: 'alerts-rules' },
    children: [
      {
        path: 'notification-profiles',
        component: Parent,
        meta: breadCrumbBuilder([
          ['alerts', { name: 'alerts' }],
          ['notification_profiles', { name: 'alerts-notification-profiles' }],
        ]),
        children: [
          {
            path: '',
            name: 'alerts-notification-profiles',
            component: NotificationProfiles,
          },
          {
            path: ':id',
            name: 'alerts-notification-profiles-id',
            component: NotificationProfileDetails,
            meta: {
              breadCrumb(route) {
                return breadCrumbBuilder([
                  [
                    route.params.id === 'new' ? 'create_notification_profile' : 'edit_notification_profile',
                    { name: 'alerts-notification-profiles-id' },
                  ],
                ]).breadCrumb
              },
            },
          },
        ],
      },
      {
        path: 'rules',
        component: Parent,
        meta: breadCrumbBuilder([
          ['alerts', { name: 'alerts' }],
          ['alert_rules', { name: 'alerts-rules' }],
        ]),
        children: [
          {
            path: '',
            name: 'alerts-rules',
            component: AlertRules,
          },
          {
            path: ':id',
            name: 'alerts-rules-id',
            component: AlertRuleDetails,
            meta: {
              breadCrumb(route) {
                return breadCrumbBuilder([
                  [route.params.id === 'new' ? 'create_alert_rule' : 'edit_alert_rule', { name: 'alerts-rules-id' }],
                ]).breadCrumb
              },
            },
          },
        ],
      },
      {
        path: 'activity-details',
        name: 'alerts-activity-details',
        component: ActivityDetails,
      },
      {
        path: 'alert-history',
        name: 'alerts-alert-history',
        component: AlertHistory,
        meta: breadCrumbBuilder([
          ['alerts', { name: 'alerts' }],
          ['alert_history', { name: 'alerts-alert-history' }],
        ]),
      },
      {
        path: 'audit-history',
        name: 'alerts-audit-history',
        component: AuditHistory,
        meta: breadCrumbBuilder([
          ['alerts', { name: 'alerts' }],
          ['audit_history', { name: 'alerts-audit-history' }],
        ]),
      },
      {
        path: 'notification-details',
        name: 'alerts-notification-details',
        component: NotificationDetails,
      },
      {
        path: 'notification-log',
        name: 'alerts-notification-log',
        component: NotificationLog,
        meta: breadCrumbBuilder([
          ['alerts', { name: 'alerts' }],
          ['notification_log', { name: 'alerts-notification-log' }],
        ]),
      },
    ],
  },
]
