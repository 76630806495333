<template>
  <not-compatible v-if="notCompatible" :feature-name="serviceConfig.tkey" />
  <settings-dynamic-block-lists
    v-else-if="boxSettings"
    :settings="dynamicLists"
    :status="status"
    :disabled="readOnly"
    :cloud-config-id="cloudConfigId"
    @update-settings="onSave"
    @download="onDownload"
    @delete-configuration="onDeleteConfiguration"
    @download-csv="initiateDownload"
    @refresh="fetchStatus"
  >
    <template #actions="{ newSettings, disabled, isDirty }">
      <u-btn :disabled="disabled" class="mr-2" @click="onResetDefaults">
        {{ $vuntangle.$t('reset_to_defaults') }}
      </u-btn>
      <u-btn :disabled="disabled || !isDirty" @click="onSave(newSettings)">
        {{ $vuntangle.$t('save') }}
      </u-btn>
    </template>
  </settings-dynamic-block-lists>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { SettingsDynamicBlockLists } from 'vuntangle'
  import { NotCompatible } from '../layout'
  import settingsMixin from './settingsMixin'
  import vuntangle from '@/plugins/vuntangle'
  import appliances from '@/plugins/ut/ut-appliances'
  import { mfwServices, ServiceName } from '@/util/mfwServices'

  export default {
    components: { NotCompatible, SettingsDynamicBlockLists },
    mixins: [settingsMixin],
    data() {
      return {
        status: [],
      }
    },

    computed: {
      serviceConfig: () => mfwServices[ServiceName.DynamicBlocklist],
      settingsPath: ({ serviceConfig }) => serviceConfig?.settingPathForBox,
      dynamicLists: ({ boxSettings }) => boxSettings?.dynamic_lists,
      // boolean telling if this feature is avaibale for the appliance based on version
      notCompatible: ({ appliance, serviceConfig }) => parseFloat(appliance?.SoftwareVersion) < serviceConfig?.version,
      cloudConfigId: ({ boxSettings }) => boxSettings.cloud_managed?.dynamic_lists,
    },

    mounted() {
      // do not fetch status unless the feature/service is compatible
      if (!this.notCompatible) this.fetchStatus()
    },

    methods: {
      /** Resets the dynamic blocklists to its default configuration */
      onResetDefaults() {
        this.$vuntangle.confirm.show({
          title: this.$vuntangle.$t('confirm'),
          message: this.$vuntangle.$t('dynamic_blocklist_reset_warning'),
          action: async resolve => {
            const defaultsResponse = await appliances.sendToApplianceApi(this.uid, 'defaults/dynamic_lists')

            if (defaultsResponse.success && defaultsResponse.data) {
              this.saveSettings(this.settingsPath, defaultsResponse.data)
            }
            resolve()
          },
        })
      },

      /** fetches the status/reports for the lists via `GetFromApplianceApi` */
      async fetchStatus() {
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await appliances.sendToApplianceApi(this.uid, 'reports/dynamiclists', {}, 'GET')
        this.$store.commit('SET_PAGE_LOADER', false)
        this.status = response?.data?.result || []
      },

      /** removes a configuration by passing a new list with that configuration sliced out
       * @param {String} configurationId - the uuid of the configuration to be removed
       */
      onDeleteConfiguration(configurationId) {
        const settings = cloneDeep(this.dynamicLists)
        const listIndex = settings.configurations.findIndex(conf => conf.id === configurationId)

        if (listIndex >= 0) {
          settings.configurations.splice(listIndex, 1)
        }

        this.onSave(settings)
      },

      /**
       * saves the new settings object against the appliance
       * this method is called when you change the enabled state of a ping analyzer
       * @param {Object} newSettings settings Object to be saved
       */
      onSave(newSettings) {
        this.saveSettings(this.settingsPath, newSettings)
      },

      /**
       * Handler for `download` event
       * @param {String[]} configurationIds - UUIDs of the configurations to be refreshed
       */
      async onDownload(configurationIds) {
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await appliances.sendToApplianceApi(this.uid, 'dynamiclists/refresh', configurationIds)
        // refresh status
        this.fetchStatus()
        this.$store.commit('SET_PAGE_LOADER', false)

        if (response.success) {
          this.$vuntangle.toast.add(this.$t('download_blocklist_to_appliance_success'))
        } else {
          this.$vuntangle.toast.add(this.$t('download_blocklist_to_appliance_failure'), 'error')
        }
      },

      /**
       * Handler for `download-csv` event
       * that fetches the ip list and than saves it to local CSV file
       * @param {String} configurationId - UUID of the configuration to be downloaded
       */
      async initiateDownload(configurationId) {
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await appliances.sendToApplianceApi(
          this.appliance.Uid,
          `dynamiclists/${configurationId}/download`,
          null,
          'GET',
        )
        this.$store.commit('SET_PAGE_LOADER', false)
        if (response.success && response.data) {
          // call vuntangle util method which processes the response and downloads the CSV file
          vuntangle.util.processDynamicListDownload(`block-list-${configurationId}.csv`, response.data)
        } else {
          this.$vuntangle.toast.add(this.$t('export_failed'), 'error')
        }
      },
    },
  }
</script>
