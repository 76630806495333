import { unsavedChangesMixin } from 'vuntangle'
import { setComponent } from './settingsComponent'
import api from '@/plugins/ut/ut-api'
import appliance from '@/plugins/ut/ut-appliances'
import { mfwServices } from '@/util/mfwServices'

export default {
  mixins: [unsavedChangesMixin],

  beforeRouteEnter(to, from, next) {
    next(vm => {
      // redirect to dashboard if `hasApplianceSettings` feature is false
      // for clients and sessions the routing should not be affected
      if (to.name === 'appliances-clients' || to.name === 'appliances-active-sessions') return

      if (
        !vm.appliance.features.hasApplianceSettings ||
        (to.name === 'database' && !vm.appliance.features.hasDatabase)
      ) {
        vm.$router.push({ name: 'appliances-id', params: { id: to.params.id } })
      }
      // don't allow 'interfaces/add/bridge' for mfw appliances under version 6.0 (determined by the hasBridged feature)
      if (to.name === 'appliances-interfaces-add' && to.params.type === 'bridge' && !vm.appliance.features.hasBridged) {
        vm.$router.push({ name: 'appliances-interfaces' })
      }
    })
  },

  computed: {
    // the appliance that gets edited
    appliance: ({ $store, $route }) => $store.getters['appliances/getByUniqueIdentifier']($route.params.id),
    // Uid of the appliance (the route param contains the UniqueIdentifier)
    uid: ({ appliance }) => appliance.Uid,
    // the actual full appliance settings as they are found in box settings.json
    boxSettings: ({ $store }) => $store.getters['appliances/getApplianceSettings'],
    // flag to determine if appliance settings are read only
    readOnly: ({ appliance }) => !appliance?.IsConnectedToCmd,
  },

  methods: {
    /**
     * check if appliance has a valid security subscription if the service requires one
     * @returns {Boolean} true if securityLicense required
     */
    securityLicenseRequired() {
      // if the account is not license enforced, security license is not required
      if (this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement) return false
      let isBasicEdition = true
      this.appliance.Subscriptions.forEach(subscription => {
        const sub = this.$store.state.subscriptions.list.find(
          sub => sub.subscriptionName === subscription.subscriptionName,
        )
        if (sub.sku.includes('SWSE')) {
          isBasicEdition = false
        }
      })
      console.log(this.settingsPath)
      return isBasicEdition && mfwServices[this.settingsPath].securityLicenseRequired
    },

    /** Dispatches an action to fetch settings for an online appliance */
    async fetchSettings() {
      this.$store.commit('SET_PAGE_LOADER', true)
      await this.$store.dispatch('appliances/fetchApplianceSettings', this.appliance)

      // preFetch data if component requires it (e.g. CaptivePortal logo images)
      const component = setComponent(this.$route.params.settings, this.uid)
      if (component?.preFetch) {
        this.preFetchCompleted = false
        await component.preFetch()
        this.preFetchCompleted = true
      }
      this.$store.commit('SET_PAGE_LOADER', false)
    },

    /**
     * Saves settings to the appliance at the specified path
     * @param {String} path - settings path as in `settings.json` on the MFW box
     * @param {Object|Array} settings - settings to be saved
     * @param {Boolean} needsGuid - used when creating new wan policies
     * @param {Boolean} refetch - whether to refetch settings or not
     * @param {String} modifiedChain (optional) - the rules chain, which was changed
     */
    async saveSettings(path, settings, needsGuid = false, refetch = true, modifiedChain = undefined) {
      this.$store.commit('SET_PAGE_LOADER', true)

      // remove temporary captive portal logo image data
      if (path === 'captiveportal') {
        delete settings.logo
      }

      const response = await this.saveSettingsAPI(path, settings, needsGuid, modifiedChain)

      if (response.success && response.data) {
        if (response.data.includes('FAILED')) {
          window.location.reload()
          return response
        }
        if (refetch) {
          // upon save success will refetch trhe appliance settings so UI is in sync
          await this.fetchSettings()
        }
        // don't show success message for Databases
        if (path !== 'databases') {
          this.$vuntangle.toast.add(this.$t('settings_updated'))
        }
      } else {
        this.$vuntangle.toast.add(
          this.$t(this.$te(response.message) ? response.message : 'sync_now_could_not_be_performed'),
          'error',
        )
      }
      this.$store.commit('SET_PAGE_LOADER', false)
      return response
    },

    /**
     * makes the API call to save settings to the appliance at the specified path
     *
     * @param {String} path - settings path as in `settings.json` on the MFW box
     * @param {Object|Array} settings - settings to be saved
     * @param {Boolean} needsGuid - used when creating new wan policies
     * @param {String} modifiedChain - (optional) the rules chain, which was changed, this is
     * used with needsGuid so server can determine in which chain we added the new rule
     */
    async saveSettingsAPI(path, settings, needsGuid = false, modifiedChain = undefined) {
      return await api.payload(
        {
          handler: 'Untangle_CommandCenter',
          paramOrder: 'payload',
          method: 'SaveRuleSettingsToAppliance',
        },
        {
          uid: this.uid,
          setting: 'settings/' + path,
          settingObj: settings,
          needsGuid,
          modifiedChain,
        },
      )
    },

    /**
     * Wrapper over getFromAppliaceApi to avoid syntax repetition
     *
     * @param {string} path path to fetch from appliance
     * @returns response from appliance api
     */
    async getFromAppliance(path) {
      return await api.cloud('Untangle_CommandCenter', 'GetFromApplianceApi', {
        uid: this.uid,
        path,
        paramOrder: 'uid path',
      })
    },

    /**
     * reverts rules and wan policies back to the defaults
     * @param {string} settingsPath - the rules path in settings
     */
    onResetToDefaults(settingsPath) {
      const message = this.ruleType === 'wan-rules' ? 'reset_wan_warning' : 'reset_rules_warning'
      const successMessage = this.ruleType === 'wan-rules' ? 'reset_wan_success' : 'reset_rules_success'
      this.$vuntangle.confirm.show({
        title: this.$t('warning'),
        message: this.$t(message),
        confirmLabel: this.$t('yes'),
        cancelLabel: this.$t('no'),
        action: async resolve => {
          let response
          if (settingsPath !== 'wan-rules') {
            // default settings can be found under 'defaults/' + this.settingsPath path for each rule
            response = await appliance.sendToApplianceApi(this.appliance.Uid, 'defaults/' + settingsPath)
          } else {
            // for wan policies or wan rules, both policies and rules should be reset to defaults
            response = await this.resetWanToDefaults()
          }

          if (response.success) {
            await this.fetchSettings()
            this.$vuntangle.toast.add(this.$t(successMessage))
          } else {
            this.$vuntangle.toast.add(this.$t('an_error_occurred'), 'error')
          }
          resolve()
        },
      })
    },

    /**
     * Reset WAN Policies and WAN Rules to defaults
     *
     * @returns {Boolean}
     */
    async resetWanToDefaults() {
      const resetPoliciesResponse = await appliance.sendToApplianceApi(this.appliance.Uid, 'defaults/wan/policies')
      const resetRulesResponse = await appliance.sendToApplianceApi(this.appliance.Uid, 'defaults/wan/policy_chains')

      await this.$store.dispatch('data/fetchWanPolicies')

      return {
        error: (resetPoliciesResponse.error || resetRulesResponse.error) ?? undefined,
        success: (resetPoliciesResponse.success && resetRulesResponse.success) ?? false,
      }
    },
  },
}
