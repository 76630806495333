<template>
  <div v-if="configuration" class="d-flex flex-column flex-grow-1">
    <rules-list
      :title="$t('bypass')"
      :description="$t('bypass_description')"
      :rules="rules"
      class="flex-grow-1"
      @refresh="onRefresh"
    >
      <template #extra-fields>
        <ValidationObserver ref="extraFields">
          <!-- name and description fields -->
          <name-description
            :name.sync="configuration.Name"
            :description.sync="configuration.Description"
            :name-label="$t('template_name')"
            :description-label="$t('template_description')"
            class="mb-4"
          />
        </ValidationObserver>
      </template>
      <template #actions="{ updatedRules, isDirty }">
        <v-btn text class="text-capitalize mr-2" @click="goBack">
          {{ $t('cancel') }}
        </v-btn>
        <u-btn
          v-if="allowDelete"
          :small="false"
          color="error"
          data-testid="delete-pm-entity"
          text
          class="text-capitalize mr-2"
          @click="onDeleteObject(configuration)"
        >
          <v-icon left>mdi-delete</v-icon> {{ $t('delete_template') }}
        </u-btn>
        <v-btn
          :disabled="!isDirty && !isDirtyConfig"
          color="primary"
          class="text-capitalize"
          depressed
          @click="onSaveSetting(updatedRules)"
        >
          <v-icon small class="mr-2">mdi-content-save</v-icon>
          {{ $t('save') }}
        </v-btn>
      </template>
    </rules-list>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { RulesList } from 'vuntangle'
  import { Type } from 'vuntangle/pm'
  import NameDescription from '../components/NameDescription.vue'
  import editorMixin from './editorMixin'

  export default {
    components: { RulesList, NameDescription },
    mixins: [editorMixin],

    provide() {
      return {
        $remoteData: () => {},
        $features: {
          hasManagementIntf: true,
          hasAboveLayer3Conditions: true,
          hasRuleLogs: true,
          hasLimitingRules: true,
        },
        $readOnly: false,
      }
    },

    data() {
      return {
        configuration: undefined,
      }
    },

    computed: {
      rules: ({ configuration }) => ({ 'bypass-rules': configuration.PolicyJson.rules }),
      isDirtyConfig: ({ configuration, editConfiguration }) =>
        configuration.Name !== editConfiguration.Name || configuration.Description !== editConfiguration.Description,
      allowDelete: ({ configurationId }) => !configurationId.startsWith('mfw-'),
    },

    watch: {
      editConfiguration: {
        handler(editConfiguration) {
          if (!editConfiguration) return
          // populates the configuration with temporary `editConfiguration` (see _editorsMixin)
          this.configuration = cloneDeep(this.editConfiguration)
        },
        deep: true,
        immediate: true,
      },
    },

    methods: {
      /**
       * Saves the configuration into mongo
       * @param {Object} settingObj - the edited settings to be saved
       */
      async onSaveSetting(updatedRules) {
        const valid = await this.$refs.extraFields.validate()
        if (!valid) return

        // populate PolicyJson with updated rules
        this.configuration.PolicyJson.rules = updatedRules['bypass-rules']

        // removes temporary created Id `mfw-...` before saving it
        if (this.configuration.Id.startsWith('mfw-')) this.configuration.Id = ''

        // dispatch action to save the template configuration
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await this.$store.dispatch('policyManager/saveObject', { object: this.configuration })
        this.$store.commit('SET_PAGE_LOADER', false)

        if (response) {
          this.$vuntangle.toast.add(this.$t('saved_successfully', [this.$t('template')]))
          this.goBack()
        } else {
          this.$vuntangle.toast.add(this.$t('unable_to_save', [this.$t('template')]), 'error')
        }
      },

      /**
       * refreshes bypass templates from rules grid refresh
       */
      async onRefresh() {
        this.$store.commit('SET_PAGE_LOADER', true)
        await this.$store.dispatch('policyManager/fetchObjectsByType', { type: Type.ConfigGlobalBypass, force: true })
        this.$store.commit('SET_PAGE_LOADER', false)
      },
    },
  }
</script>
