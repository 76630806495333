<template>
  <u-widget :title="$t('recent_hosts')" :info="$t('recent_hosts_info')" v-on="$listeners">
    <!-- {{ recentHosts[1] }} -->
    <u-grid
      id="recent-hosts"
      toolbar="hidden"
      :no-data-message="$t('no_data')"
      :column-defs="recentHostsColumnDefs"
      :row-data="rowData"
      :resize-columns.sync="resizeEvent"
      :enable-refresh="false"
      :fetching="$store.state.hosts.fetching"
      @row-clicked="viewDetails"
    />
    <u-dialog
      :show-dialog="!!filteredRecords.length"
      :title="$t('additional_information')"
      :width="700"
      :buttons="[
        {
          'name': $t('ok'),
          'handler': 'close-dialog',
        },
      ]"
      @close-dialog="onCloseDialog"
    >
      <extra-details :filtered-records="filteredRecords" :current-index.sync="currentIndex" />
    </u-dialog>
  </u-widget>
</template>
<script>
  import grids from '@/plugins/ut/ut-grids'
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import ExtraDetails from '@/components/widgets/ExtraDetails'
  import util from '@/plugins/ut/ut-util'

  export default {
    components: { ExtraDetails },
    mixins: [WidgetMixin],
    props: {
      appliance: {
        type: Object,
        default: null,
      },
      uids: {
        type: Array,
        default() {
          return []
        },
      },
    },
    data() {
      return {
        // list of records passed to detail dialog to navigate through
        filteredRecords: [],
        // index of the record being viewed from filteredRecords
        currentIndex: -1,
      }
    },
    computed: {
      hostGridColumns: ({ appliance }) => ({
        vendorImage: {},
        hostname: {},
        ipAddress: {},
        macAddress: {},
        macAddressVendor: {},
        // do not return appliance columns if looking at a single appliance
        ...(appliance && { appliance: { hide: true } }),
        uid: { hide: true },
        operatingSystem: {},
        quotaUsage: { hide: true },
        licenseEntitled: { hide: true },
        dateCreated: { hide: true },
        dateUpdated: { hide: true },
      }),

      recentHostsColumnDefs: ({ hostGridColumns }) =>
        grids.mergeWithApplianceColumns(grids.getHostsGridColumns(hostGridColumns), [
          'appliance',
          'tag',
          'uid',
          'ip_address',
        ]),
      recentHosts() {
        // filter by single appliance or passed in uids
        return this.appliance
          ? this.$store.getters['hosts/hosts']?.filter(host => host.UID === this.appliance.Uid) || []
          : this.$store.getters['hosts/hosts']?.filter(host => this.uids.includes(host.UID)) || []
      },
      /**
       * Merge hosts data with appliances data
       */
      rowData: ({ $store, recentHosts }) => $store.getters['appliances/populateApplianceInfoByUid'](recentHosts, 'UID'),
    },
    created() {
      this.$store.dispatch('hosts/fetchHosts')
    },
    methods: {
      onCloseDialog() {
        this.filteredRecords = []
        this.currentIndex = -1
      },

      /**
       * builds the props needed for details dialog and displays the dialog
       * @param {*} param clicked row to fetch details for
       */
      viewDetails(params) {
        params.api.forEachNodeAfterFilterAndSort(node => {
          const row = node.data
          this.filteredRecords.push({
            entitled: row.CmdHost?.entitled ? row.CmdHost.entitled.toString() : ' - ',
            quotaExpirationTime: row.CmdHost?.quotaExpirationTime,
            lastAccessTime: row.CmdHost?.lastAccessTime,
            address: util.formatIpAddressFromCMD(row.IP),
            creationTime: row.CmdHost?.creationTime,
            lastCompletedTcpSessionTime: row.CmdHost?.lastCompletedTcpSessionTime,
            active: row.CmdHost?.active ? row.CmdHost?.active : this.$t('unknown'),
            lastSessionTime: row.CmdHost?.lastSessionTime,
            httpUserAgent: row.CmdHost?.httpUserAgent,
            tags: util.uidToApplianceTag(row.UID),
            quotaRemaining: row.CmdHost?.quotaRemaining,
            hostnameDeviceLastKnown: row.CmdHost?.hostnameDeviceLastKnown,
            hostnameSource: row.CmdHost?.hostnameSource,
            hostname: util.formatHostname(row.HostName),
            macAddress: row.MacAddress,
            macVendor: row.CmdHost?.macVendor,
            quotaIssueTime: row.CmdHost?.quotaIssueTime,
            interfaceId: row.CmdHost?.interfaceId,
            quotaSize: row.CmdHost?.quotaSize,
            captivePortalAuthenticated: row.CmdHost?.captivePortalAuthenticated,
            Uid: row.UID ? util.obfuscateUid(row.UID) : this.$t('not_assigned'),
            applianceHostname: util.uidToHostname(row.UID),
          })
        })
        this.currentIndex = params.node.rowIndex
      },
    },
  }
</script>
