<template>
  <u-alert v-if="showAlerts">
    <div class="font-weight-bold">{{ $t('required_services') }}</div>
    <ul>
      <li v-if="showAppControlAlert">{{ $t('alert_condition_application_control') }}</li>
      <li v-if="showGeoIpAlert">{{ $t('alert_condition_geoip_filter') }}</li>
    </ul>
  </u-alert>
</template>
<script>
  import { Type, conditionsConfig } from 'vuntangle/pm'

  export default {
    props: {
      conditionItems: { type: Array, default: undefined },
      conditionGroupsItems: { type: Array, default: undefined },
    },

    data() {
      return {
        ruleTypes: [Type.RuleSecurity, Type.RulePortForward, Type.RuleShaping, Type.RuleNat],
      }
    },

    computed: {
      /**
       * Returns the edited policy
       * @param vm - vue instance
       * @param vm.$route - $route instance
       * @param vm.$store - $store instance
       */
      policy: ({ $route, $store }) => {
        const policyId = $route.params.policyId
        if (!policyId) return
        return $store.getters['policyManager/getEditObjectById'](policyId)
      },

      /**
       * Returns the edited rule, within the policy
       * @param vm - vue instance
       * @param vm.$route - $route instance
       * @param vm.$store - $store instance
       */
      rule: ({ $route, $store }) => {
        const ruleId = $route.params.ruleId
        if (!ruleId) return
        return $store.getters['policyManager/getEditObjectById'](ruleId)
      },

      /**
       * Returns the edited rule type, e.g. `mfw-rule-security`
       * @param vm - vue instance
       * @param vm.rule - edited rule
       */
      ruleType: ({ rule }) => rule?.Type,

      /**
       * Returns boolean to show or not App Control alert
       * @param vm - vue instance
       * @param vm.isRequired - function
       */
      showAppControlAlert: ({ isRequired }) => isRequired(Type.RuleApplicationControl),

      /**
       * Returns boolean to show or not GeoIP Filter alert
       * @param vm - vue instance
       * @param vm.isRequired - function
       */
      showGeoIpAlert: ({ isRequired }) => isRequired(Type.RuleGeoipFilter),

      /**
       * Returns boolean to show or not the alerts if at least one exists
       * @param vm - vue instance
       * @param vm.showAppControlAlert - boolean
       * @param vm.showGeoIpAlert - boolean
       */
      showAlerts: ({ showAppControlAlert, showGeoIpAlert }) => showAppControlAlert || showGeoIpAlert,
    },

    methods: {
      /**
       * Returns boolean if edited rule contains conditions which are dependent on other services
       * enabled through Policy Manager rules
       * e.g. Application Conditions requires an enabled App Control Rule within policy definition
       * e.g. GeoIP Conditions requires an enabled GeoIp Rule within policy definition
       * @param ruleType - string the rule type, e.g. mfw-rule-security
       */
      isRequired(ruleType) {
        // if not editing a rule inside policy context, or not within a rule from Security, Nat, PF or Shaping, just return
        if (!this.policy || !this.rule || !this.ruleTypes.includes(this.rule?.Type)) return

        /**
         * check in Rule conditions items and in Rule group conditions items if any of the conditions
         * require a specific service to be existing and enabled;
         * it takes the rule type and check against it's configuration by a new field called `requiredRule`
         * See: /vuntangle/src/policy-manager/config/conditionsConfig.js
         */
        const inItems = this.conditionItems.some(cond => conditionsConfig[cond.type]?.requiredRule === ruleType)
        const inGroups = this.conditionGroupsItems.some(group =>
          group.some(condition => condition.some(cond => conditionsConfig[cond.type]?.requiredRule === ruleType)),
        )
        // if not such conditions just return
        if (!inItems && !inGroups) return

        // get all rules from the policy of the given type
        const rulesIds = this.policy?.PolicyJson.rules[ruleType] || []

        // check that above found rules ids are not included in disabled policy rules
        const rulesDisabled = rulesIds.every(ruleId => this.policy?.PolicyJson.disabledRules?.includes(ruleId))

        return !rulesIds.length || rulesDisabled
      },
    },
  }
</script>
