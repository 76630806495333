// prettier-ignore
// NOTE: using js module instead of JSON to be able to add comments

/**
 * JP localization
 * Keys to be matched 1:1 with english translations (see en.js)
 *
 *
 * Pluralization
 * Is achieved by defining locale using pipes
 * e.g.
 * english: network: "network | networks"
 * french: network: "réseau | réseaux"
 *
 * In template, is needed to use $tc() instead of $t()
 * $tc('network', 1) => "network"
 * $tc('network', 2) => "networks"
 */
export default {
  '!=': '!=',
  '<': '<',
  '<=': '<=',
  '==': '==',
  '=>': '=>',
  '>': '>',
  accept_link_label: 'リンクラベルを受け入れる',
  account: '私のアカウント',
  account_balance: '勘定残高',
  account_billed_info: 'サブスクリプションが更新された日にアカウントに請求されます',
  account_billing_cycle_info: '各月の請求サイクル日は次のとおりです',
  account_id: 'アカウントID',
  account_management: 'サブスクリプション、請求、請求書、ユーザー管理',
  account_no_address: 'バウチャーを引き換えることに失敗しました。 アドレスが定義されていません。',
  account_number: '口座番号',
  account_owner: 'アカウントの所有者：',
  account_preferences_save_failed: 'アカウント設定を更新できませんでした。',
  account_preferences_save_success: 'アカウント設定が正常に更新されました。 UI の変更を適用するには更新が必要な場合があります。',
  account_safety_only_two_factor_enabled: 'アカウントの安全性を確保するため、このオプションは 2 要素認証が有効になっている場合にのみ有効になります。',
  account_settings_av_save_failed: '認証に失敗しました。 資格情報を確認して、再試行してください。',
  account_settings_confirmation_required: 'リクエストされたアカウント設定の変更には追加の確認が必要です。 アカウント所有者に電子メールが送信されました。',
  account_settings_save_failed: 'アカウント設定の更新に失敗しました。',
  account_settings_save_success: 'アカウント設定が正常に更新されました。',
  account_type: '口座の種類',
  ach: 'ACH',
  ach_electronic_funds: 'ACH / 電子資金移動',
  ach_payment_error: 'お支払いの処理で問題が発生しました。再入力してみるか、銀行に直接お問い合わせください。',
  active: 'アクティブ',
  active_hosts: 'アクティブなホスト:',
  activity: '活動',
  ad_monitoring_agent: 'Active Directory ログイン モニター エージェント',
  ad_monitoring_summary:
     "AD Login Monitor エージェントは Active Directory サーバーにインストールされ、サーバーのログイン イベントを監視し、ユーザーがコンピュータにログインすると NGFW を更新します。<p>エージェントの詳細については、<a target='_blank' を参照してください。 href='https://wiki.untangle.com/index.php/Directory_Connector#Active_Directory_Server_Login_Monitor_Agent'>NG ファイアウォール Wiki。</a>",
  add_account_failed: 'ご指定いただいたメールアドレスに招待状を送信できませんでした。',
  add_account_success: '指定した電子メール アドレスに招待が正常に送信されました',
  add_ach: 'ACHの追加',
  add_ach_failure: 'ACH 支払い方法を追加できませんでした。',
  add_ach_success: 'ACH 支払い方法が正常に追加されました',
  add_alert_rule: 'アラートルールの追加',
  add_appliance: 'アプライアンスの追加',
  add_appliance_error: 'この組織にアプライアンスを追加する権限がありません。権限を変更するには、組織のマネージャーに問い合わせてください。',
  add_appliance_to_account: 'アプライアンスをアカウントに追加',
  add_appliance_totp: 'アプライアンスの TOTP を追加',
  add_appliances_failure: 'アプライアンスをネットワークに追加できませんでした。',
  add_appliances_success: 'アプライアンスがネットワークに正常に追加されました。',
  add_cc_failed: '入力したとおりにクレジット カードを追加できません',
  add_child_account: 'アカウントを追加する',
  add_credit_card: 'クレジットカードを追加',
  add_credit_card_confirm: 'クレジット カードがアカウントに正常に追加されました。',
  add_existing: '既存の追加',
  add_existing_condition: '既存の条件を追加します...',
  add_existing_rule: '既存のルールを追加します...',
  add_header: 'ヘッダーの追加',
  add_license: 'ライセンスの追加',
  add_notification_profile: '通知プロファイルの追加',
  add_policy_to_appliance: 'アプライアンスにポリシーを追加する',
  add_selected_appliances: '選択したアプライアンスをこのネットワークに追加します',
  add_template: 'テンプレートの追加',
  add_to_policy: 'ポリシーに追加',
  add_user: 'ユーザーを追加する',
  add_users: 'ユーザーの追加',
  add_wan_rule: 'WAN ルールの追加',
  additional_information: '追加情報',
  address_line2: '住所2',
  address_not_verified: 'このお支払い方法の住所を確認できませんでした。 アドレス情報を確認し、Arista Edge Threat Management Sales にお問い合わせください。',
  advertising: '広告とマーケティング',
  agni_user: 'アグニユーザー',
  agni_user_groups: 'AGNI ユーザーグループ',
  agree_and_continue: '同意して続行',
  agreement: 'Arista Edge Threat Management の {0} に同意します',
  agriculture: '農業',
  alert_create_failure: 'アラート ルールの作成に失敗しました。',
  alert_create_success: '新しいアラート ルールが正常に作成されました。 変更が反映されるまでに最大 20 分かかる場合があります。',
  alert_edit_failure: 'アラート ルールの編集に失敗しました。',
  alert_edit_success: 'アラート ルールが正常に編集されました。 変更が反映されるまでに最大 20 分かかる場合があります。',
  alert_history: 'アラート履歴',
  alert_new_device_discovered: 'Network Discovery によって新しいデバイスが検出されました',
  alert_rule: 'アラートルール',
  alert_rule_hint: 'イベント概要メッセージで見つかった場合にこのアラートをトリガーする文字列',
  alert_rule_name_hint: '一意のアラート ルール名',
  alert_rules: 'アラートルール',
  alerts: 'アラート',
  all_appliances: 'すべてのアプライアンス',
  all_current_and_future_appliances: '現在および<b>将来</b>のすべてのアプライアンス',
  all_offline: 'すべてのアプライアンスがオフライン',
  all_online: 'すべてのアプライアンスをオンラインに',
  all_traffic_local: 'ローカルネットワークからのすべてのトラフィック',
  analyze: '分析する',
  antivirus_scan: 'ウイルス対策スキャン',
  api_key: 'APIキー',
  api_token: 'APIトークン',
  appliance: 'アプライアンス',
  appliance_action_not_found: 'アプライアンスのアクションが見つかりません',
  appliance_add_licenses_sub_not_assignable: 'このライセンスはアプライアンスに割り当てることができません',
  appliance_add_totp_message: '時間ベースのワンタイム パスワード認証をアプライアンスに追加する',
  appliance_backup: 'アプライアンスのバックアップ',
  appliance_count: '家電なし | 1 つのアプライアンス | {n} 個のアプライアンス',
  appliance_details: 'アプライアンスの詳細',
  appliance_does_not_exist: 'アプライアンスは存在しません!',
  appliance_incompatible_with_vpn: 'アプリ {applist} が有効になっています。このアプライアンスをソフトウェア デファインド ネットワーク用に構成するには、これらのアプリを無効にしてください',
  appliance_incompatible_with_vpn_title: 'このアプライアンスは WireGuard VPN と互換性がありません',
  appliance_interface_disabled: 'インターフェースは無効になります',
  appliance_invalid_search: '検索項目の値が無効です。',
  appliance_license_will_auto_renew: 'このサブスクリプションは {days} 日後に自動更新されます',
  appliance_licenses: 'アプライアンスのライセンス',
  appliance_licenses_days_remaining: '残り {days} 日',
  appliance_lose_connectivity: 'これにより、アプライアンスが一時的に接続を失う可能性があります',
  appliance_map: 'アプライアンスマップ',
  appliance_model: 'アプライアンスのモデル',
  appliance_must_be_online: 'TOTP 設定を行うには、アプライアンスを ETM に接続する必要があります。',
  appliance_not_connected_cmd: 'アプライアンスは{to}に接続できませんでした。アプライアンスがインターネットにアクセスしてオンラインであることを確認してください。アプライアンスのUID（{uid}）またはシリアル番号が正しいことを確認してください。',
  appliance_offline_totp_message: 'オンライン時にアプライアンスに時間ベースのワンタイム パスワード認証を追加または削除します',
  appliance_policies: 'アプライアンスのポリシー',
  appliance_remote_access_failed: 'アプライアンスへのリモート接続の作成に失敗しました',
  appliance_remove_totp_message: 'このアプライアンスにはすでに時間ベースのワンタイム パスワードが設定されています',
  appliance_search_updates: 'ソフトウェアのアップデートを確認する',
  appliance_set_label_message: 'アプライアンスのラベルを入力してください',
  appliance_set_wifi_region_message: 'アプライアンスの Wi-Fi 地域を選択してください',
  appliance_settings_update_initiated: 'アプライアンスの設定を更新するためにタスクがキューに入れられました。',
  appliance_status_invalid_license_settings: '無効なライセンス設定',
  appliance_status_license_expired: '1 つ以上のサブスクリプションの有効期限が切れています。',
  appliance_status_license_expiring_soon: '1 つ以上のサブスクリプションがまもなく期限切れになります',
  appliance_status_no_licenses: 'アクティブなライセンスが見つかりません',
  appliance_status_nolicense_command_center: 'ETM ダッシュボードの全機能を使用するには、このアプライアンスを完全ライセンスにアップグレードする必要があります',
  appliance_status_offline: 'オフライン',
  appliance_status_online: 'オンライン',
  appliance_status_seat_count_exceeded: 'ライセンスシート数を超えました! すべてのホストが保護されるわけではありません。',
  appliance_status_trial_expired: '1 つ以上のトライアルの有効期限が切れています。',
  appliance_status_trial_expiring_soon: '1 つ以上のトライアルがまもなく期限切れになります',
  appliance_to_restore: '復元するアプライアンス',
  appliance_update_software_failed_title: 'ソフトウェアの更新に失敗しました',
  appliance_upgrade_available: '[アップグレード可能]',
  appliance_version_incompatible_with_vpn: '互換性のないアプライアンスのバージョン - アプライアンスには少なくともバージョン 16.0 (NGFW の場合) および 3.0 (Micro Edge の場合) が必要です',
  appliance_widgets: 'アプライアンスのウィジェット',
  appliance_widgets_text: '表示したいウィジェットを選択できます。 ドラッグ アンド ドロップを使用して、表示する順序を指定します。',
  appliance_without_a_policy_assigned: 'ポリシーが割り当てられていないアプライアンス',
  appliances: '家電製品',
  appliances_map: '家電マップ',
  appliances_out_of_sync: '{0} 台のアプライアンスが同期していません',
  appliances_selected: '1 つのアプライアンスが選択されました | {n} 個のアプライアンスが選択されました',
  appliances_title: '家電製品',
  appliances_update: 'アプライアンス ソフトウェア アップデート',
  appliances2: '家電製品:',
  application_cat: 'アプリケーションカテゴリー',
  application_control_description: 'Application Control テンプレートを使用して、このポリシーによってどのアプリケーションを許可または拒否するかを定義します',
  application_control_list_group: 'アプリケーション制御リストグループ',
  application_groups: 'アプリケーショングループ',
  application_productivity: 'アプリケーションの生産性レベル',
  application_risk: 'アプリケーションのリスクレベル',
  applications_control_list: 'アプリケーション制御リスト',
  architecture: '建築',
  arista_authorized: 'アリスタ認定',
  arista_edge_threat_management: 'Arista エッジ脅威管理',
  arista_q_series: 'Arista Q シリーズ アプライアンス',
  assign_a_policy: 'ポリシーを割り当てる',
  assign_appliance_duplicate_failed: 'このアプライアンスを追加できません。 アプライアンスはすでにこのアカウントに割り当てられています。',
  assign_appliance_organization_failed: 'このアプライアンスを追加できません。 すでに別の組織に属している場合は、この組織に追加する前に、現在の組織から削除する必要があります。',
  assign_label: 'アプライアンスにラベルを割り当てる',
  assign_sub_failed: 'アプライアンスにサブスクリプションを割り当てることができませんでした。',
  assign_sub_success: 'サブスクリプションは正常に割り当てられました。',
  assign_sub_to_appliance: 'アプライアンスにサブスクリプションを割り当てる',
  assign_subscription: 'サブスクリプションの割り当て',
  assign_subscription_to_this_appliance: 'このアプライアンスにサブスクリプションを割り当てる',
  assigned_to: 'に割り当てられた',
  assignment_description: 'ポリシーを 1 つ以上のセキュリティ アプライアンスに割り当てて同期する',
  assignment_status: '割り当てステータス',
  audit_history: '監査履歴',
  audit_info: '監査情報',
  authorization_endpoint_url: '認可エンドポイント URL',
  authorized_reseller: '正規販売代理店',
  auto_assign_subscriptions: 'サブスクリプションの自動割り当て',
  auto_assign_subscriptions_note: '注: これにより、未割り当てのサブスクリプションが、アカウントに追加された新しいアプライアンスに割り当てられます。',
  auto_renew: '自動更新',
  auto_renew_failed: 'サブスクリプションの自動更新に失敗しました',
  auto_renew_toggled: 'サブスクリプションの自動更新が更新されました',
  automatic_config: 'VPN トンネル エンドポイントは、ETM ダッシュボードに登録されているインターネット IP アドレスを使用して構成されます。',
  automatic_login: '自動ログイン',
  automotive: '自動車',
  available_physical_memory: '利用可能な物理メモリ',
  available_virtual_memory: '利用可能な仮想メモリ',
  available_zones: '利用可能なゾーン',
  average_jitter: '平均ジッター',
  average_latency: '平均レイテンシ',
  average_packet_loss: '平均パケット損失',
  back: '戻る',
  back_to_policies: 'ポリシーに戻る',
  backup_appliance_settings: 'アプライアンスのバックアップ設定',
  backup_date: 'バックアップ日:',
  backup_download_failed: 'バックアップファイルのダウンロードに失敗しました。',
  backup_generation_failure: 'マスター アプライアンスのバックアップの生成に失敗しました。',
  backup_hostname: 'ホスト名:',
  backup_label: 'ラベル：',
  backup_no_license: 'バックアップの生成に失敗しました。 アプライアンスには完全なライセンスがありません',
  backup_not_generated: 'バックアップの生成に失敗しました。',
  backup_uid: 'UID:',
  backups: 'バックアップ',
  bad_credit_card_exp_info: 'クレジット カードの有効期限が切れています。再入力してください。',
  bad_credit_card_num_info: '入力したクレジット カード番号は無効です。もう一度入力してください。',
  band_tier: 'バンド/ティア',
  bandwidth_control: '帯域幅制御',
  bank_name: '銀行名',
  bank_routing_number: '銀行ルーティング番号',
  banking_and_financial: '銀行および金融',
  bcc_email_addresses: 'BCCメールアドレスのCSVリスト',
  beta: 'ベータ',
  billing: '請求する',
  billing_address: '請求先住所',
  billing_agreement_id: '請求契約 ID:',
  billing_email: '請求メール',
  billing_email_info:
    '次のものを受け取ります:<br />' +
    '* 請求書<br/>' +
    '* 支払い失敗の通知<br/>' +
    '* クレジット カードの有効期限切れのお知らせ<br/>' +
    '* 30 日間の更新通知',
  billing_info: '請求情報',
  billing_info_account_positive_balance: '<b>注: </b>現在のアカウント残高は {val} です。<br/>お支払い情報を更新すると、未払いの残高がすべて回収されます。<br/>',
  billing_info_credit_positive_balance: '<b>注: </b> 現在のクレジット残高は {val} です。<br/>これらの金額は、次回のお支払いから差し引かれるクレジットを表しています。<br/>',
  billing_total: '12 か月の請求総額',
  billing_trend: '12 か月の請求傾向',
  bitdefender_access_url: 'アクセスURL',
  bitdefender_credentials: 'Bitdefender エンドポイント セキュリティ',
  bitdefender_credentials_instructions:
    '<a href="https://cloud.gravityzone.bitdefender.com/" target="_blank">Bitdefender GravityZone</a> アカウントにプロビジョニングした API キーを入力してください。 これにより、「ホスト」セクションに追加の情報と機能が追加されます。 詳細については、<a href="https://support.edge.arista.com/hc/en-us/articles/360051479273" target="_blank">こちら</a>をご覧ください。',
  blocked: 'ブロックされています',
  blocked_threats: 'ブロックされた脅威',
  branding_manager: 'ブランディングマネージャー',
  business: '仕事',
  buy: '買う',
  buy_license: 'ライセンスを購入する',
  buy_subscriptions: 'サブスクリプションを購入する',
  bypassed: 'バイパスされる',
  callback_url: 'コールバック URL',
  cannot_delete_account_because_haas: 'アクティブな HaaS サブスクリプションがあるため、アカウントを削除できません',
  cannot_leave_only_org: '自分だけの組織を離れることはできない',
  cannot_leave_org_error: '組織を脱退できませんでした',
  cannot_leave_primary_org: '主な組織を離れることはできません',
  captive_portal_description: 'キャプティブ ポータル テンプレートを使用して、キャプティブ ポータルをネットワーク上のデバイスに適用するタイミングを定義します',
  captive_portal_user: 'キャプティブ ポータル ユーザー',
  card_expiration_not_verified: 'カードの有効期限を確認できませんでした。 再入力してみるか、クレジットカード会社に直接お問い合わせください。',
  card_number: 'カード番号',
  card_type: 'カードの種類',
  cardholder_name: 'クレジットカード名義人氏名',
  cc_email_addresses: 'CCメールアドレスのCSVリスト',
  cc_expired_update_in_billing_info: 'クレジットカードの有効期限が切れています。 [アカウント] -> [マイ アカウント] -> [請求情報] に移動して更新してください。',
  cert: '証明書',
  certificate_id: '証明書ID',
  change_password: 'パスワードを変更する',
  change_policy_first_packet: 'ポリシー条件を最初のパケットで評価できる条件に変更します。',
  changed: 'かわった',
  charged_payment_method: '請求されるお支払い方法:',
  checking: 'チェック中',
  checksum: 'チェックサム',
  choose_endpoint: '自動 ({IP})',
  choose_global_configuration: 'グローバル構成を選択する',
  city: '市',
  class: 'クラス',
  class_type: 'クラスタイプ',
  clear_default: 'デフォルトをクリア',
  client_authentication_method: 'クライアント認証方式',
  client_endpoint_link: '&nbsp;&nbsp;&nbsp;{vendorname} コンソールに移動します',
  client_geoip: 'クライアント地域 IP',
  client_id: 'クライアントID',
  client_secret: 'クライアントシークレット',
  cloud_backups: 'クラウドバックアップ',
  cloud_backups_confirmation: '選択したバックアップを使用してこのアプライアンスを復元してもよろしいですか? アプライアンスは、ネットワーク設定を除き、バックアップの構成とポリシーを使用します。<br/><br/>プロセスが完了すると、復元の結果を含むトースト メッセージが画面に表示されます。',
  cloud_backups_confirmation_title: 'バックアップ復元の確認',
  cloud_backups_create_backup_error: 'バックアップの作成に失敗しました。 後でもう一度試してください。',
  cloud_backups_create_backup_success: 'バックアップが正常に作成されました。',
  cloud_backups_create_confirmation: '選択したアプライアンスからバックアップを作成してもよろしいですか?',
  cloud_vision: 'Cloud Vision',
  command_executed_successfully: 'コマンドが正常に実行されました',
  command_failed: '要求されたコマンドの実行に失敗しました。',
  command_failure: 'アプライアンスに接続できません',
  command_sent_successfully: 'コマンドが正常にキューに追加されました。',
  company: '会社',
  company_description: '会社概要',
  company_information: '企業情報',
  company_name: '会社名',
  company_website: '会社のウェブサイト',
  compare: '比較する',
  compare_description:
    '左側には、アプライアンスに現在設定されている設定が表示されます。右側には、次回の同期時にアプライアンスにプッシュされる設定が表示されます。',
  compare_no_data: '比較のためのデータを取得できません',
  comparison: '比較',
  complete: 'アプライアンスが正常に追加されました。 ホスト名の変更またはテンプレートのプッシュをリクエストした場合、これはスケジュールされており、すぐに完了します。',
  complete_appliance_setup: 'アプライアンスのセットアップを完了する',
  complete_remote: 'アプライアンスが正常に追加されました。ホスト名の変更またはテンプレートのプッシュを要求した場合、これはスケジュールされており、すぐに完了します。このアプライアンスにアクセスする新しいウィンドウが開き、セットアップを続行できます。',
  completed: '完了',
  condition_description: '状態の説明',
  condition_in_use:
    'この条件は複数のポリシーに割り当てられます。 コピーを作成することを検討してください。コピーしないと、変更は次のポリシーに適用されます: {0}',
  condition_name: '条件名',
  condition_object_already_added: '条件オブジェクトはすでにリストにあります',
  conditions_description: '条件を使用して、このポリシーをいつ適用するかを定義します。 条件がない場合は、ポリシーがすべてのトラフィック フローに適用されることを意味します',
  config_failed: 'このアプライアンスに接続できません。アプライアンスが接続されて使用可能になるとすぐに、構成の選択がプッシュされます',
  config_incomplete: 'このアプライアンスの構成は正常に完了しませんでした。{errorMessage}。アプライアンスでいくつかの手順を再実行する必要がある場合があります。',
  configuration_json: '構成JSON',
  configuration_templates: '構成テンプレート',
  configure_appliance: 'アプライアンスの構成',
  confirm_delete_child_dialog: 'このアカウントを削除すると、そのアカウントと共有されているすべてのサブスクリプションの共有が解除され、アカウントから管理者として削除されます。 続行してもよろしいですか?',
  confirm_delete_data: '選択した項目を削除してもよろしいですか?',
  confirm_delete_network: '選択したネットワークを削除してもよろしいですか?',
  confirm_delete_policy: '選択したポリシーを削除してもよろしいですか?',
  confirm_delete_templates: '選択したテンプレートを削除してもよろしいですか?',
  confirm_delete_wan_policy: '選択したポリシーを削除すると、次の WAN ルールも削除されます。',
  confirm_delete_wan_rules: '選択した WAN ルールを削除してもよろしいですか?',
  confirm_generate_vouchers: 'アカウントに請求が行われ、次のクーポンを受け取ります。',
  confirm_generate_vouchers_2: 'これらの伝票を生成してもよろしいですか?',
  confirm_leave_org: 'この組織を離れてもよろしいですか?',
  confirm_notification_profile_delete: '選択した通知プロファイルを削除してもよろしいですか?',
  confirm_proceed: '続行してもよろしいですか?',
  confirm_remove_appliances: '選択したアプライアンスを削除してもよろしいですか?',
  confirm_remove_task: '選択したタスクを削除してもよろしいですか?',
  confirm_remove_users: '選択したユーザーをアカウントから削除してもよろしいですか?',
  confirm_reset_vpn_tunnels: 'すべての VPN トンネルを削除して無効にしてもよろしいですか?',
  confirm_reset_wan_rules: 'すべての ETM ダッシュボード WAN ルールをネットワークおよびすべてのデバイスから削除してもよろしいですか?',
  confirm_restore: '次の復元を確認してください',
  confirm_restore_button: '復元の確認',
  conflicting_subnets: '赤で表示されたサブネットはネットワーク内の別のアプライアンスですでに構成されているため、このアプライアンスで VPN を有効にすることはできません',
  connect: '接続する',
  construction: '工事',
  contact: '接触',
  contact_information: '連絡先',
  could_not_authenticate: '認証できませんでした!',
  could_not_find_org: '退会したい組織が見つかりませんでした',
  countries: '国',
  coupons: 'クーポン',
  cpu_count: 'CPU数',
  create: '作成する',
  create_account: 'アカウントを作成する',
  create_account_msg_other: '他のアカウント情報を入力してください',
  create_account_msg_required: 'ログイン情報を入力してください',
  create_account_validate_email_and_token_error: 'アクティベーション トークンまたは電子メールが無効です。 メール アカウントをもう一度確認し、問題が解決しない場合は新しい招待をリクエストしてください。',
  create_alert_rule: 'アラートルールの作成',
  create_backup: 'バックアップを作成する',
  create_condition: '条件の作成',
  create_configuration: '構成の作成',
  create_default: 'デフォルトの作成',
  create_default_policy_confirmation: 'デフォルトのポリシーを作成してもよろしいですか?',
  create_defaults: 'デフォルトの作成',
  create_filter_group: 'フィルタールールグループの作成',
  create_group: 'グループを作る',
  create_network: 'ネットワークの作成',
  create_new: '新しく作る',
  create_notification_profile: '通知プロファイルの作成',
  create_notification_profile_failed: '通知プロファイルの作成に失敗しました',
  create_object: 'オブジェクトの作成',
  create_paypal_agreement: 'PayPal 請求契約を作成する',
  create_paypal_billing_agreement_failed: '請求契約の作成中に問題が発生しました。詳細については、Arista Edge Threat Management セールスにお問い合わせください。',
  create_paypal_billing_agreement_redirect_failed: 'PayPal との接続のセットアップ中に問題が発生しました。詳細については、Arista Edge Threat Management セールスにお問い合わせください。',
  create_paypal_billing_agreement_success: 'PayPal 請求契約が正常に作成されました',
  create_policy: 'ポリシーの作成',
  create_rule: 'ルールの作成',
  create_template: 'テンプレートの作成',
  create_template_instructions:
    'この操作により、Arista Edge Threat Management を更新するためのテンプレートが作成されます ' +
    '構成を含むターゲット アプライアンス リスト内のアプライアンスと ' +
    'テンプレート マスターからのポリシー (ネットワークを除く) ' +
    '構成）。',
  create_update_wan_rule: 'WANルールの作成/更新',
  created: '作成した',
  creating_network: 'ネットワークの作成',
  creating_updating_alert: 'アラートルールの作成/更新',
  creating_updating_notification: '通知プロファイルの作成/更新',
  credit_card: 'クレジットカード',
  credit_cards: 'クレジットカード',
  criteria: '基準',
  ctw_example: 'CTWの後に8桁が続く',
  current: '現在の組織',
  current_password: '現在のパスワード',
  current_product: '現在の製品',
  current_server_datetime: '現在のアプライアンスの日付/時刻',
  current_subscriptions: '現在のサブスクリプション',
  custom_interface_zone: 'カスタムインターフェイスゾーン',
  customer_accounts: '顧客アカウント',
  customer_not_found: '指定された電子メール アドレスを持つユーザーが見つかりませんでした。',
  daily: '毎日',
  dashboard_widgets_text: '表示したいウィジェットを選択できます。 ドラッグ アンド ドロップを使用して、表示する順序を指定します。',
  data: 'データ',
  date: '日付',
  date_created: '作成日',
  date_installed: '設置日',
  date_redeemed: '引き換え日',
  date_time: '日付時刻',
  date_updated: '更新日',
  day_of_the_week: '曜日',
  default_payment_method: 'デフォルトの支払い方法',
  default_payment_update: 'デフォルトのお支払い方法が正常に更新されました',
  default_payment_update_failed: 'デフォルトのお支払い方法を保存できませんでした',
  default_rule: 'デフォルトのルール',
  delayed_complete_no_config: 'アプライアンスが正常に追加されました。アプライアンスのセットアップを完了し、要求されたその他の構成変更を行うためのタスクがキューに入れられました。',
  delete: '消去',
  delete_account: 'アカウントを削除する',
  delete_account_confirmation: "<strong>警告!</strong> アカウントを削除すると、アカウントに関連付けられたすべてのデータが削除され、ログアウトされます。 <strong>この操作は元に戻すことができません。</strong> <br/><br/>下に「削除」と入力してください。 <br/>",
  delete_account_failed: 'アカウントの削除中にエラーが発生しました',
  delete_account_success: 'アカウントは正常に削除されました',
  delete_account_warning: '警告！ アカウントを削除すると削除されます',
  delete_ach_failed: '少なくとも 1 つの ACH がファイルに登録されているか、別のデフォルトの支払い方法が選択されている必要があります。',
  delete_ach_success: '選択した ACH 支払い方法は正常に削除されました',
  delete_alert_rule: 'アラートルールの削除',
  delete_alert_rules_confirm: '選択したアラート ルールを削除してもよろしいですか?',
  delete_alert_rules_failed: '選択したアラート ルールを削除できませんでした。',
  delete_alert_rules_success: '選択したアラート ルールが正常に削除されました。',
  delete_av_confirm_text: 'アカウントからウイルス対策設定を削除してもよろしいですか?',
  delete_av_confirm_title: 'ウイルス対策設定を削除する',
  delete_cc_confirm_text: '選択したクレジット カードをアカウントから削除してもよろしいですか?',
  delete_cc_confirm_title: '選択したクレジットカードを削除',
  delete_cc_failed: '少なくとも 1 枚のクレジット カードを登録するか、別のデフォルトの支払い方法を選択する必要があります。',
  delete_child_dialog: 'アカウントを削除する',
  delete_confirm: '選択したアプライアンスをアカウントから削除してもよろしいですか?',
  delete_confirm_network_also: '選択したアプライアンスをアカウントから削除してもよろしいですか? これはネットワーク {networkName} 上の最後のアプライアンスであるため、このネットワークも削除されることに注意してください。',
  delete_credit_card_confirm: '選択したクレジット カードは正常に削除されました。',
  delete_duplicate_condition: '重複した条件を削除する',
  delete_duplicate_group: '重複グループを削除',
  delete_duplicate_object: '重複したオブジェクトを削除する',
  delete_duplicate_rule: '重複ルールの削除',
  delete_failure: '選択した {0} を削除できませんでした',
  delete_network: 'ネットワークの削除',
  delete_notification_profile: '通知プロファイルの削除',
  delete_notification_profile_error: '選択した通知プロファイルを削除できませんでした。',
  delete_notification_profile_failed: '通知プロファイルの削除に失敗しました',
  delete_notification_profile_success: '選択した通知プロファイルが正常に削除されました。',
  delete_notification_profiles: '通知プロファイルの削除',
  delete_notification_profiles_error: '通知プロファイルの削除中にエラーが発生しました',
  delete_notification_profiles_error_associated_to_alert_rules: '次の通知プロファイルはアラート ルールにアタッチされているため削除できません。',
  delete_policies: 'ポリシーの削除',
  delete_policy: 'ポリシーの削除',
  delete_success: '選択した {0} は正常に削除されました',
  delete_templates: 'テンプレートの削除',
  delete_title: 'アプライアンスの削除',
  delete_voucher_fail_not_owner: 'エラー。所有者ではないバウチャーは削除できません',
  delete_vouchers: 'クーポンの削除',
  delete_vouchers_failure: '選択したクーポンを削除できませんでした。',
  delete_vouchers_message: '選択したバウチャーを削除してもよろしいですか?',
  delete_vouchers_success: '選択した伝票が正常に削除されました。',
  delete_vpn_failed: 'VPN トンネルの削除に失敗しました',
  delete_vpn_tunnels: 'VPN トンネルの削除',
  delete_wan_rules: 'WAN ルールの削除',
  deleted: '削除されました',
  deleting_ach_payment: 'ACH 支払いの削除',
  deleting_credit_card: 'クレジットカードの削除',
  destination_dns_hint: '宛先DNSヒント',
  details: '詳細',
  detected_intrusions: '侵入が検出されました',
  dev: '開発者',
  device_id: 'デバイスID',
  directory_connector: 'ディレクトリコネクタ',
  disagree: '同意しない',
  discard: '破棄',
  disconnected_appliance_expired_certificate: '証明書の有効期限が切れているため、アプライアンスが切断されていると表示される場合があります。 ETM ダッシュボードの接続を復元するには、最新バージョンに更新してください。',
  disconnected_warning1: '指定されたアプライアンスは現在システムに接続されていません。',
  disconnected_warning2: '次の画面では、アプライアンスの構成情報を定義できます。<br>アプライアンスへの接続を試行し、この情報で 7 日間更新します。',
  disconnected_warning3: 'それ以降は、このアプライアンスに接続し、利用可能なときに手動で更新する必要があります。',
  discount: '割引',
  disk: 'ディスク',
  disk_information: 'ディスク情報',
  dns_hint: 'DNS ヒント',
  docker_container: 'Dockerコンテナ',
  domain: 'ドメイン',
  domain_field: 'ドメインを入力してください',
  download_saml: 'SAMLをダウンロード',
  download_sp_metadata: 'SPメタデータのダウンロード',
  duplicate_condition: '重複条件',
  duplicate_group: '重複したグループ',
  duplicate_object: 'の重複オブジェクト',
  duplicate_reboot_time: '今回はすでに再起動がスケジュールされています',
  duplicate_rule: '重複ルール',
  duplicate_transaction: '重複したトランザクションが検出されました。 決済プロバイダーに確認するか、Arista Edge Threat Management Sales にお問い合わせください。',
  edit_alert_rule: 'アラートルールの編集',
  edit_condition: '条件の編集',
  edit_conditions: '条件を編集する',
  edit_configuration: '構成の編集',
  edit_credit_card: 'クレジットカードの編集',
  edit_email_template: 'メールテンプレートの編集',
  edit_email_template_fail: 'メールテンプレートの保存中にエラーが発生しました',
  edit_email_template_success: '電子メールテンプレートが正常に保存されました',
  edit_group: 'グループの編集',
  edit_location: '場所の編集',
  edit_location_text: 'アプライアンスのアドレスを入力してください',
  edit_notification_profile: '通知プロファイルの編集',
  edit_policy_order: 'ポリシーの順序を編集する',
  edit_shared_subnets_message: 'この VPN 内の他のすべてのピアリングされたネットワークにアクセスできる必要がある、このアプライアンスの背後にあるサブネットを選択します。 空の選択は、アプライアンスがクライアント モードになることを意味します。',
  edit_shared_subnets_message_ngfw: 'この VPN 内の他のすべてのピアリングされたネットワークにアクセスできる必要がある、このアプライアンスの背後にあるサブネットを選択します。 現在 NGFW ではクライアント モードがサポートされていないため、少なくとも 1 つの有効なサブネットが必要です',
  edit_template: 'テンプレートの編集',
  edit_user_permissions: 'ユーザー権限の編集',
  edit_user_permissions_fail: 'ユーザーの権限を更新できません。',
  edit_user_permissions_success: 'ユーザー権限が正常に更新されました。',
  edit_wan_rule: 'WAN ルールの編集',
  education: '教育',
  electronic_record: '電子記録',
  electronic_record_button: 'コピーをリクエストする',
  electronic_record_text: '電子記録のコピーをリクエストする',
  email: 'Eメール',
  email_body: '体',
  email_configuration: '電子メール設定',
  email_contact: 'メールでのお問い合わせ',
  email_from: 'から',
  email_hint: '招待するユーザーのメール アドレスをカンマ区切りで入力します',
  email_in_use: '入力したメインの電子メール アドレスはすでに使用されています。',
  email_not_found: 'メールアドレスが見つかりません。',
  email_send_failed: 'パスワードリセットメールの送信に失敗しました。',
  email_subject: '主題',
  email_to: 'に',
  email_validation: 'このフィールドは有効な電子メールである必要があります',
  enable_auto_login: 'リモートアクセスの自動ログインを有効にする',
  enable_mfa: '二要素認証を有効にする',
  enable_mfa_description: '2 要素認証により、アカウントのセキュリティが強化されます。 メールアドレスとパスワードを使用してログインした後、確認コードの入力も求められます。 アカウントを保護するための 2FA の設定の詳細については、<a target="_blank" href="https://support.edge.arista.com/hc/en-us/articles/360006054634">こちら</a>をご覧ください。',
  enable_sso: 'SSOを有効にする',
  enable_vpn_message: 'VPN アクセスを有効にすると、現在のネットワーク内のすべての VPN 対応アプライアンスとのトンネルが追加されます。',
  encryption_certificate: '暗号化証明書',
  end_date: '終了日',
  end_time: '終了時間',
  endpoint_address_override: 'エンドポイントアドレスオーバーライド',
  endpoint_id: 'エンドポイントID',
  endpoint_security: 'エンドポイントセキュリティ',
  endpoint_security_title: 'どのエンドポイント セキュリティ システムを使用していますか?',
  endpoint_service_info_1: '「サービス」オブジェクトを表す 1 つ以上のプロトコル/ポートの組み合わせを入力します。',
  endpoint_service_info_2: 'オブジェクトがサービス条件で使用される場合、以下のいずれかの組み合わせが満たされると条件が満たされます。',
  endpoint_url: 'エンドポイント URL',
  engine_version: 'エンジンのバージョン',
  enter_md5_hash_ip_url_mac: 'IP アドレス、MAC アドレス、URL、またはファイルの MD5 ハッシュを入力して、アイテムに関する詳細情報を取得します。',
  enter_purchase_order_number: 'このサブスクリプションの新しい注文番号を入力してください',
  enter_uid_or_serial_to_add: 'アプライアンスの UID またはシリアル番号を入力します。 &nbsp;<a href="{url}" target="_blank">UID を確認するにはどうすればよいですか?</a>',
  entertainment: 'エンターテインメント',
  entitled: '権利がある',
  entity_id: 'エンティティID',
  error_message: 'エラーメッセージ',
  error_occurred: 'エラーが発生しました：',
  etm_dashboard: 'ETM Dashboard',
  event_log: 'イベントログ',
  exact_14: '正確に 14 文字の英数字',
  existing_user_login: 'このメール アドレスでは新しいアカウントを作成できません。',
  exit: '出口',
  expiration_date: '有効期限',
  expiration_month: '有効期限月',
  expiration_year: '有効期限年',
  expired_reset_key: 'リセット キーが有効でないか、有効期限が切れています。 パスワードを再度リセットしてください。',
  expires: '有効期限が切れます',
  failed_completing_invite: 'この時点では招待を完了できません',
  failed_push: '選択したアプライアンスにポリシー {policyName} をプッシュできませんでした。',
  failed_redeem_no_zuora: 'バウチャーを引き換えることに失敗しました。 支払い方法が定義されていません。',
  failed_save_user_settings: 'ユーザー設定の保存に失敗しました',
  fax: 'ファックス',
  feature_not_supported: '{0} 機能はサポートされていません',
  feature_upgrade_message: 'アプライアンスはこの機能をサポートしていません。 すべての最新機能を利用するには、アプライアンスをアップグレードしてください。',
  federal_government: '連邦政府',
  file_type: 'ファイルの種類',
  filesize: 'ファイルサイズ',
  filter_event: 'フィルタールールブロック',
  filter_group_description: 'フィルタルールをグループに追加する',
  final_step: '最終段階',
  finance: '金融と保険',
  firewalls: 'ファイアウォール',
  firewalls_managed: '管理下のファイアウォール:',
  first_known: '最初に知られたもの',
  first_name: 'ファーストネーム',
  first_seen: '初めて見た',
  first_time_sync_confirmation: '1 台以上のアプライアンスが初めてポリシーを受信して​​います。アプライアンス上の対応するローカル構成は、このポリシーによって置き換えられます。',
  flagged: 'フラグが立てられています',
  following_attributes_returned: '次の属性が SAML AuthNRequest から返されました。',
  following_attributes_returned_oauth2: '次の属性がユーザー情報エンドポイントから返されました。',
  food: '食料と飲料',
  forever: '永遠に',
  forgot_password: 'パスワードをお忘れですか？',
  free: '無料',
  free_space: 'フリースペース',
  from_template_master: '送信者: テンプレート マスター:',
  general_information: '一般情報',
  generate_prepaid_vouchers: 'プリペイドバウチャーを生成する',
  generate_voucher_success: 'バウチャーが作成されました。 あなたはすぐに確認メールが届きます。',
  generate_vouchers: 'バウチャーの生成',
  generic: 'ジェネリック',
  generic_error: 'エラーが発生しました。',
  geo_ip_description: 'Geo-IP フィルター テンプレートを使用して、このポリシーでブロックまたは許可する国を定義します',
  geoip_blocked_session: 'Geo-IP によってセッションがブロックされました',
  get: '得る',
  get_billing_data_failed: 'このページのデータを取得できません。 問題が引き続き発生する場合は、もう一度試して、Arista Edge Threat Management セールスにお問い合わせください。',
  get_notification_profile_failed: '通知プロファイルの取得に失敗しました',
  get_screenshot: 'スクリーンショットを取得する',
  get_started: '始める',
  get_status: 'ステータスの取得',
  get_support: '支持を得ます',
  get_upgrade_price_failed: 'アップグレード価格を取得できません。 更新してもう一度お試しください',
  getting_started_untangle_go: "<a href='https://support.edge.arista.com/hc/en-us/articles/1500003534002-Getting-Started-with-Untangle-Go-mobile-app-' target='_blank'>Erste Schritte mit Untangle Go</a>.",
  global: 'グローバル',
  global_configuration: 'グローバル構成',
  global_templates: 'グローバルテンプレート',
  go_back: '戻る',
  gold_partner: 'ゴールドパートナー',
  government: '政府・行政',
  group_conditions: 'グループの条件',
  group_description: 'グループの説明',
  group_name: 'グループ名',
  group_not_found: 'ポリシーが見つかりません',
  hardware_appliances: 'ハードウェアアプライアンス',
  headers: 'ヘッダー',
  healthcare: 'ヘルスケアと医薬品',
  help: 'ヘルプ',
  higher_education: '高等教育',
  home: 'ホーム / 個人',
  home_protect_basic: 'ホームプロテクトベーシック',
  home_protect_plus: 'ホームプロテクトプラス',
  hospitality: 'ホスピタリティ',
  hospitality_and_retail: 'ホスピタリティと小売',
  host_count: 'ホスト数',
  host_details: 'ホストの詳細',
  host_type: 'ホストの種類',
  hosts: 'ホスト',
  hosts_selector_lookup: 'ホスト名、IP、MAC アドレス、または OS を検索します',
  http_user_agent: 'HTTPユーザーエージェント',
  icon: 'アイコン',
  ignore_failure: '選択された {0} を無視できませんでした',
  ignore_for_now: '今のところ無視してください',
  ignore_success: '選択された {0} は正常に無視されました',
  immediately: 'すぐに',
  import: '輸入',
  important: '重要',
  in_sync: '同期中',
  incoming_alerts: '着信アラート',
  incompatible_product_license: 'この製品は既存の製品ライセンスと互換性がないため、アプライアンスにインストールできません。',
  industry: '業界',
  info: '情報',
  info_alerts: 'アプライアンスによって生成されたアラートのリスト。 頻繁/繰り返しのアラートは 15 分間にわたって集約されます',
  info_appliance_map: '家電製品の設置場所の地図',
  info_appliance_policies: 'このアプライアンスに関連付けられたポリシーのリスト。',
  info_appliances_grid: '家電製品一覧',
  info_audit_history: 'アカウントアクティビティのリスト',
  info_blocked_threats: 'ウイルス ブロッカー アプリによってブロックされた脅威と関連メタデータのリスト',
  info_cloud_backups: 'クラウドバックアップのリスト',
  info_equals: '定義された値と等しい',
  info_greater: '定義された値より大きい',
  info_greater_or_equal: '定義された値以上です',
  info_in: 'オブジェクトグループに属している',
  info_intrusions_detected: '侵入防御サービスによって検出された最大 25 件の侵入のリスト',
  info_less: '定義された値未満です',
  info_less_or_equal: '定義された値以下である',
  info_match: 'オブジェクトと一致します',
  info_network_performance: 'アプライアンスと関連するネットワーク パフォーマンスのリスト。 アプライアンスをクリックして追加の詳細を表示します',
  info_not_equals: '定義された値と等しくない',
  info_not_in: 'オブジェクトグループに属していません',
  info_not_match: 'オブジェクトと一致しません',
  info_policy_analytics: 'ポリシー分析情報',
  info_wan_link_information: 'WAN リンクの使用状況とパフォーマンスに関連する履歴情報。',
  input_a_comma_separated_list_of_ips_cidrs: 'IP/CIDR または範囲のカンマ区切りリストを入力します。',
  input_placeholder_text: '例.com',
  input_vlan_tags: 'VLAN タグを入力してください',
  inside_untangle_blog: 'ブログ',
  inside_untangle_blog_text: 'ネットワークを安全に保つための最新のセキュリティトレンド、ニュース、ヒントとコツを入手してください',
  installed_software: 'インストールされているソフトウェア',
  integrations: '統合',
  interface_id: 'インターフェースID',
  interface_type: 'インターフェースの種類',
  internal_error_occurred: '内部エラーが発生しました。 Arista Edge Threat Management セールスにお問い合わせください。',
  internal_server_error: '内部サーバーエラー',
  intrusion_info: '侵入情報',
  intrusions_detected: '侵入が検出されました',
  intrusions_detected_last_30_days: '検出された侵入 - 過去 30 日間',
  invalid_backup_file_version: 'バックアップ ファイルのバージョンはこのアプライアンスではサポートされていません。',
  invalid_coupons_note: '注: クーポン {invalidCoupons} は現在の注文には適用されません',
  invalid_credit_card_number: 'クレジット カード番号が無効です。 再入力してみるか、クレジットカード会社に直接お問い合わせください。',
  invalid_csrf_token: '無効なセキュリティ トークンが送信されました。 リクエストをもう一度試してください。',
  invalid_information: '無効な情報が提供されました',
  invalid_json: '無効な Json 形式です',
  invalid_name: '無効な名前',
  invalid_notification_profile_emails: '通知プロファイルを作成できません。 電子メール アドレスは組織のユーザーである必要があります。',
  invalid_policy_condition: '無効なポリシー条件',
  invalid_uid: '無効なUID',
  invalid_voucher: '入力したクーポンは無効です。',
  invitation_missing: 'このパートナーの招待情報がありません - 削除して再度追加してください',
  invite: '招待する',
  invite_body: '下の [確認] ボタンをクリックして招待プロセスを完了してください。',
  invite_title: '招待状を確認する',
  invited_email: '招待したいメールアドレス',
  invites_being_resent: '選択したユーザーに招待が再送信されています',
  invoice_balance: '請求書残高',
  invoice_date: '請求書の日付',
  invoice_number: '請求書番号',
  invoice_total: '請求書の合計',
  invoices: '請求書',
  ip: 'IP',
  ip_address_hint: '例えば 192.168.1.0/24、192.168.2.0/24',
  ip_addresses: 'IPアドレス',
  ip_info: 'IP情報',
  ip_location: 'IP の場所',
  is_pre_paid: '前払いです',
  iso_installer: 'ISO インストーラー (グラフィカル モード)',
  issue: '問題',
  item_delete_fail: '警告: アイテムを削除できませんでした',
  item_dependant_items: '項目には次の依存関係があります。',
  job_id: 'ジョブID',
  keep_current_settings: '現在の設定を維持する',
  keep_in_sync: '同期を保つ',
  keep_in_sync_hint: 'この設定を有効にすると、マスター アプライアンスで変更が検出されるたびに、ターゲット アプライアンスがマスター アプライアンスと自動的に同期されます (ネットワーク構成の変更を除く)。',
  label: 'ラベル',
  label_not_assigned: 'ラベルが割り当てられていません',
  label_not_set: 'ラベルが設定されていません',
  last_login: '前回のログイン',
  last_name: '苗字',
  last_scan_results: '前回のスキャン結果',
  last_session: '最後のセッション',
  last_sync_md5: '最終同期MD5',
  last_sync_status: '最後の同期は {syncTime} に開始されました。 ステータスの表示',
  last_synced: '最終同期',
  latest: '最新',
  latitude: '緯度',
  learn_more: 'もっと詳しく知る',
  leave_organization: '組織を離れる',
  legal: '法律サービス',
  license_entitled: '資格のあるライセンス',
  license_setup_not_located: 'このシリアル番号を持つアプライアンスはシステム内に見つかりませんでした。アプライアンスは、接続時にアカウントに追加されます',
  licensed_devices: 'ライセンスを取得したデバイス',
  list_of_ips_cidrs: 'IP/CIDR または範囲のカンマ区切りリスト',
  list_of_ports_or_ranges: 'ポートまたはポート範囲のカンマ区切りリスト',
  loading_remote_access: 'リモートアクセスをロード中',
  location: '位置',
  lock_workstation: 'ロックワークステーション',
  logged_in_as: 'ログイン名:',
  logged_in_from: 'ログイン元:',
  logged_users: 'ログオンしているユーザー',
  login: 'ログイン',
  login_attempt_locked_out: 'ログイン試行が {loginAttempts} 回失敗したため、アカウントからロックアウトされました。{lockoutMinutes} 分後に再度ログインを試行できます。',
  login_email_verify_complete: 'あなたのメールアドレスが確認されました。 Arista ETM アカウントには自由にアクセスできます。',
  login_email_verify_error: 'アクティベーション トークンまたは電子メールが無効です。 電子メール アカウントを確認して再試行するか、確認電子メールを再送信してください。',
  login_email_verify_incomplete: 'アカウントのプロフィールが完了していません。 メールをチェックしてメール アドレスを確認するか、確認メールを再送信してください。',
  login_email_verify_resend: '確認メールが再送信されました。',
  login_failed: 'ユーザーネームまたはパスワードが違います。 もう一度試してください。',
  login_mfa_verification_required_email: 'このアカウントでは<strong>二要素認証</strong>が有効になっています。 アカウントに関連付けられたメールに送信された確認コードを入力してください。',
  login_mfa_verification_required_totp: 'このアカウントでは<strong>二要素認証</strong>が有効になっています。 TOTP 認証アプリケーションからの確認コードを入力してください。',
  login_password_reset_form: '新しいパスワードを入力してください。',
  login_sso_required: 'このアカウントには、Google または Microsoft 経由の SSO ログインが必要です。適切なログイン オプションを使用してください。',
  login_sso_required_google: 'このアカウントには Google 経由の SSO ログインが必要です',
  login_sso_required_microsoft: 'このアカウントには Microsoft 経由の SSO ログインが必要です',
  login_sso_required_organization: 'このアカウントには組織名を使用した SSO ログインが必要です',
  login_url: 'ログインURL（HTTP-REDIRECT）',
  login_welcome_back: 'おかえり、',
  login_welcome_downloads: 'ETM ダッシュボードを使用して<a href="{baseUrl}download/ng-firewall" target="_blank">ダウンロード</a>にアクセスします',
  login_welcome_notindividual: 'ない、',
  longitude: '経度',
  lookup_geo: '地理座標を調べる',
  lowest_jitter: '最低のジッター - あらゆる WAN',
  lowest_jitter_non_lte: '最低のジッター - 非 LTE WAN',
  lowest_latency_any_wan: '最小の遅延 - あらゆる WAN',
  lowest_latency_non_lte: '最低の遅延 - 非 LTE WAN',
  mac_address_info: 'MACアドレス情報',
  mac_address_vendor: 'MACアドレスベンダー',
  main_email: 'メインメール',
  main_email_info: 'このアカウントのすべての電子メール通信を受信します',
  maintain_security_renew_your_subscription: 'セキュリティと重要な機能を維持するには、<a href="{baseUrl}organization/subscriptions">サブスクリプションを更新</a>してください。',
  make_selection: '保存するには選択してください',
  malwarebytes_credentials: 'Malwarebytes エンドポイント セキュリティ',
  malwarebytes_credentials_instructions: '<a href="https://cloud.malwarebytes.com/" target="_blank">Malwarebytes Nebula Console</a> アカウントの認証情報を入力してください。 これにより、「ホスト」セクションに追加の情報と機能が追加されます。',
  manage_disabled: '無効',
  manage_full_access: '全権アクセス',
  manage_message: '管理するアプライアンスの選択',
  manage_read_only: '読み取り専用',
  manage_subscription_payfail: 'このサブスクリプションを管理する前に、{subscriptionName} の支払いを送信してください。',
  manage_subscriptions: 'サブスクリプションの管理',
  managed_service_provider: 'マネージドサービスプロバイダー',
  management_address: '管理アドレス',
  managing: '管理します：',
  manufacturer: 'メーカー',
  manufacturing: '製造業',
  map_platinum: 'MAPプラチナ',
  map_tooltip: '<b>ホスト名:</b> {hostName}<br/><b>UID:</b> {uid}<br/><b>ラベル:</b> {tag}<br/>< b>サーバー IP:</b> {serverIp}<br/><b>ステータス:</b> {status}<br/><b>ホスト数:</b> {hostCount}<br/>< b>場所:</b> {場所}',
  mask_uids: 'マスクUID',
  master_appliance: 'マスターアプライアンス',
  master_appliance_not_found: '  バックアップに失敗しました。マスター アプライアンスが見つかりません',
  master_not_connected_to_cmd: 'マスター アプライアンスはオフラインです。 マスター アプライアンスがオンラインであることを確認して、再試行してください。',
  md5: 'MD5',
  md5_hash_info: 'MD5ハッシュ情報',
  memory_details: 'ホストメモリの詳細',
  message: 'メッセージ',
  message_type: 'メッセージの種類',
  method: '方法',
  mfa: 'MFA',
  mfa_verification_failed: 'このアカウントの<strong>二要素認証</strong>が失敗しました。',
  mfw_image_e3: 'GZ（e3画像）',
  mfw_image_e6: 'GZ（e6画像）',
  mfw_no_subscription: 'アプライアンスにはサブスクリプションがありません。 接続速度が制限され、セキュリティ機能が無効になります。 サブスクリプションを割り当てるか、<a href="{0}configurator/?sku=SR-11-SWSE-0100-1YEAR" target="_blank">ここをクリック</a>してサブスクリプションを購入します。',
  mfw_setup_wizard_refresh_text: '<h1>セットアップ ウィザード</h1>デバイスでセットアップ ウィザードを完了したら、アプライアンスを更新してください。<br/><br/>',
  mfw_setup_wizard_text: '<h1>セットアップ ウィザード</h1>Arista Edge Threat Management をお選びいただきありがとうございます。 ウィザードの指示に従って、デバイスの初期セットアップと構成を行います。<br/><br/>',
  mfw_upgrade_51: 'これにアクセスするには、トップメニューを使用してバージョン 5.1 以降にアップグレードしてください。',
  micro_edge: 'マイクロエッジ',
  micro_edge_basic: 'マイクロエッジベーシックエディション',
  micro_edge_basic_nonprofit: 'Micro Edge Basic Edition 非営利団体',
  micro_edge_basic_public_sector: 'Micro Edge Basic Edition 公共部門',
  micro_edge_description: 'Micro Edge は、軽量のネットワーク エッジ アプライアンスです。 NG ファイアウォールと一緒に導入して複数拠点のオフィスにネットワーキング ソリューションを提供することも、単独で導入してネットワーク パフォーマンスを最適化することもできます。 <br/><br/>仮想アプライアンスには、少なくとも 256 MB の RAM と 128 MB のストレージが必要です。 <a href="https://support.edge.arista.com/hc/en-us/articles/360022862034-Untangle-SD-WAN-Router-System-Requirements" target="_blank">Micro Edge System を参照してください。 詳細については、 要件</a>を参照してください。',
  micro_edge_policies: 'マイクロエッジポリシー',
  micro_edge_security: 'マイクロエッジセキュリティエディション',
  micro_edge_security_nonprofit: 'Micro Edge Security Edition 非営利団体',
  micro_edge_security_public_sector: 'Micro Edge Security Edition 公共部門',
  micro_edge_templates: 'マイクロエッジテンプレート',
  micro_edge_vmdk_info: 'これらのファイルの両方をダウンロードしてインストールする必要があります',
  mining: 'マイニング',
  missing_templates: '次のテンプレート構成: {policyTypes} は正常に作成されませんでした',
  mobile: 'アンタングルゴー (モバイル)',
  mobile_beta: 'アンタングル・ゴー',
  mobile_configuration: 'モバイル構成',
  mobile_date_paired: 'ペアリングされた日付',
  mobile_device_type: 'デバイスタイプ',
  mobile_info: '通知を受け取りたいモバイルデバイスを選択してください。',
  mobile_number: '携帯電話番号',
  mobile_summary:
    "Untangle Go は、Android および iOS ベースのデバイス用のモバイル アプリで、ステータス、アラート、システム リソースなど、Edge Threat Management アプライアンスとサブスクリプションに関する情報をモバイル デバイスから表示できるようにします。 Untangle Go は ETM ダッシュボードを補完しますが、それに代わるものではありません。 <a href='https://aristaetm.featureupvote.com/' target='_blank'>フィードバック</a>をお待ちしております。",
  modal_content:
    '<div style="width:100%;"><p>Arista Edge Threat Management では、お客様のプライバシーを重視しています。 最近<br/>欧州連合の一般データプライバシー規則に準拠するためにプライバシーポリシーを更新しました。<br/> この<br/>ポリシーでは、以下について説明します。</p><ul><li>当社が収集する情報とその収集理由。</li><li>情報の使用方法。</li><li>当社がどのように利用するか。 情報へのアクセス、更新、または削除。</li></ul> <p>当社は、このポリシーを読みやすく、理解しやすいものにするよう努めています<br/>。 ここでポリシーを読んで確認してください:<br/><a href="https://www.arista.com/en/privacy-policy" target="_blank">https://www.arista.com/en /privacy-policy</a><br/><br/>ポリシーを確認したことを確認し、<br/>プライバシー ポリシーの詳細に従って Untangle が個人データを使用することに<br/>同意してください。 /p><div style="text-align: center; padding-top: 10px;"></div></div>',
  model: 'モデル',
  most_bandwidth_any_wan: '最も利用可能な帯域幅 - 任意の WAN',
  most_bandwidth_non_lte: '最も利用可能な帯域幅 - 非 LTE WAN',
  msp: 'MSP',
  msp_authorized: 'MSP認定済み',
  msp_gold: 'MSPゴールド',
  msp_silver: 'MSPシルバー',
  my_info: '私の情報',
  my_organization: '私の組織',
  name_on_account: 'アカウント上の名前',
  nat_description: 'NAT ルールを使用して、特定のトラフィック フローにネットワーク アドレス変換 (NAT) を適用します。',
  negative_price_explanation: 'マイナスの金額はクレジット残高に転送されます。',
  network_average: 'ネットワーク平均',
  network_details: 'ネットワークの詳細',
  network_map: 'ネットワークマップ',
  network_name: 'ネットワーク名',
  network_not_found: 'ネットワークが見つかりません',
  network_performance: 'ネットワークパフォーマンス',
  network_selector_lookup: 'ネットワーク名を検索する',
  network_summary: 'ネットワークの概要',
  networks: 'ネットワーク',
  never: '一度もない',
  new_product: '新製品',
  next: '次',
  next_step: '次のステップ',
  ng_firewall: 'NG ファイアウォール',
  ng_firewall_complete: 'NG ファイアウォール - 完了',
  ng_firewall_homepro: 'NG ファイアウォール ホームプロ',
  ng_image_img: 'IMG (USB イメージ)',
  ng_image_iso: 'ISO (インストールイメージ)',
  ng_image_linksys32X_factory: 'IMG (Linksys WRT32X ベータ) ファクトリー',
  ng_image_linksys32X_upgrade: 'IMG (Linksys WRT32X ベータ) アップグレード',
  ng_image_linksys1900_factory: 'IMG (Linksys WRT1900ACS ベータ) 工場',
  ng_image_linksys1900_upgrade: 'IMG (Linksys WRT1900ACS ベータ) アップグレード',
  ng_image_linksys3200ACM_factory: 'IMG (Linksys WRT3200ACM ベータ) 工場',
  ng_image_linksys3200ACM_upgrade: 'IMG (Linksys WRT3200ACM ベータ) アップグレード',
  ng_image_ova: 'OVA (VMware アプライアンス)',
  ng_image_vdi: 'VDI (Oracle VirtualBox)',
  ng_image_vmdk: 'VMDK (VMWare イメージ)',
  ngfw_iso_description:
    "高解像度ビデオ出力を備えたシステムには、グラフィカル インストーラーを使用してください。このバージョンでは、ローカル管理用のグラフィカル デスクトップ環境が実行されます。",

  ngfw_no_complete_subscription:
    'アプライアンスには完全なサブスクリプションがありません。 高度なセキュリティおよび接続機能が無効になり、ETM ダッシュボード管理機能が制限されます。 定期購入を割り当てるか、<a href="{0}cart/" target="_blank">ここをクリック</a>して定期購入を購入します。',

  ngfw_ova_description:
    'ESX バージョン 6.5 ビルド 3 以降が必要です。 インストールとセットアップの手順については、<a href="https://wiki.untangle.com/index.php/Untangle_Virtual_Appliance_on_VMware" target="_blank">NG Firewall for VMware セットアップ ガイド</a>を参照してください。',
  ngfw_serial_description: 'シリアル コンソールを備えたシステムにはシリアル コンソール インストーラーを使用します。これには、Q4、Q8、および Q8W ハードウェア アプライアンスが含まれます。',
  ngfw_templates: 'NGFW テンプレート',
  nmap_port_scan: '選択したホストで NMAP スキャンを実行しようとしています。 スキャンが完了すると、結果が表示されます。',
  no_active_accounts: 'このユーザーにはアクティブなアカウントがありません。',
  no_appliance_found: 'アプライアンスが見つかりません',
  no_appliances: '家電製品なし',
  no_appliances_assigned: 'アプライアンスが割り当てられていません',
  no_backups_available:
    '利用可能なバックアップはありません。<a target="_blank" href="https://wiki.untangle.com/index.php/Configuration_Backup">構成バックアップ</a>を参照してください。',
  no_compatible_appliances_available: '対応するアプライアンスがありません。',
  no_compatible_mfw_appliances_available_for_policy: '  {policy} ポリシーを受信するには、アプライアンスにサブスクリプションがあり、ソフトウェア バージョンが {version} 以上である必要があります。',
  no_complete_license_on_master: '  復元に失敗しました。 マスター アプライアンスには完全なライセンスがありません',
  no_conditions_set: '条件が設定されていません',
  no_data: 'データなし',
  no_endpoint_security:
    'エンドポイント セキュリティ ソフトウェアが検出されませんでした。 Endpoint Security ソフトウェアでの Arista Edge Threat Management の使用の詳細については、<a href="https://support.edge.arista.com/hc/en-us/articles/360012766294-Managing-hosts-in-Command-Center" ターゲットをご覧ください。 ="_blank">こちら</a>。',
  no_headers_set: 'ヘッダーが設定されていません',
  no_host_selected: '表示するホストを選択してください',
  no_info_billing_info_tab: 'ファイルに支払いの詳細がありません。 最初の注文後、ここでお支払いの詳細を更新できます。',
  no_item_selected: 'リストから少なくとも 1 つの項目を選択してください',
  no_label: 'ラベルなし',
  no_mfw_backups_available: '利用可能なバックアップはありません。',
  no_name: 'ノーネーム',
  no_paypal_found: '有効な PayPal 請求契約が見つかりません。 下のボタンを使用して作成します。',
  no_product_selected: 'クーポンを生成するには製品を選択する必要があります',
  no_provider:
    'Endpoint Security ソリューションと統合してホスト管理を拡張し、追加情報を確認します。 Endpoint Security ソフトウェアでの Arista Edge Threat Management の使用の詳細については、<a href="https://support.edge.arista.com/hc/en-us/articles/360012766294-Managing-hosts-in-Command-Center" ターゲットをご覧ください。 ="_blank">こちら</a>。',
  no_record_found: '探しているレコードは見つかりませんでした。 削除された可能性があります。',
  no_results_found: '結果が見つかりません',
  no_saml_test_results_found: 'SAML テスト結果が見つかりませんでした。 SAML 設定を確認してください。',
  no_subscription_found: 'サブスクリプションが見つかりません',
  no_terms: '有効なクレジット契約が見つかりません。 規約を適用するには、Arista Edge Threat Management Sales にお問い合わせください。',
  no_uid_access: 'ユーザーはこのアプライアンスにアクセスできません',
  no_zuora_account_for_customer: '伝票の作成に失敗しました。 支払い方法が定義されていません。 営業にご連絡ください。',
  non_complete_appliance_string: '同期する前に、マスター アプライアンスを完全ライセンスにアップグレードする必要があります。',
  none_entered: '何も入力されていません',
  nonprofit: '非営利',
  nonprofit_complete: '非営利 - 完了',
  not_allowed: 'このアカウントには、要求された操作を実行する権限がありません',
  not_applicable: '該当なし',
  not_assigned: '割り当てられていない',
  not_available: '利用不可',
  not_connected_to_cmd: 'アプライアンスはオフラインです',
  not_enough_terms: 'この購入には十分な条件クレジットがありません。 別の支払い方法を選択してください。',
  not_exempt: '免除されない',
  not_found: '見つかりません',
  not_on_line: 'オフライン - 構成できません',
  not_renewing: '更新しない',
  not_synced: '同期されていません',
  notes: 'ノート',
  notification_log: '通知ログ',
  notification_profile: '通知プロファイル',
  notification_profile_create_error: '通知プロファイルの作成に失敗しました。',
  notification_profile_create_success: '新しい通知プロファイルが正常に作成されました。 変更が反映されるまでに最大 20 分かかる場合があります。',
  notification_profile_description: '通知プロファイルの説明',
  notification_profile_edit_error: '通知プロファイルの編集に失敗しました。',
  notification_profile_edit_success: '通知プロファイルが正常に編集されました。 変更が反映されるまでに最大 20 分かかる場合があります。',
  notification_profile_emails: '通知プロファイルは、組織のユーザーである電子メールに対してのみ作成できます。',
  notification_profiles: '通知プロファイル',
  notification_profiles_select_error: '1 つ以上の通知プロファイルを選択します',
  notification_profiles_select_text: 'このアラートの通知プロファイルを選択します。',
  notifications: '通知',
  now: '今',
  now_available: '現在利用可能です',
  nps_dislike: 'まったくありそうもない',
  nps_like: '非常に可能性が高い',
  nps_title: 'Arista Edge Threat Management を友人や同僚に勧める可能性はどのくらいですか?',
  number: '番号',
  number_of_appliances: 'アプライアンスの数',
  number_of_attempts: '試行回数',
  oauth_save_failed: 'OAUTH設定の更新に失敗しました',
  oauth_save_success: 'OAUTH設定が正常に更新されました',
  oauth2: 'OAuth2 / OpenID',
  oauth2_config: 'OAuth2 / OpenID 設定',
  oauth2_sign_in_redirects: 'OAuth2 プロバイダーでは、アプリケーションのサインイン リダイレクト URI が必要な場合があります。次の URI を許可する必要があります。',
  oauth2_test_results: 'OAuth2 テスト結果の表示',
  oauth2_unconfigured: 'OAuth2 - 構成されている場合にのみ使用可能です。',
  object_description: 'オブジェクトの説明',
  object_name: 'オブジェクト名',
  objects_description: 'ルールと条件で使用するオブジェクトを作成および管理する',
  oem_ng_firewall_complete: 'OEM NG ファイアウォール - 完了',
  online_firewalls: 'オンラインファイアウォール:',
  openvpn: 'OpenVPN',
  operating_system: 'オペレーティング·システム',
  operator: 'オペレーター',
  option_ach: 'ACH: {0}',
  option_credit_card: 'クレジット カード: {0}',
  option_paypal: 'PayPal アカウントのメールアドレス: {0}',
  option_terms: '条項。 利用可能なクレジット: {0}',
  or: 'または',
  organization_config_delete_failed: 'SSO 組織構成の削除に失敗しました',
  organization_config_delete_success: 'SSO 組織構成が正常に削除されました',
  organization_info: '組織情報',
  organization_login: '組織ログイン',
  organization_login_type: '組織のログインタイプ',
  organization_login_unconfigured: '組織ログイン - 構成されている場合にのみ使用可能です。',
  organization_name: '組織名',
  organization_requires_mfa: '所属する組織には 2 要素認証が必要です',
  organization_save_failed: 'SSO 組織設定の保存に失敗しました',
  organization_save_success: '組織の SSO 設定が正常に更新されました',
  organization_sso_login: '組織のSSOログイン',
  organizations: '組織',
  orgtype_title: 'あなたの組織を最もよく表しているものはどれですか?',
  other_downloads: 'その他のダウンロード',
  out_of_sync: '同期が取れていない',
  overview: '概要',
  owned_by_redeemer: 'リディーマーが所有',
  owner_location: '所有者の所在地',
  page_title_app: '{app} ポリシー',
  pager_duty: 'ポケベルの任務',
  pagerduty_configuration: 'PagerDuty の構成',
  pagerduty_info:
    'このテンプレートは PagerDuty Events API V2 用です。 詳細については、<a href="https://v2.developer.pagerduty.com/docs/send-an-event-events-api-v2" target="_blank">ここをクリックしてください。</a>',
  pair_device: 'デバイスをペアリングする',
  partner_city_lat_long: '緯度と経度を調べるには、都市と国を入力する必要があります',
  partner_dashboard: 'パートナーダッシュボード',
  partner_email_vars:
    '<h3>以下のフィールドでは次の変数を使用できます:</h3><ul>' +
    '<li><strong>%account.company%</strong> - アカウントの会社名</li>' +
    '<li><strong>%account.name%</strong> - アカウントの所有者としての名前</li>' +
    '<li><strong>%account.email%</strong> - アカウントの所有者としてのメール アドレス</li>' +
    '<li><strong>%acceptLink%</strong> - 招待を受け入れるリンク</li>',
  partner_geocode_failed:
    "住所をジオコーディングできませんでした。緯度と経度を手動で入力してください。 緯度と経度を取得するには、Google マップで現在地を右クリックし、[現在地] を選択すると、緯度と経度の形式 (緯度、経度): 37.374427、-122.029531 で一覧表示されます。",
  partner_information: 'パートナー情報',
  partner_information_message:
    'すべての情報は、ETM ダッシュボード <a href="{0}partners/find-a-partner" target="_blank">パートナーの検索マップ</a>に表示するためにのみ使用されます。',
  partner_information_save_failed: 'パートナー情報の更新に失敗しました。',
  partner_information_save_success: 'パートナー情報が正常に更新されました。',
  partner_portal: 'パートナーポータル',
  partner_program: 'パートナープログラム',
  partner_program_text: '大きなメリットを享受し、すべてのクライアントの管理を簡素化',
  partner_sales:
    '過去 12 か月間、貴社の売上は {total_sales} で、貴社は <b>{partner_level}</b> レベルに属します。これには <b style="font-size: 18px">{partner_percent}%< が含まれます /b> ソフトウェアの購入をオフにします。',
  partner_sales_no_level:
    '過去 12 か月間の売上は {total_sales} で、ソフトウェアの購入額は <b style="font-size: 18px">{partner_percent}%</b> オフになりました。',
  password_confirm: 'パスワードを認証する',
  password_update_success: 'あなたの情報は正常に更新されました。 セキュリティのため、新しい認証情報を使用して再度ログインしてください。',
  password_updated: 'パスワードは正常に更新されました。',
  past_reboot_time: '過去の再起動時刻をスケジュールすることはできません',
  payload: 'ペイロード',
  payload_vars:
    '<h3>以下のフィールドでは次の変数を使用できます:</h3><ul>' +
    '<li><strong>%event%</strong> - エンベロープ情報を含むイベント オブジェクト全体の JSON 本文</li>' +
    '<li><strong>%event.HTML%</strong> - HTML に変換されたエンベロープ情報を含むイベント オブジェクト全体の本文</li>' +
    '<li><strong>%eventstring%</strong> - エスケープされた JSON 文字列としてエンコードされたエンベロープ情報を含むイベント オブジェクト全体の本文</li>' +
    '<li><strong>%event.message%</strong> - イベントの概要を含むイベント メッセージ文字列</li>' +
    '<li><strong>%event.body%</strong> - エンベロープ情報を除く JSON イベント ペイロード</li>' +
    '<li><strong>%event.bodyHTML%</strong> - HTML に変換されたエンベロープ情報を除くイベント ペイロード</li>' +
    '<li><strong>%event.bodystring%</strong> - エスケープされた JSON 文字列としてエンコードされた、エンベロープ情報を除くイベント ペイロード</li>' +
    '<li><strong>%eventsource%</strong> - イベント ソース - イベントがアプライアンスによって生成された場合は、アプライアンスの UID、それ以外の場合はアカウント ID</li>' +
    '<li><strong>%eventsource.IP%</strong> - イベントの受信元の IP アドレス</li>' +
    '<li><strong>%event.type%</strong> - イベントの種類 (アラート、監査など)</li>' +
    '<li><strong>%eventsource.label%</strong> - ETM ダッシュボードでアプライアンスに与えられたラベル</li></ul>',
  payment_declined: 'お支払い方法が拒否されました。 別の支払い方法を試すか、クレジット カード会社に直接お問い合わせください。',
  payment_include_credit_balance: '  (利用可能なクレジット残高を使用した後)',
  payment_info: '支払い情報',
  payment_info_default_payment_method: '現在の支払い方法',
  payment_info_outstanding_balance: '残高',
  payment_info_tax_number: '税/付加価値税番号',
  payment_method_declined: 'お支払い方法が拒否されました。 決済プロバイダーに直接お問い合わせください。',
  payment_method_info: 'お支払い方法について',
  paypal: 'ペイパル',
  paypal_method: 'PayPal アカウントのメールアドレス:',
  pending: '保留中',
  pending_verification: '検証保留中',
  perform: '実行する',
  permitted_appliances: '許可された機器',
  phish_blocker: 'フィッシングブロッカー',
  phone: '電話',
  phone_number: '電話番号',
  platform: 'プラットホーム',
  platinum_partner: 'プラチナパートナー',
  please_configure_your_condition: '条件を設定してください',
  please_contact_support: 'サポートまでご連絡ください。',
  please_enter_message: 'ホストに送信するメッセージを入力してください:',
  please_provide_additional_info: 'この追加情報をサポート チームに提供してください',
  please_refresh_browser: 'ブラウザを更新して、もう一度お試しください。',
  please_select_a_source_appliance: 'ソース アプライアンスを選択してください',
  please_select_appliance: 'アプライアンスを選択してください',
  please_select_target_appliances: 'ターゲット アプライアンスを選択してください',
  please_wait: 'お待ちください ...',
  policies_action_policy_page: '{policy-page} | {action} {policy-page}',
  policies_category_type: '{category} | {type}',
  policies_description: 'ポリシーを作成および管理して、セキュリティ アプライアンスの展開全体で一貫した構成を維持します。',
  policy_analytics: 'ポリシー分析',
  policy_cloned_failure: 'ポリシーの複製に失敗しました',
  policy_cloned_success: 'ポリシーが正常に複製されました',
  policy_conditions_info: 'このポリシーがいつ適用されるかを決定する条件',
  policy_conditions_validation_failure: '少なくとも 1 つの条件が無効です。保存する前に修正してください。',
  policy_create_failure: 'アプライアンスから新しいポリシーを作成できませんでした。 ポリシー マネージャー アプリケーションとこのアプリケーションがアプライアンスにインストールされていることを確認してください。',
  policy_create_success: 'アプライアンスから新しいポリシーが正常に作成されました。',
  policy_delete_failure: '選択したポリシーを削除できませんでした。',
  policy_delete_success: '選択したポリシーは正常に削除されました。',
  policy_description: 'ポリシーの説明',
  policy_group_dependency: '他のポリシーが依存しているため削除できません。',
  policy_manager_alert_text: 'この機能を利用するには、Policy Manager をアプライアンスにインストールする必要があります。',
  policy_name: 'ポリシー名',
  policy_name_validation: 'ポリシー名は空であってはならず、100 文字を超えてはいけません',
  policy_objects: 'ポリシーオブジェクト',
  policy_order: 'ポリシー命令',
  policy_push_initiated: 'ポリシーのプッシュが開始されました。',
  policy_syncing_initiated: 'ポリシーの同期が開始されました',
  policy_update_failure: '選択したポリシーを更新できませんでした。',
  policy_update_success: '選択したポリシーが正常に更新されました。',
  port_forward_description: 'ポート転送ルールを使用して、宛先アドレスを変換して受信トラフィックをリダイレクトします。',
  port_number: 'ポート番号',
  port_scan: 'ポートスキャン',
  post: '役職',
  postal_zip_code: '郵便番号',
  price_with_discount: '割引価格',
  product: '製品',
  product_installed: 'この製品はアプライアンスにすでにインストールされています。',
  product_line: '生産ライン',
  product_name: '商品名',
  product_upgrade_invalid: 'アップグレード先の製品が無効です',
  professional: '専門/技術サービス',
  profile_not_found: 'プロフィールが見つかりません',
  protect: '守る',
  provision: '規定',
  provision_appliance: 'アプライアンスのプロビジョニング',
  public_cloud_deployments: 'パブリッククラウドの導入',
  public_sector_complete: '公共部門 - 完了',
  purchase_order_number: '発注番号',
  push_appliance_wan_policies: 'アプライアンスの WAN ポリシーをプッシュする',
  push_policy: 'プッシュポリシー',
  push_template: 'プッシュテンプレート',
  qualifying_institution: '資格のある機関',
  quantity: '量',
  quarantined: '隔離された',
  queued: 'キューに入れられました',
  quick_filter_text: '{1} 件中 {0} 件のエントリを表示中',
  quota_usage: '割り当て/使用量',
  real_estate: '不動産',
  reboot_info: 'アプライアンスを再起動すると、スケジュールされた再起動は失われます',
  reboot_now: '今すぐ再起動してください',
  reboot_now_successful: 'アプライアンスが再起動中です。オンラインに戻るまで機能は利用できない可能性があります',
  reboot_options: '再起動オプション',
  reboot_scheduled: 'スケジュールされた再起動',
  received: '受け取った',
  recent_hosts: '最近のホスト',
  recent_hosts_info: '過去 12 時間以内にアクティブだったホストのリスト。 ホストをクリックすると詳細が表示されます',
  recipient_email_addresses: '受信者のメールアドレスのCSVリスト',
  recommendation: 'おすすめ',
  redeem: '償還',
  redeem_voucher: 'バウチャーを引き換える',
  redeem_voucher_failure: 'バウチャーを引き換えることに失敗しました。',
  redeem_voucher_success: 'バウチャーは正常に引き換えられました',
  redeemed_by: '償還者',
  register_now: '今すぐ登録',
  registration_failed: '登録に失敗しました。',
  registration_success: '登録完了',
  registration_success_simple: 'アカウントが正常に作成されました。 資格情報を使用してログインしてください。',
  registration_success_with_verification: 'アカウントは正常に作成されましたが、確認する必要があります。 セットアップを完了するための手順が記載された電子メールが、指定されたアドレスに届きます。',
  reinstate: '復元する',
  reinstate_popup_message: 'この定期購入を再開するための費用は <strong>{renewCost}</strong> です。<br/>現在の残高は <strong>{balance}</strong> です。 <br/>サブスクリプションは期間終了日の時点で復元され、料金が請求されます。 <br/>この期限切れのサブスクリプションを復元してもよろしいですか?',
  reinstate_subscription: 'サブスクリプションを再開する',
  release_candidate: 'リリース候補版',
  release_notes:
    '<a target="_blank" href="https://support.edge.arista.com/hc/en-us/articles/360050838353">リリースノート</a>',
  religious: '宗教的',
  remove_ach: 'ACHの削除',
  remove_ach_confirm: '選択したエントリをアカウントから削除してもよろしいですか?',
  remove_appliance: 'アプライアンスの削除',
  remove_appliance_error: '選択したアプライアンスの削除中にエラーが発生しました',
  remove_appliance_failure: 'ネットワークからアプライアンスを削除できませんでした。',
  remove_appliance_success: '選択したアプライアンスが正常に削除されました',
  remove_appliance_totp: 'アプライアンスの TOTP を削除する',
  remove_appliances: 'アプライアンスの削除',
  remove_confirm: '選択した項目を削除してもよろしいですか?',
  remove_credit_card: 'クレジットカードを削除する',
  remove_network_appliance_success: 'アプライアンスがネットワークから正常に削除されました。',
  remove_sub_failed: 'サブスクリプションを更新できませんでした。変更は加えられていません。',
  remove_sub_success: 'サブスクリプションは正常に割り当て解除されました。',
  remove_task: 'タスクの削除',
  remove_unused_condition: '未使用状態を削除する',
  remove_unused_condition_group: '未使用の条件グループを削除します',
  remove_unused_object: '未使用のオブジェクトを削除する',
  remove_unused_object_group: '未使用のオブジェクトグループを削除する',
  remove_unused_policy: '使用されていないポリシーを削除する',
  remove_unused_rule: '使用されていないルールを削除する',
  remove_unused_template: '使用されていないテンプレートを削除する',
  remove_user_fail: 'ユーザーを削除できません。',
  remove_user_success: 'ユーザーは正常に削除されました。',
  remove_users: 'ユーザーの削除',
  renew_license: 'ライセンスを更新する',
  renew_now: '今すぐ更新',
  renew_popup_message: 'この定期購入を更新する費用は <strong>{renewCost}</strong> です。<br/>現在の残高は <strong>{balance}</strong> です。 <br/>サブスクリプションは期間終了日に更新され、料金が発生します。 <br/>このサブスクリプションを更新してもよろしいですか?',
  renew_sub_failed: '選択したサブスクリプションを更新できません。 支払い情報を確認して再試行するか、詳細について Arista Edge Threat Management セールスにお問い合わせください。',
  renew_sub_success: '選択したサブスクリプションは正常に更新されました。',
  renew_subscription: 'サブスクリプションを更新する',
  renewal_date: '更新日',
  renewal_period: '更新期間',
  renewal_price: 'リニューアル価格',
  renewal_quote: '更新見積書',
  renewal_quote_failed: '選択したサブスクリプションの更新見積を取得できません。詳細については、Arista Edge Threat Management セールスにお問い合わせください。',
  renewal_quote_success: '見積書を表示する準備ができました。[見積書を表示] ボタンを選択すると、別のタブまたはウィンドウで見積書が開きます。',
  renewal_quote_view_quote: '見積書を見る',
  reports_audit_history: '監査履歴',
  request_body: 'リクエストボディ',
  request_copy_failed: 'リクエストは失敗しました。 詳細については、Arista Edge Threat Management サポートにお問い合わせください。',
  request_copy_success: 'ご要望を受け付けました。 要求された情報を記載した電子メールがアカウント所有者に送信されます。',
  request_electronic_account_record: '電子口座記録のリクエスト',
  request_tax_exempt_status: '免税ステータスのリクエスト',
  requests: 'リクエスト',
  require_all_users_two_factor: 'すべてのユーザーに 2 要素認証の使用を要求する',
  requires_security_license: '{0} にはセキュリティ エディションのライセンスが必要です',
  reseller: 'リセラー',
  resend_invite: '招待を再送信する',
  resend_invite_success: '招待は選択したユーザーに正常に再送信されました。',
  resend_verification_email: '確認メールを再送',
  resent_invite_fail: '選択したユーザーに招待を再送信できませんでした',
  reset: 'リセット',
  reset_email_template_fail: '電子メール テンプレートのリセットでエラーが発生しました',
  reset_email_template_success: '電子メールテンプレートが正常にリセットされました',
  reset_password: 'パスワードを再設定する',
  reset_password_failed: 'パスワードのリセットに失敗しました。',
  reset_password_msg: '以下にメールアドレスを入力してください。 パスワードをリセットするためのリンクが届きます。',
  reset_password_success: 'この電子メール アドレスに関連付けられたアカウントが存在する場合は、パスワードをリセットするための詳しい手順が記載された電子メールが送信されます。',
  reset_status: 'リセットは {syncTime} に開始されました。 ステータスの表示',
  reset_to_factory: '既定値にリセット',
  resolve: '解決する',
  resources: 'リソース',
  restore_appliance_from_backup: 'バックアップからアプライアンスを復元する',
  restore_called: 'バックアップ復元コマンドが送信されました',
  restore_failure: '  アプライアンスが正しく復元されませんでした。 再試行する前に、すべてのアプライアンスがオンラインであることを確認してください',
  restore_instructions: 'この操作では、選択したアプライアンスが表示されたバックアップとともに復元されます。{settingsExceptions} 復元するすべてのアプライアンスがオンラインであることを確認してください。 続行してもよろしいですか?',
  restore_instructions_exceptions: '  {settingsExceptions} を除くすべての設定が復元されます。',
  restore_success: 'アプライアンスの復元が正常に開始されました',
  restore_warning: '<strong>インターフェイス</strong>を復元すると、ネットワーク接続の問題が発生する可能性があります。 <br /><strong>アカウント</strong>を復元すると、管理者にアクセスできなくなる可能性があります。',
  retail: '小売り',
  retry: 'リトライ',
  revert: '元に戻す',
  review: 'レビュー',
  role: '役割',
  role_admin: '管理者 - アクセスなし',
  role_admin_account_full: '管理者 - フルアクセス',
  role_admin_account_read: '管理者 - 読み取りアクセス',
  role_appliance_account_full: 'アプライアンス管理者 - フルアクセス',
  role_appliance_account_read: 'アプライアンス管理者 - 読み取りアクセス',
  role_primary: '主要な',
  role_user: 'ユーザー - アクセスなし',
  role_user_account_full: 'ユーザー - フルアクセス',
  role_user_account_read: 'ユーザー - 読み取りアクセス',
  routing_key: 'ルーティングキー',
  rule_cloned_failure: 'ルールの複製に失敗しました',
  rule_cloned_success: 'ルールが正常に複製されました',
  rule_description: 'ルールの説明',
  rule_name: 'ルール名',
  rule_not_found: 'ルールが見つかりません',
  rules_description: 'ポリシーで使用するルールを作成および管理する',
  rules_order_description: '以下のルールを希望の順序に並べ替えることができます。 これにより、アプライアンスに適用される優先順位が決まります。',
  rules_reset_initiating: 'ネットワーク内のすべてのアプライアンスに対してルールのリセットが開始されます。リセット要求のステータスについては監査履歴を確認してください。',
  rules_sync_failed: '同期ルールが失敗しました',
  rules_sync_initiating: 'ネットワーク内のすべてのアプライアンスに対してルールの同期が開始されます。 監査履歴で同期のステータスを確認してください。',
  run_analytics_error: '分析実行中のエラー',
  run_analytics_sent_successfully: 'ポリシー分析プロセスが開始されました。 プロセスが完了したらグリッドを更新してデータを更新します',
  run_analytics_success: '分析が完了しました',
  same_shipping_address: '同じ配送先住所',
  saml: 'SAML',
  saml_config: 'SAML設定',
  saml_save_failed: 'SAML 設定の更新に失敗しました',
  saml_save_success: 'SAML設定が正常に更新されました',
  saml_test_results: 'SAML テスト結果',
  saml_unconfigured: 'SAML - 構成されている場合にのみ使用可能です。',
  sample: 'サンプル',
  save_appliance_setting: 'アプライアンスの設定を保存する',
  save_changes: '変更内容を保存',
  save_condition: '保存条件',
  save_failed: 'アカウント設定を更新できませんでした。',
  save_oauth: 'OAuth2の保存',
  save_policy_changes: 'ポリシーの変更を保存する',
  save_policy_changes_text: 'ポリシーが変更されました。 変更を保存しますか?',
  save_rule: '保存ルール',
  save_rule_changes: 'ルールの変更を保存',
  save_rule_changes_text: 'ルールが変更されました。変更を保存しますか?',
  save_saml: 'SAMLの保存',
  save_vat_number: '税/VAT 番号を保存する',
  saving_ach_payment: 'ACH 支払いの保存',
  saving_credit_card: 'クレジットカードの節約',
  saving_policy_template: 'ポリシーテンプレートの保存',
  saving_settings_dialog: '以前の設定はまだ保存されています。 再試行してください。',
  saving_user_permissions: 'ユーザー権限の保存',
  savings: '貯蓄',
  scan_duration: 'スキャン期間',
  scan_qrcode: '<strong>Untangle Go</strong> アプリでコードをスキャンしてください。',
  scan_type: 'スキャンの種類',
  school: '学校',
  sdn: 'ソフトウェアデファインド ネットワーク',
  search: '検索',
  seats_5: '最大5個',
  seats_12: '最大12個',
  seats_25: '25まで',
  seats_50: '50まで',
  seats_75: '75まで',
  seats_100: '最大100件',
  seats_150: '最大150',
  seats_250: '最大250',
  seats_500: '最大500',
  seats_1000: '最大1000',
  seats_1000000: '無制限',
  seats_home: '家',
  seats_unlimited: '無制限',
  security_code_not_verified: 'セキュリティコードを確認できませんでした。 再入力してみるか、決済プロバイダーに直接お問い合わせください。',
  security_description: 'セキュリティ ルールを使用してネットワーク セグメント間のトラフィック フローをフィルタリングする',
  security_id: 'セキュリティID',
  security_license_not_found: 'このアプライアンスにはセキュリティ ライセンスがありません。 ほとんどのサービスは、セキュリティ エディション ライセンスでのみ利用できます。',
  select_appliance: 'アプライアンスの選択',
  select_appliances_restore_instructions: '復元するアプライアンスを 1 つ以上選択してください',
  select_appliances_sync_instructions: 'テンプレートをプッシュするアプライアンスを選択します',
  select_appliances_to_restore: '復元するアプライアンスの選択',
  select_appliances_to_sync: 'マスター テンプレートと同期するアプライアンスの選択',
  select_applications: '1 つ以上のアプリケーションを選択してください',
  select_conditions: '条件の選択',
  select_delete_items: '削除する項目を選択してください',
  select_existing_conditions: '既存の条件を選択してください',
  select_existing_rules: '既存のルールを選択',
  select_firewall: 'ファイアウォールを選択',
  select_group: 'グループの選択',
  select_host: 'ホストの選択',
  select_master_appliance: 'マスター アプライアンスとして使用するアプライアンスを選択します。 ライセンスを取得したアプライアンスのみがサポートされます。',
  select_network: 'ネットワークの選択',
  select_new_product: '新製品を選択',
  select_object: 'オブジェクトの選択',
  select_one_appliance: 'ネットワークを作成するには、少なくとも 1 つのアプライアンスを選択してください',
  select_organization: '組織を選択してください',
  select_payment_method: '支払い方法を選択してください',
  select_product: 'クーポンを生成する製品を選択してください:',
  select_rules: 'ルールの選択',
  select_rules_order: 'ルールの順序を選択してください',
  select_template_appliance: 'テンプレート アプライアンスを選択します',
  send_invitation: '招待状を送信する',
  send_invitation_to_users: 'ユーザーに招待状を送信する',
  send_message: 'メッセージを送る',
  sender_email_address: '送信者のメールアドレス',
  sent: '送信済み',
  serial_installer: 'ISO インストーラー (シリアル コンソール)',
  serial_number: 'シリアルナンバー',
  server_geoip: 'サーバー地域IP',
  services_reset_warning: 'このサービスはデフォルト設定にリセットされます。 <br /> 続けますか？',
  session_expired: 'セッションの有効期限が切れました。 もう一度ログインしてください。',
  set_account_settings: 'アカウント設定を行う',
  set_appliance_policy: 'アプライアンスポリシーの設定',
  set_as_default: 'デフォルトとして設定',
  set_description: 'セットの説明',
  set_despription_message: 'サブスクリプションの説明を入力してください',
  set_label: 'ラベルの設定',
  set_label_fail: 'ラベルを変更できませんでした。このアプライアンスへの完全なアクセス権があることを確認してください',
  set_label_success: 'アプライアンスのラベルが正常に更新されました',
  set_schedule: 'スケジュールを設定する',
  set_wifi_region: 'Wi-Fi地域を設定する',
  set_wifi_region_fail: 'Wi-Fi地域の変更に失敗しました',
  set_wifi_region_success: 'アプライアンスの Wi-Fi 領域が正常に更新されました',
  settings_rolledback: '設定がロールバックされました',
  settings_sso: 'シングル・サインオン',
  settings_sso_any_provider: '任意のプロバイダー',
  settings_sso_condition: 'アカウントの安全性を確保するため、このオプションはシングル サインオンでログインした場合にのみ有効になります。',
  settings_sso_enabled_condition: 'アカウントの安全性を確保するため、ログインに使用した SSO のみを選択できます',
  settings_sso_google: 'グーグル',
  settings_sso_microsoft: 'マイクロソフト',
  settings_sso_not_required: '不要',
  settings_sso_requirement: 'シングルサインオン要件',
  setup: '設定',
  setup_admin_email: '管理者のメールアドレス',
  setup_admin_password: '管理者のパスワード',
  setup_admin_username: '管理者ユーザー名',
  setup_agreement: 'Arista Edge Threat Management の {0} に同意します',
  setup_appliance_is_master: 'このアプライアンスはすでに既存のテンプレートのマスターとして設定されているため、テンプレートのプッシュは実行されませんでした',
  setup_configure: 'アプライアンスのセットアップ',
  setup_eula: 'エンドユーザーライセンス契約',
  setup_incomplete: 'システムはアプライアンスに接続できず、構成を完了できませんでした。エラー {msg}。接続を再試行するタスクが作成されました。',
  setup_install_type: 'インストールタイプ',
  setup_ngfw_config: 'このアプライアンスの構成情報を入力してください',
  setup_time_zone: 'タイムゾーン',
  severity: '重大度',
  shaping_description: 'シェーピング ルールを使用して、特定のトラフィック フローのネットワーク パフォーマンスを最適化する',
  share_sub_description: '別のユーザーがこのサブスクリプションを表示および割り当て/割り当て解除できるようにします。',
  share_sub_failed: 'サブスクリプションを共有できませんでした。',
  share_sub_success: 'サブスクリプションは正常に共有されました。',
  share_sub_with_self: 'サブスクリプションを自分自身に割り当てることはできません。',
  shared_subnets: '共有サブネット',
  shipping_address: 'お届け先の住所',
  shipping_name: '配送先名',
  show_autogenerated: '自動生成されたものを表示',
  show_more: 'もっと見せる...',
  show_on_partner_map: 'パートナーマップに表示',
  show_qr_code: 'QRコードを表示',
  show_rules: 'アプライアンスのルールを表示する',
  sign_out: 'サインアウト',
  signing_certificate_if_different: '署名証明書 (暗号化証明書と異なる場合)',
  signing_out: 'サインアウトする',
  silver_partner: 'シルバーパートナー',
  since: '以来',
  single_reboot_time_restriction: '許可される再起動時間は 1 つだけです - 新しい時間を設定するには、以前の再起動時間を削除してください',
  size: 'サイズ',
  sku: 'SKU',
  slack: 'スラック',
  slack_configuration: 'Slack の設定',
  slack_info:
    'このテンプレートは Slack Webhook 用です。 詳細については、<a href="https://api.slack.com/incoming-webhooks" target="_blank">ここをクリック</a>してください。',
  software_downloads: 'ソフトウェアのダウンロード',
  software_it: 'ソフトウェア・IT',
  source_intf_name: '送信元インターフェース名',
  source_reverse_dns: 'ソース逆引きDNS',
  spam_blocker: 'スパムブロッカー',
  specific_lte: '特定の WAN - LTE',
  specific_wan0: '特定の WAN - WAN0',
  specific_wan1: '特定の WAN - WAN1',
  specify_port_list: '単一のポート、ポートのリスト、またはポート範囲を指定します。',
  specify_port_number: 'ポート番号を指定する',
  ssl_cert_dns_name: 'SSL証明書のDNS名',
  ssl_inspector: 'SSLインスペクター',
  sso: 'SSO',
  sso_existing_organization: '保存しようとしている組織はすでに存在します',
  sso_required: 'SSOが必要です',
  sso_save_failed: 'SSO 設定の更新に失敗しました',
  sso_save_success: 'SSO設定が正常に更新されました',
  sso_type: 'SSO タイプ',
  start_date: '開始日',
  start_time: '始まる時間',
  state_and_local_gov: '州および地方自治体',
  step_n_of_3: 'ステップ {n}/3',
  street_address: '住所',
  student: '学生',
  sub_already_assigned: 'このサブスクリプションはすでにこのアプライアンスに割り当てられています',
  sub_charge_value: '新規購読料金',
  sub_not_allowed: 'このサブスクリプションはこのアプライアンスでは許可されていません。',
  sub_not_assignable: 'このサブスクリプションをアプライアンスに割り当てることはできません。',
  sub_owner: '{0} はこのサブスクリプションを管理制御しています',
  sub_redeemer: '{0} はこのサブスクリプションを割り当てたり割り当て解除したりできます',
  submit: '提出する',
  submit_a_request: 'サポートチケットを送信する',
  subnets: 'サブネット',
  subscription: 'サブスクリプション',
  subscription_assigned_expired: 'このアプライアンスに割り当てられたサブスクリプションの有効期限が切れています。 重要なセキュリティ機能が無効になっており、ネットワークが危険にさらされている可能性があります。 | このアプライアンスの 1 つ以上のサブスクリプションの有効期限が切れています。 重要なセキュリティ機能が無効になっており、ネットワークが危険にさらされている可能性があります。',
  subscription_assigned_will_expire_day: 'このアプライアンスに割り当てられたサブスクリプションは 1 日で期限切れになります。 | このアプライアンスの 1 つ以上のサブスクリプションは 1 日以内に期限切れになります。',
  subscription_assigned_will_expire_days: 'このアプライアンスに割り当てられたサブスクリプションは、{0} 日後に期限切れになります。 | このアプライアンスの 1 つ以上のサブスクリプションは {0} 日後に期限切れになります。',
  subscription_details: 'サブスクリプションの詳細',
  subscription_home: '家',
  subscription_name: 'サブスクリプション名',
  subscription_not_toggling: 'サブスクリプション {names} の自動更新期間は過ぎています。 Arista Edge Threat Management Sales にお問い合わせください。更新設定の処理をお手伝いします。',
  subscription_owned_by_redeemer: 'このサブスクリプションは引き換え者が所有します',
  subscription_term: 'サブスクリプション期間:',
  subscription_term_1_month: '毎月',
  subscription_term_12_month: '1年',
  subscription_term_15_month: '15ヶ月',
  subscription_term_24_month: '2年',
  subscription_term_36_month: '3年',
  subscription_term_60_month: '5年',
  subscription_unlimited: '無制限',
  subscription_upgrade: 'サブスクリプションのアップグレード',
  subscription_upgrade_failed: 'サブスクリプションのアップグレードの失敗',
  subscription_upgrade_success: 'サブスクリプションのアップグレードが成功しました',
  subscriptions: '定期購入',
  subscriptions_not_updated: 'サブスクリプションは更新されていません。詳細については、Arista Edge Threat Management セールスにお問い合わせください。',
  subscriptions_not_updated_with_terms: 'デフォルトの支払いとして条件が設定されているアカウントは自動更新を設定できません',
  subtitle: 'ネットワークを使用すると、アプライアンスをグループ化して、集約されたパフォーマンス データを表示し、共有 WAN ルーティング ポリシーをプッシュできます。',
  success_green: '<span style="color:green;">成功</span>',
  suggest_idea: 'アイデアを提案する',
  swap: 'スワップ',
  switch_organization_failed: '組織の切り替えに失敗しました。 これでログアウトされます。 目的の組織に再度ログインしてみてください。',
  switch_organization_no_access: '組織の切り替えに失敗しました。 この組織にはもうアクセスできないようです。 これがエラーであると思われる場合は、組織の管理者に問い合わせてください。',
  switch_to: '切り替える',
  sync: '同期',
  sync_access_rule_warning: '同期ルールはデバイス上の既存のアクセス ルールを上書きします',
  sync_all_new_appliances_already_enabled:
    '「すべての新しいアプライアンスを同期」設定は、一度に 1 つのテンプレートに対してのみ有効にできます。 続行するには、この設定を無効にしてください。',
  sync_cloud_vision_appliances: 'Cloud-Vision アプライアンスの同期',
  sync_failed: 'ターゲット アプライアンスの同期に失敗しました。',
  sync_failed_no_complete_license_on_master: 'ターゲット アプライアンスを同期できません。 マスター アプライアンスには完全なライセンスがありません。',
  sync_failure_formatted_message: '<span style="color:red;">失敗</span> - {error}',
  sync_failure_toast_message: '失敗 - 1 つ以上のアプライアンスが同期に失敗しました。',
  sync_filter_rule_warning: '同期ルールはデバイス上の既存のフィルタ ルールを上書きします',
  sync_frequency: '同期周波数',
  sync_in_progress: '同期が進行中です',
  sync_initiated: '同期が開始されました',
  sync_initiated_target_appliances: 'すべてのターゲット アプライアンスで同期プロセスが開始されました.',
  sync_now: '今すぐ同期',
  sync_now_confirmation: 'この操作により、ターゲット アプライアンス リスト内のアプライアンスが、テンプレート マスターからの構成とポリシーで更新されます (ネットワーク構成を除く)。',
  sync_now_could_not_be_performed: '今すぐ同期を実行できませんでした',
  sync_policy_warning: '同期すると、デバイス上の {policy} 設定が上書きされます。 続行してもよろしいですか?',
  sync_rule_warning: '同期により、デバイス上の既存の {policyType} が上書きされます。',
  sync_rules: '同期ルール',
  sync_rules_to_mfw_appliances: 'ルールを MFW アプライアンスに同期する',
  sync_service_policy: 'サービスポリシーの同期',
  sync_status: '同期ステータス',
  sync_success_toast_message: '<span style="color:green;">成功!</span> アプライアンス {uid} は正常に同期されました。',
  sync_target_with_template: 'ターゲット アプライアンスをテンプレートと同期する',
  sync_vpn_info: 'VPN情報を同期する',
  sync_vpn_settings: 'VPN 設定を同期する',
  sync_wan_rule_warning: '同期ルールは、デバイス上の既存の WAN ルールと WAN ポリシーを上書きします。',
  sync_wan_rules: 'WAN ルールを同期する',
  synced: '同期済み',
  syncing: '同期中',
  system_details: 'システム詳細',
  system_type: 'システムタイプ',
  sysupgrade_failed: 'Sysupgrade の試行が失敗しました',
  target_appliance: 'ターゲットアプライアンス',
  target_appliance_failure_toast_message: '1 つ以上のターゲット アプライアンスが同期できません。 テンプレートを編集し、誤って構成されたアプライアンスを削除します。',
  task: 'タスク',
  task_completed_successfully: 'タスクは正常に完了しました',
  task_id: 'タスクID',
  tasks: 'タスク',
  tasks_list: 'タスクのリスト',
  tasks_remove_failed: '選択したタスクの削除中にエラーが発生しました',
  tasks_remove_successful: '選択したタスクは正常に削除されました',
  tax_exempt_status: '免税ステータス (米国のみ):',
  tax_information: '税金に関する情報',
  tax_vat_number: '税/付加価値税番号。 ここに入力した内容は請求書に記載されます。',
  taxes: '税金',
  technical_difficulties: '残念ながら、技術的な問題が発生しています。後でもう一度試すか、詳細についてお問い合わせください。',
  telecommunications: '電気通信とインターネット',
  template: 'テンプレート',
  template_configuration: 'テンプレートの設定',
  template_master: 'テンプレートマスター',
  template_not_found: 'テンプレートが見つかりません',
  template_type: 'テンプレートの種類',
  templates: 'テンプレート',
  templates_description: 'ポリシーで使用するサービス構成テンプレートを作成および管理する',
  temporary_payment_error: '現時点でお支払いを処理できませんでした。後でもう一度お試しください。問題が解決しない場合は、Arista Edge Threat Management Accounting にお問い合わせください。',
  term: '学期',
  terms: '条項',
  terms_credit_balance: '利用規約のクレジット残高:',
  terms_credit_limit: '利用規約の利用限度額:',
  terms_method: '条項。 利用可能なクレジット:',
  test_general: 'SSO 設定のテスト',
  test_oauth: 'OAuth2設定をテストする',
  test_saml: 'SAML 設定のテスト',
  thank_you: 'ありがとう！',
  the_following_error_occurred: '次のエラーが発生しました:',
  threat_history: '脅威の歴史',
  threat_info: '脅威情報',
  threat_prevention_description: '脅威防御テンプレートを使用して、このポリシーに脅威防御を適用するかどうかを定義します',
  threats: '脅威',
  threats_blocked: 'ブロックされた脅威:',
  throughput_50: '最大50Mbps',
  throughput_100: '最大100Mbps',
  throughput_300: '最大300Mbps',
  throughput_500: '最大500Mbps',
  throughput_unlimited: '無制限',
  time_based_one_time_password: '時間ベースのワンタイムパスワード',
  time_of_day_utc: '時刻 (UTC)',
  time_zone_change_warning: '注 - タイムゾーンの変更は、アプライアンスが再起動されるまで完全には有効になりません。',
  timeout_maximum_error: '非アクティブなログアウト期間は 48 時間を超えることはできません',
  timeout_message: 'タイムアウトは 1 分から 48 時間の間で設定できます',
  timeout_minimum_error: '非アクティブなログアウト期間は少なくとも 1 分である必要があります',
  timeout_section_title: '非アクティブなログアウト期間',
  timestamp: 'タイムスタンプ',
  title: 'ネットワーク',
  to_target_appliances: '宛先: ターゲット アプライアンス:',
  toggle_auto_renew_alert_message:
    '<strong>自動更新をオフにしてもよろしいですか?</strong> <br/><br/>更新の支払いを行わずに自動更新日が過ぎた場合は、新しい注文を行ってネットワーク セキュリティ アプリケーションを実行する必要があります。 ネットワークを保護できなくなります。 <br/><br/>サブスクリプションを自動的に最新の状態に保つには、[いいえ] を押してください。',
  toggle_auto_renew_fail_due_to_default_payment: 'サブスクリプション: {names} の自動更新を切り替えることはできません。 デフォルトのお支払い方法で連続して失敗する回数が多すぎます。 自動更新を有効にするには更新してください。 詳細については、Arista Edge Threat Management セールスにお問い合わせください。',
  toggle_auto_renew_not_allowed: 'サブスクリプション {name}: {reason} の自動更新を切り替えることはできません。 詳細については、Arista Edge Threat Management セールスにお問い合わせください。',
  toggle_auto_renewal: '自動更新の切り替え',
  token: 'トークン',
  token_endpoint_url: 'トークンエンドポイントURL',
  tools: 'ツール',
  top_applications: '上位のアプリケーション',
  top_applications_bandwidth: '上位アプリケーション (帯域幅別) - 過去 30 日間',
  top_blocked_categories_by_request: '上位のブロックされたカテゴリ (リクエストによる)',
  top_blocked_hostnames_by_request: '上位のブロックされたホスト名 (リクエストによる)',
  top_blocked_sites: 'ブロックされている上位のサイト',
  top_blocked_sites_by_request: 'ブロックされている上位のサイト (リクエストに応じて)',
  top_domains: 'トップドメイン',
  top_domains_by_request: 'トップドメイン (リクエストに応じて)',
  top_hostnames: '上位のホスト名',
  top_hostnames_bandwidth: '上位のホスト名 (帯域幅別)',
  top_hosts_last_30: '上位ホスト名 (帯域幅別) - 過去 30 日間',
  total: '合計',
  total_amount_all: 'アカウントの 12 か月分の金額 (すべてのサブスクリプション)',
  total_amount_shared: 'アカウントの 12 か月分の金額 (共有サブスクリプション)',
  total_bandwidth: '総帯域幅',
  total_bandwidth_30days: '合計帯域幅 - 過去 30 日間',
  total_bandwidth_last_30: '合計帯域幅 - 過去 30 日間',
  total_blocked_categories: 'ブロックされたカテゴリの合計数',
  total_blocked_hostnames: 'ブロックされたホスト名の合計',
  total_cost: '総費用',
  total_count: '総数',
  total_download: '合計ダウンロード数',
  total_number_of_policies: 'ポリシーの総数:',
  total_shared: 'アカウントと共有サブスクライブ',
  totp: 'TOTP',
  totp_added_successful: 'TOTP がアプライアンスに正常に追加されました',
  totp_added_unsuccessful: 'TOTP をこのアプライアンスに追加できませんでした',
  totp_removed_successful: 'TOTP はアプライアンスから正常に削除されました',
  totp_removed_unsuccessful: 'TOTP をこのアプライアンスから削除できませんでした',
  transporation: '交通機関',
  tunnel_vpn: 'トンネルVPN',
  two_factor_authentication: '二要素認証',
  two_factor_required: '2要素認証が必要です',
  uid: 'UID',
  uid_or_serial_empty_text: 'UID またはシリアル番号',
  uid_validation: '無効な UID - UID には文字 A ～ F と数字 0 ～ 9 のみを含めることができます。',
  uids: 'ウイズ',
  unable_to_communicate: 'ETM ダッシュボードと通信できません。',
  unable_to_process_payment: '支払いを処理できません',
  unable_to_save: '{0}を保存できません',
  unassign_policies: 'ポリシーの割り当てを解除する',
  unassign_policies_from_appliance: 'アプライアンスからポリシーを解放する',
  unassign_policies_warning: '選択したアプライアンスからすべてのポリシーの割り当てを解除しますか?',
  unassign_sub: '<strong>{0}</strong> からサブスクリプションの割り当てを解除します',
  unassign_sub_failed: 'サブスクリプションの割り当てを解除できませんでした。',
  unassign_sub_success: 'サブスクリプションは正常に割り当て解除されました。',
  unassign_sub_warning: '<strong>警告:</strong> サブスクリプションは <strong>{uid}</strong> から割り当て解除されます。',
  unassigned: '未割り当て',
  unassigning: '割り当ての解除',
  unexpected_error_occurred: '予期しないエラーが発生しました。',
  unique_name_for_rule: 'ルールの一意の名前',
  unique_notification_profile_name: '一意の通知プロファイル名',
  unknown_error: '不明なエラー。',
  unknown_hostname: '不明なホスト名',
  unknown_ip: '不明なIP',
  unknown_mac: '不明なMAC',
  unknown_os: '不明なOS',
  unknown_platform: '不明なプラットフォーム',
  unknown_uids: '不明な UID',
  unknown_version: '不明なバージョン',
  unlicensed_not_eligible: 'ライセンスなし - 対象外',
  uno_application: 'UNOアプリケーション',
  uno_application_groups: 'UNO アプリケーション グループ',
  unshare_sub: 'このサブスクリプションを {0} と共有解除します',
  unshare_sub_failed: 'サブスクリプションの共有を解除できませんでした。',
  unshare_sub_success: 'サブスクリプションは正常に共有解除されました。',
  unshare_sub_warning: '<strong>警告:</strong> 定期購入は <strong>{email}</strong> との共有が解除されます。',
  unsupported_device_type: '非 MFW アプライアンスはサポートされていません。',
  untangle_go: 'モバイルアプリ',
  untangle_go_mobile_app: 'Untangle Go モバイル アプリ',
  untangle_go_text: 'いつでもどこでもネットワークのステータスとアラートを表示します。',
  untangle_go_title: 'アンタングル・ゴー',
  untangle_server_error: 'ご注文の処理中に予期しないエラーが発生しました。 サポートが必要な場合は、Arista Edge Threat Management Accounting (untangle-accounting@arista.com または 1-408-598-4292) までお問い合わせください。',
  untangle_support: 'サポート',
  untangle_support_text: '少しお手伝いが必要ですか? サポートセクションで必要な答えを見つけてください',
  unused_condition: '未使用状態',
  unused_condition_group: '未使用状態グループ',
  unused_object: '未使用のオブジェクト',
  unused_object_group: '未使用のオブジェクトグループ',
  unused_policy: '未使用のポリシー',
  unused_rule: '未使用のルール',
  unused_template: '未使用のテンプレート',
  update_appliance_assignments: 'アプライアンスの割り当ての更新',
  update_appliance_hostname: 'アプライアンスのホスト名を更新する',
  update_appliance_totp: 'アプライアンスの TOTP を更新する',
  update_appliance_wifi_region: 'アプライアンスの Wi-Fi リージョンを更新する',
  update_appliances_confirmation: 'ETM ダッシュボードは、これらのアプライアンスのソフトウェア アップデートを確認します。 利用可能な場合は、アプライアンスが更新されます。 今すぐ更新してもよろしいですか?',
  update_appliances_location_failed: '位置情報の更新に失敗しました',
  update_appliances_location_success: '位置情報が正常に更新されました',
  update_cc_failed: 'クレジット カード情報を保存できませんでした。日付とカード番号が有効であることを確認してください。',
  update_credit_card_confirm: 'クレジット カードは正常に更新されました。',
  update_day_of_week: '曜日',
  update_default_payment_method_no_selection: 'デフォルトとして設定する支払い方法を選択していることを確認してください。',
  update_domainname: 'アプライアンスのドメイン名を更新します -- これにより再起動が発生する可能性もあります',
  update_error: '現時点ではアプライアンスをアップグレードできません',
  update_hostname: 'アプライアンスのホスト名を更新します -- これを変更すると、アプライアンスが再起動する可能性があります',
  update_initiated: 'アップグレードが開始されました',
  update_location: 'アプライアンスの場所を更新する',
  update_message: 'ソフトウェア更新の対象となるアプライアンスを 1 つ以上選択します',
  update_no_datetime: '接続できませんでした',
  update_none_scheduled: '予定なし',
  update_notification_profile_failed: '通知プロファイルの更新に失敗しました',
  update_now: '今すぐアップデート',
  update_options: '更新オプション',
  update_payment_methods: '[マイ アカウント] -> [請求情報] に移動して、支払いの詳細を更新してください。',
  update_picker_info: '自動アップグレードを実行する日時を選択してください。',
  update_scheduled: '現在の週間スケジュール',
  update_software: 'ソフトウェアを更新する',
  update_sub_description: 'アップデートの説明',
  update_sub_description_empty: '少なくとも 1 つのサブスクリプションを選択する必要があります。',
  update_sub_description_failed: '選択したサブスクリプションの説明を更新できません。',
  update_sub_description_success: '選択したサブスクリプションの説明が正常に更新されました。',
  update_tax_exempt_status_failed: '免税リクエストを送信できませんでした。',
  update_tax_exempt_status_success: '免税リクエストを受け取りました - すぐに詳細を記載した電子メールをお送りします。',
  update_tax_vat_number_failed: 'VAT 番号を更新できませんでした。',
  update_tax_vat_number_success: 'VAT 番号は正常に更新されました。',
  update_wifi_region: 'Wi-Fi 地域を更新する',
  updates: 'プライバシーポリシーの更新',
  updates_available: '利用可能なアップデート',
  updating_default: 'デフォルトの支払いを更新しています',
  upgrade_confirm_message: '現在のサブスクリプションはキャンセルされ、新しいサブスクリプションが作成されます。 アップグレードを続行してもよろしいですか?',
  upgrade_day: '日は {0} から {1} の間である必要があります',
  upgrade_discount_value: '割引額',
  upgrade_docker_waf: 'Docker デプロイメントで WAF をアップグレードするには、以下を参照してください。',
  upgrade_failed: 'アップグレードに失敗しました',
  upgrade_hour: '時間は {0} から {1} の間である必要があります',
  upgrade_minute: '分は {0} から {1} の間である必要があります',
  upgrade_no_options_available: '選択したサブスクリプションに利用できるアップグレード オプションはありません。',
  upgrade_price: '<b>アカウントに適用される合計金額: </b>',
  upgrade_price_estimate_no_taxes: '<br/>これらは推定価格であり、税金が含まれていない場合があります。',
  upgrade_product: '製品のアップグレード',
  upgrade_redeemed_voucher_sub_not_allowed: '引き換えられたバウチャー用に作成されたサブスクリプションはアップグレードできません。',
  upgrade_refund_value: '現在の定期購入の払い戻し',
  upgrade_renewal_date: '<b>新しい更新日: </b>',
  upgrade_renewal_price: '<b>更新時に請求される合計金額: </b>',
  upgrade_review_price: '価格の確認',
  upgrade_selected_subs_failed: '選択した 1 つ以上のサブスクリプションをアップグレードできません。 デフォルトの支払い方法が有効であることを確認するか、詳細については Arista Edge Threat Management サポートにお問い合わせください。',
  upgrade_sub_appliance: 'アプライアンスをアップグレードすることはできません。',
  upgrade_sub_cmdc: 'Command Center 製品は完全パッケージにアップグレードできません。 新たに選択してください。',
  upgrade_sub_complete: '完全なパッケージは個別にアップグレードする必要があります。',
  upgrade_sub_complete_exists: 'このサブスクリプションは、完全なパッケージがすでに含まれているサーバーに割り当てられています。 選択を変更してください。',
  upgrade_sub_contact_untangle: '5 年の更新期間を持つ選択された製品は、ここではアップグレードできません。 その他のオプションについては、Arista Edge Threat Management セールスにお問い合わせください。',
  upgrade_sub_default_fail: '選択したサブスクリプションをアップグレードできません。 個別にアップグレードを試みるか、サポートにお問い合わせください。',
  upgrade_sub_demo_bundle: 'デモ バンドルをアップグレードすることはできません。',
  upgrade_sub_denied: 'このサブスクリプションをアップグレードすることはできません。',
  upgrade_sub_diff_seats: '選択したデバイスのライセンス数は異なるため、個別にアップグレードする必要があります。',
  upgrade_sub_diff_servers: '選択した内容は別のサーバーに割り当てられるため、個別にアップグレードする必要があります。',
  upgrade_sub_duplicate: '選択した商品には重複した商品が含まれています。 重複を削除して再試行してください。',
  upgrade_sub_has_payfail: 'あなたのアカウントで最近支払いの失敗が検出されました。 お支払い情報を更新して、もう一度お試しください。',
  upgrade_sub_home_protect: 'Home Protect パッケージは個別にアップグレードする必要があります',
  upgrade_sub_mfw: 'Micro Edge サブスクリプションは個別にアップグレードする必要があります。',
  upgrade_sub_multiple_complete: '選択した複数のサブスクリプションの中に、少なくとも 1 つの完全なパッケージがある場合、アップグレード プロセスを続行することはできません。 すべての完全パッケージの選択を削除して、再試行してください。',
  upgrade_sub_new_term: '<br/>サブスクリプションは新しい期間で始まります。',
  upgrade_sub_nfr: '非売品製品をアップグレードすることはできません。',
  upgrade_sub_no_further: '選択した 1 つ以上の製品はそれ以上アップグレードできません。',
  upgrade_sub_oem: 'OEM パッケージは個別にアップグレードする必要があります。',
  upgrade_sub_seats_assigned: 'デバイスの数をアップグレードするには、サーバーに割り当てられているすべてのサブスクリプションを必ず選択してください。',
  upgrade_sub_together: '選択したサブスクリプションをまとめてアップグレードすることはできません。 別途アップグレードしてください。',
  upgrade_tax_value: '税金の総額',
  upgraded_subscription: 'サブスクリプションをアップグレードしました。',
  upload_rate: 'アップロード',
  upload_total: '合計アップロード',
  ups_error_in_processing: 'この注文の UPS 料金の計算中にエラーが発生しました',
  uri: 'ウリ',
  url_admin_info: 'URL 管理者情報',
  url_info: 'URL情報',
  url_owner_info: 'URL 所有者情報',
  url_tech_info: 'URL 技術情報',
  use_default_settings: 'デフォルト設定を使用する',
  used: '使用済み',
  user_agent: 'ユーザーエージェント',
  user_already_invited: 'ユーザー: {emails} はすでにこの組織を管理しています。',
  user_band_conflict: 'この製品には、アプライアンスの他の製品とは異なるユーザー帯域があります。',
  user_email_address: 'ユーザーのメールアドレス',
  user_emails: 'ユーザーのメールアドレス',
  user_info_endpoint_url: 'ユーザー情報エンドポイント URL',
  user_info_update_failed: '連絡先情報の更新に失敗しました。',
  user_interface: 'ユーザーインターフェース',
  user_invite_fail: 'ユーザーを招待できません。',
  user_invite_success: 'ユーザーの招待は正常に送信されました。',
  user_name: 'ユーザー名',
  user_not_found: 'ユーザーが見つかりません',
  user_not_in_org: 'あなたは退会したい組織のメンバーではありません',
  user_settings_saved_success: 'ユーザー設定が正常に保存されました',
  users: 'ユーザー',
  utilities: '公共事業とエネルギー',
  valid_license: '有効なライセンス',
  vendor: 'ベンダー',
  verification_method: '検証方法',
  verify_appliance_type: 'アプライアンスのタイプを確認してください',
  version: 'バージョン',
  victor_ops: 'ビクターオプス',
  victorops_configuration: 'VictorOps の構成',
  victorops_info:
    'このテンプレートは VictorOps REST エンドポイント用です。 詳細については、<a href="https://help.victorops.com/knowledge-base/victorops-restendpoint-integration/" target="_blank">ここをクリックしてください。</a>',
  view_details: '詳細を見る',
  view_rules: 'ルールの表示',
  virus_blocker: 'ウイルスブロッカー',
  voucher: 'バウチャー',
  voucher_already_redeemed: '入力したバウチャーはすでに引き換えられています。',
  voucher_code_to_redeem: '引き換えたいクーポンコードを入力してください',
  voucher_expired: '入力したクーポンの有効期限が切れています。',
  voucher_missing_organization: 'バウチャーを引き換えるには、対象となる機関名が必要です。',
  vouchers: 'バウチャー',
  vouchers_account_no_purchases: 'アカウントには購入はありません。 クーポン機能を有効にするには、少なくとも 1 つの購入を完了してください。',
  vpn_access: 'VPN アクセス',
  vpn_reset_initiating: 'ネットワーク内のすべてのアプライアンスに対する VPN 設定のリセットを開始しています。このリセットのステータスについては監査履歴を確認してください。',
  vpn_sync_initiating: 'ネットワーク内のすべてのアプライアンスとの VPN 設定の同期を開始します。この同期のステータスについては監査履歴を確認してください。',
  waf: 'ウェブアプリケーションファイアウォール',
  waf_connection_lost: '非アクティブなため、現在の接続が失われました。 新しいものを確立するには、をクリックしてください',
  waf_description: 'Web アプリケーション ファイアウォール (WAF) は、データの侵害や損失につながる可能性のあるインターネットの脅威や悪意のある攻撃から Web アプリケーションを保護するセキュリティ アプライアンスです。 WAF は、Docker コンテナとして展開することも、ISO を使用してソフトウェア アプライアンスとしてインストールすることもできます。',
  waf_docker_description:
    'Docker コンテナは、オンプレミスまたは Amazon AWS、Microsoft Azure、Google Cloud、Oracle Cloud などのパブリック クラウド インスタンスで、Web サーバーまたは Docker を実行できる Linux ディストリビューション上で WAF を直接実行するためのデプロイメント オプションです。 Docker コンテナのデプロイメントについては、<a href="https://support.edge.arista.com/hc/en-us/articles/4410194539159" target="_blank">こちら</a>をご覧ください。',
  waf_iso_description:
    'ISO 導入オプションは、サーバー上の仮想アプライアンスまたはベア メタルとして導入できる Debian ベースのオペレーティング システムのインストールです。 ISO 導入の詳細については、<a href="https://support.edge.arista.com/hc/en-us/articles/4410086155799" target="_blank">こちら</a>をご覧ください。',

  waf_not_supported: 'WAFは現在サポートされていません',
  waf_setup_complete: 'Web アプリケーション ファイアウォールがアカウントに正常に追加されました',
  waf_setup_wizard_text: '<strong>セットアップ</strong><br/>Arista Edge Threat Management をお選びいただきありがとうございます。 [続行] をクリックすると、アプライアンスがアカウントに追加され、構成ウィザードが表示されます。<br/><br/>',
  waf_unable_to_connect: 'Web アプリケーション ファイアウォールに接続できませんでした: <b>{0}</b>',
  wan_balancer: 'WANバランサー',
  wan_failover: 'WANフェイルオーバー',
  wan_link_information: 'WAN リンク情報',
  wan_policies_sync_info_end: '。 ポリシーをデバイスに同期するには、関連付けられた WAN ルールが必要です。',
  wan_policies_sync_info_start: 'で使用する WAN ポリシーを作成する',
  wan_rule_criteria_specified: 'WAN ルールを有効にするには、少なくとも 1 つの基準が指定されている必要があります',
  wan_rule_description: 'WAN ルールを使用して優先リンク経由でトラフィックを転送する',
  web_cache: 'ウェブキャッシュ',
  web_events: 'ウェブイベント',
  web_filter_blocked: 'ブロックされています',
  web_filter_category: 'カテゴリー',
  web_filter_description: 'Web フィルタ テンプレートを使用して、このポリシーによってどの URL カテゴリを許可または拒否するかを定義します',
  web_filter_flagged: 'フラグが立てられています',
  web_filter_reason: '理由',
  webfilter_block_session: 'Web フィルタによってセッションがブロックされました',
  webfilter_flag_session: 'Web フィルタによってフラグが設定されたセッション',
  webhook: 'Webhook',
  webhook_configuration: 'Webフックの設定',
  webroot_credentials: 'ウェブルート エンドポイント セキュリティ',
  webroot_credentials_instructions:
    '<a href="https://identity.webrootanywhere.com/v1/Account/login" target="_blank">Webroot SecureAnywhere</a> アカウントの認証情報を入力してください。 これにより、「ホスト」セクションに追加の情報と機能が追加されます。 詳細については、<a href="https://support.edge.arista.com/hc/en-us/articles/360041456633-Managing-endpoints-via-Webroot-integration" target="_blank">こちらをご覧ください。 .</a>',
  webroot_key_tooltip: 'Webroot クラウド プラットフォームへの接続に使用される親キーコード',
  webroot_parent_keycode: 'ウェブルートの親キーコード',
  website: 'Webサイト',
  weekly: '毎週',
  widgets_no_appliances: 'あなたのアカウントにはアプライアンスがありません',
  will_renew: '更新します',
  workgroup: 'ワークグループ',
  x_others: '他 {0} 人以上',
  yes: 'はい',
  you_must_save_oauth2_settings: 'テストを実行する前に、OAuth2 設定を保存する必要があります。',
  you_must_save_saml_settings: 'テストを実行するか SP メタデータをダウンロードする前に、SAML 設定を保存する必要があります',
  zuora_company_pattern: "./' のパターンは使用できません。"
}
