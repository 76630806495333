var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-widget',_vm._g({attrs:{"title":_vm.$t('alert_history'),"info":_vm.$t('info_alerts'),"enable-refresh":""},on:{"refresh":_vm.fetchDashboardAlerts},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.alertsEnabled)?_c('div',{staticClass:"d-flex flex-grow-1 justify-end mx-4"},[_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary","rounded":"","outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'alerts-rules' })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-cog")]),_vm._v(" "+_vm._s(_vm.$t('manage'))+" ")],1)],1):_vm._e()]},proxy:true}])},_vm.$listeners),[_c('u-grid',{attrs:{"id":"alert-history","no-data-message":_vm.noDataMessage,"column-defs":_vm.alertColumnDefs,"fetching":_vm.alertsFetching,"row-data":_vm.rowData,"resize-columns":_vm.resizeEvent},on:{"update:resizeColumns":function($event){_vm.resizeEvent=$event},"update:resize-columns":function($event){_vm.resizeEvent=$event},"refresh":_vm.fetchDashboardAlerts,"row-clicked":_vm.viewAlertDetails}}),_c('u-dialog',{attrs:{"show-dialog":!!_vm.filteredAlerts.length,"title":_vm.$t('additional_information'),"width":700,"buttons":[
      {
        'name': _vm.$t('add_alert_rule'),
        'handler': 'add-alert-rule',
      },
      {
        'name': _vm.$t('ok'),
        'handler': 'close-dialog',
      },
    ]},on:{"add-alert-rule":function($event){return _vm.addAlertRule({ data: _vm.filteredAlerts[_vm.alertIndex] })},"close-dialog":_vm.onCloseDialog}},[_c('alert-details',{attrs:{"filtered-alerts":_vm.filteredAlerts,"alert-index":_vm.alertIndex},on:{"update:alertIndex":function($event){_vm.alertIndex=$event},"update:alert-index":function($event){_vm.alertIndex=$event},"route-to":_vm.onRouteTo}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }