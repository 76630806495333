<template>
  <div>
    <!-- used for displaying vuntangle framework components -->
    <u-framework-dialog />
    <u-framework-toast />
    <u-framework-confirm />
    <!-- page loader overlay -->
    <v-overlay
      :value="$store.state.pageLoader"
      opacity="1"
      :color="$store.state.isDark ? 'rgba(0, 0, 0, 0.66)' : 'rgba(255, 255, 255, 0.66)'"
      class="text-center"
    >
      <v-progress-circular indeterminate size="32" color="primary" />
    </v-overlay>
  </div>
</template>
<script>
  import { UFrameworkConfirm, UFrameworkDialog, UFrameworkToast } from 'vuntangle'

  export default {
    components: {
      UFrameworkDialog,
      UFrameworkToast,
      UFrameworkConfirm,
    },
  }
</script>
