<template>
  <u-page v-if="isNew || existingRule" :title="isNew ? $t('create_alert_rule') : $t('edit_alert_rule')" full-height>
    <u-section>
      <ValidationObserver ref="obs">
        <!-- name field -->
        <ValidationProvider v-slot="{ errors }" :rules="{ required: true, max: 100, unique: ruleNames }">
          <u-text-field
            v-model="rule.Name"
            :label="$t('name')"
            :placeholder="$t('alert_rule_name_hint')"
            :error-messages="errors"
            class="mb-4"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="required|max:1000">
          <u-text-field
            v-model="rule.Rule"
            :label="$t('rule')"
            :placeholder="$t('alert_rule_hint')"
            :error-messages="errors"
            class="mb-4"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="required">
          <u-select
            v-model="rule.Status"
            :items="[
              { text: $t('active'), value: 'active' },
              { text: $t('disabled'), value: 'disabled' },
            ]"
            :label="$t('status')"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-select>
        </ValidationProvider>
      </ValidationObserver>
    </u-section>

    <u-section :title="$t('notification_profiles')" class="d-flex flex-column flex-grow-1">
      <!-- TODO - add section subtitle -->
      <v-card-subtitle class="pa-0 mb-4">
        {{ $t('notification_profiles_select_text') }}
      </v-card-subtitle>

      <div v-if="notificationProfileRequired" class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">
            {{ $t('notification_profiles_select_error') }}
          </div>
        </div>
      </div>
      <u-grid
        id="notification-profiles-edit"
        row-node-id="Id"
        selection-type="multiAction"
        :no-data-message="$t('no_data')"
        :column-defs="columnDefs"
        :fetching="fetching"
        :row-data="notificationProfiles"
        :selection.sync="notificationProfilesSelected"
        @refresh="$store.dispatch('alerts/fetchNotificationProfiles', { force: true })"
      />
    </u-section>
    <v-card-actions class="pa-0">
      <u-btn text @click="$router.push({ name: 'alerts-rules' })">
        <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">{{ $t('cancel') }}</span>
      </u-btn>
      <u-btn :disabled="fetching" @click="saveRule">{{ isNew ? $t('create') : $t('update') }}</u-btn>
    </v-card-actions>
  </u-page>
  <u-page v-else-if="ruleNotFound" :title="$t('alert_rules')">
    <v-list-item>
      <v-list-item-content>
        <u-alert error>
          {{ $t('rule_not_found') }}
        </u-alert>
      </v-list-item-content>
    </v-list-item>
  </u-page>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import vuntangle from '@/plugins/vuntangle'
  import users from '@/plugins/ut/ut-users'

  export default {
    /** Prevent/redirect access if user does not have rights */
    beforeRouteEnter(to, from, next) {
      if (!users.hasFullAccountAccess()) {
        next('/alerts/alert-history')
      } else next()
    },
    data() {
      return {
        rule: {
          Id: null,
          Name: '',
          // pre-populate if audit event message given.
          // if there is a colon, only use what comes before it
          Rule: this.$route.params.eventMessage?.split(':')[0] || '',
          Status: 'active',
          NotificationProfileIds: [],
        },
        notificationProfilesSelected: [],
        notificationProfileRequired: false,
        ruleNotFound: false,
      }
    },
    computed: {
      columnDefs() {
        return [
          { headerName: this.$t('name'), field: 'Name' },
          { headerName: this.$t('description'), field: 'Description' },
        ]
      },

      fetching() {
        return this.$store.state.alerts.notificationProfilesFetching || this.$store.state.alerts.alertRulesFetching
      },

      /**
       * Make sure notification profiles are only returned if it is a new rule or once the existing rule is found
       *
       * @return {Array}
       */
      notificationProfiles() {
        return this.$store.state.alerts.notificationProfiles && (this.isNew || this.existingRule)
          ? this.$store.state.alerts.notificationProfiles
          : []
      },

      /**
       * retrieve the currently requested rule, if it exists in the store
       */
      existingRule() {
        return this.$store.state.alerts.alertRules?.find(rule => rule.Id === this.$route.params.id) || null
      },
      /**
       * Check if the rule being added is new.
       *
       * @return {boolean}
       */
      isNew() {
        return this.$route.params.id === 'new'
      },
      /**
       * Alert rule names to validate the rule is unique.  Will remove the current rule name on edit.
       *
       * @return {string[]}
       */
      ruleNames() {
        const ruleNames = this.$store.state.alerts.alertRules?.map(rule => rule.Name) || []

        // remove the current rule name if editing
        if (!this.isNew && this.existingRule) {
          const existingRuleIndex = ruleNames.indexOf(this.existingRule.Name)
          if (existingRuleIndex !== -1) {
            ruleNames.splice(existingRuleIndex, 1)
          }
        }

        return ruleNames
      },
    },

    watch: {
      '$store.state.alerts.alertRules': {
        immediate: true,

        /**
         * When editing an alert rule, load the existing rule after the
         * alerts store has been loaded.
         *
         * @return {void}
         */
        handler() {
          // do nothing if the alert is new or alert are not loaded
          if (this.isNew || !this.$store.state.alerts.alertRules) {
            return
          }

          // find the rule from the rules store
          // if the rule is not found, set to display not found and return
          if (!this.existingRule) {
            this.ruleNotFound = true

            return
          }

          this.rule = {
            Id: this.existingRule.Id,
            Name: this.existingRule.Name,
            Rule: this.existingRule.Rule,
            Status: this.existingRule.Status,
            NotificationProfileIds: [...this.existingRule.NotificationProfileIds],
          }
          this.notificationProfilesSelected = [...this.existingRule.NotificationProfileIds]
          this.notificationProfileRequired = false
        },
      },
      notificationProfilesSelected() {
        this.notificationProfileRequired = this.notificationProfilesSelected.length <= 0
      },
    },
    created() {
      this.$store.commit('SET_ALERTS_SELECTION', this.$route.path)
      this.$store.dispatch('alerts/fetchAlertRules')
      this.$store.dispatch('alerts/fetchNotificationProfiles')
    },

    methods: {
      // saves back new or updated rule to the store
      async saveRule() {
        // validate form fields as the submit button is not tide to observer
        const isValid = await this.$refs.obs.validate()
        if (!isValid) {
          return
        }

        // force selection of a notification profile
        if (this.notificationProfilesSelected.length === 0) {
          this.notificationProfileRequired = true
          return
        }

        this.$store.commit('SET_PAGE_LOADER', true)
        this.notificationProfileRequired = false
        this.rule.NotificationProfileIds = []
        for (let i = 0; i < this.notificationProfilesSelected.length; i++) {
          const nxtNP = this.notificationProfilesSelected[i]
          if (nxtNP) {
            this.rule.NotificationProfileIds.push(nxtNP.Id)
          }
        }

        if (this.isNew) {
          const response = await this.$store.dispatch('alerts/createAlertRule', this.rule)
          if (response.success && response.data) {
            vuntangle.toast.add(this.$t('alert_create_success'))
          } else {
            vuntangle.toast.add(this.$t('alert_create_failure'), 'error')
          }
        } else {
          // get the rule from the store
          const storeRule = this.$store.state.alerts.alertRules.find(rule => rule.Id === this.$route.params.id)
          const savedRule = cloneDeep(storeRule)
          savedRule.Name = this.rule.Name
          savedRule.Rule = this.rule.Rule
          savedRule.Status = this.rule.Status
          savedRule.NotificationProfileIds = [...this.rule.NotificationProfileIds]
          const response = await this.$store.dispatch('alerts/updateAlertRule', savedRule)
          if (response.success && response.data) {
            vuntangle.toast.add(this.$t('alert_edit_success'))
          } else {
            vuntangle.toast.add(this.$t('alert_edit_failure'), 'error')
          }
        }

        this.$store.commit('SET_PAGE_LOADER', false)
        this.$router.push({ name: 'alerts-rules' })
      },
    },
  }
</script>
